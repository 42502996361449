(() => {
    angular.module('VedApp').component('vedWidget', {
        templateUrl: 'js/components/cart/widget/widget.tpl.html',
        controller: VedWidgetController
    });

    VedWidgetController.$inject = ['$document', '$scope', '$state', '$rootScope', '$element', 'OrderCartService', 'REGEXP', '$window', '$translate', '$timeout'];

    function VedWidgetController($document, $scope, $state, $rootScope, $element, OrderCartService, REGEXP, $window, $translate, $timeout) {
        let ctrl = this;

        ctrl.cartWidgetOpened = false;
        ctrl.cartItemsCount = 0;
        ctrl.REGEXP = REGEXP;
        ctrl.mobile = $window.matchMedia("only screen and (max-width: 426px)").matches;
        ctrl.cartTotalAmount = 0;
        ctrl.widgetEnabled = true;
        ctrl.cartItems = [];
        ctrl.deliveryMaxCost = null;
        ctrl.window = angular.element($window);

        ctrl.window.bind('resize', () => {
        (window.innerWidth <= 426) ? (ctrl.mobile = true) : (ctrl.mobile = false); 
        });

        //Hook methods
        ctrl.$onInit = () => {
            initCartItems();

            $scope.$on('newItemAppearedInCart', initCartItems);

            $document.on('click', closeCartWidget);
        };
        ctrl.$onDestroy = () => {
            $document.off('click', closeCartWidget);
        };

        $scope.$on('openCartWidget', () => {
            (ctrl.cartWidgetOpened === false) ? (ctrl.cartWidgetOpened = true) : null;
            // ctrl.cartWidgetOpened = true;
        })

        $scope.$on('clearCart', () => {
            sessionStorage.getItem('cartItems') ? sessionStorage['cartItems'] = [] : null;
            ctrl.cartItemsCount = 0;
            ctrl.calculateTotalAmount();
        });

        $scope.$on('disableWidget', () => {
            ctrl.widgetDisabled = true;
        });

        $scope.$on('enableWidget', () => {
            ctrl.widgetDisabled = false;
        });

        ctrl.setDeliveryCost = () => {
            OrderCartService.getDeliveryCostPrices().then((data) => {
                let deliveryCostTable = data;
                let deliveryCosts = [0];

                ctrl.cartItems.map((item) => {
                    if (item.quantity && item.quantity > 0) {
                        deliveryCosts.push(deliveryCostTable[item.package.id]);
                    }
                });
                
                ctrl.deliveryMaxCost = _.max(deliveryCosts);
            })
        };

        //Public methods
        ctrl.toggleCartWidget = () => {
            ctrl.widgetDisabled = OrderCartService.getWidgetAvailable();
            if (ctrl.widgetDisabled === false) {
                ctrl.cartWidgetOpened = !ctrl.cartWidgetOpened;
            } else {
                return false;
            }
        };

        ctrl.deleteItem = (item) => {
            let index = ctrl.cartItems.indexOf(item);

            ctrl.cartItems.splice(index, 1);
            ctrl.cartItemsCount = ctrl.cartItems.length;
            sessionStorage['cartItems'] = JSON.stringify(ctrl.cartItems);
            ctrl.calculateTotalAmount();
        };

        ctrl.viewCart = () => {
            ctrl.cartWidgetOpened = false;
            $state.go('cart.order-details');
        };

        // Private methods
        ctrl.calculateTotalAmount = (item) => {
            if (item) {
                item.quantity = (!item.quantity) ? 0 : item.quantity;
            }

            sessionStorage['cartItems'] = JSON.stringify(ctrl.cartItems);

            ctrl.cartTotalAmount = ctrl.cartItems.reduce((sum, current) => {
                return sum + current.price * current.quantity;
            }, 0);

            ctrl.setDeliveryCost();
        };

        const closeCartWidget = (event) => {
            if (event.target !== $element[0] && $element.find(event.target).length === 0) {
                ctrl.cartWidgetOpened = false;
                $scope.$apply();
            }
        };

        const initCartItems = () => {
            ctrl.cartItems = sessionStorage.getItem('cartItems') ? JSON.parse(sessionStorage.getItem('cartItems')) : [];
            ctrl.cartItemsCount = ctrl.cartItems.length;
            ctrl.calculateTotalAmount();
        };
    }
})();