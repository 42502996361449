(() => {
    angular.module('VedApp').config(['$stateProvider', '$locationProvider', '$urlRouterProvider',
        ($stateProvider, $locationProvider, $urlRouterProvider) => {
            $stateProvider
                .state('main', {
                    url: '/',
                    template: '<ved-main></ved-main>',
                    params: {
                        activeGroupOrderTab: false,
                    },
                })
                .state('helper', {
                    url: '/helper',
                    template: '<ved-helper></ved-helper>'
                })
                .state('question-form', {
                    url: '/question-form',
                    template: '<ved-question-form></ved-question-form>'
                })
                .state('question-success', {
                    url: '/question-sent',
                    template: '<ved-question-sent></ved-question-sent>'
                })
                .state('order-question', {
                    url: '/order-question',
                    template: '<ved-order-question></ved-order-question>'
                })
                .state('customer-question', {
                    url: '/customer/question/:id/:type',
                    template: '<ved-customer-question data-chat="$resolve.chat"></ved-customer-question>',
                    params: {
                        id: null,
                        type: null
                    },
                    resolve: {
                        chat: ['$stateParams', 'CommunicationService', ($stateParams, CommunicationService) => {
                            if ($stateParams.type === 'GENERAL') {
                                return CommunicationService.getChatDetails($stateParams.id).then(res => res.data);
                            }
                        }]
                    }
                })
                .state('messages', {
                    url: '/messages',
                    template: '<ved-messages></ved-messages>'
                })
                .state('article-view', {
                    url: '/article/:articleName',
                    template: '<ved-article-view data-article="$resolve.article"></ved-article-view>',
                    resolve: {
                        article: ['$stateParams', 'ArticlesService', ($stateParams, ArticlesService) => ArticlesService.getArticle($stateParams.articleName).then(res => res.data)],
                    }
                })
                .state('tutorials-list', {
                    url: '/tutorials-list',
                    template: '<ved-tutorials-list></ved-tutorials-list>'
                })
                .state('products-list', {
                    url: '/order',
                    template: '<ved-products-list class="ved-order"></ved-products-list>'
                })
                .state('contacts', {
                    url: '/contacts',
                    template: '<ved-contact></ved-contact>'
                })
                .state('group-order', {
                    url: '/nabo',
                    // template: '<ved-group-order></ved-group-order>',
                    template: '<ved-main></ved-main>',
                    params: {
                        activeGroupOrderTab: true,
                        enableGroupActive: false,
                        orderId: null
                    }
                })

                .state('cart', {
                    url: '/cart',
                    abstract: true,
                    template: '<ved-cart></ved-cart>'
                })
                .state('cart.order-details', {
                    url: '/order-details',
                    views: {
                        cart: {
                            template: '<ved-order-details data-delivery-ranges="$ctrl.deliveryRanges" data-order="$ctrl.order" data-step="$ctrl.activeStep"></ved-order-details>'
                        }
                    }
                })
                .state('cart.personal-info', {
                    url: '/personal-info',
                    views: {
                        cart: {
                            template: '<ved-personal-info data-on-image-upload="$ctrl.saveNewOrderToStorage()" data-order="$ctrl.order" data-step="$ctrl.activeStep"></ved-personal-info>'
                        }
                    }
                })
                .state('cart.payment', {
                    url: '/payment',
                    views: {
                        cart: {
                            template: '<ved-payment data-order="$ctrl.order" data-step="$ctrl.activeStep"></ved-payment>',
                            params: {
                                order: null
                            }
                        }
                    }
                })
                .state('cart.payment-fi', {
                    url: '/payment-fi',
                    views: {
                        cart: {
                            template: '<ved-payment-fi data-order="$ctrl.order" data-step="$ctrl.activeStep"></ved-payment-fi>',
                            params: {
                                order: null
                            }
                        }
                    }
                })
                .state('cart.successful-order', {
                    url: '/successful-order',
                    views: {
                        cart: {
                            template: '<ved-successful-order data-order="$ctrl.order"></ved-successful-order>'
                        }
                    }
                })

                // Dugnad
                .state('dugnad', {
                    url: '/dugnad',
                    template: '<ved-dugnad></ved-dugnad>'
                })
                .state('create-dugnad', {
                    url: '/create-dugnad',
                    template: '<ved-register-dugnad-group class="dugnad"></ved-register-dugnad-group>'
                })
                .state('dugnad-contact-me', {
                    url: '/dugnad/contact-me',
                    template: '<ved-contact-me></ved-contact-me>'
                })
                .state('dugnad-profile', {
                    url: '/dugnad-profile',
                    template: '<ved-dugnad-profile></ved-dugnad-profile>'
                })

                // User
                .state('user-welcome', {
                    url: '/user/welcome',
                    template: '<ved-welcome></ved-welcome>'
                })
                .state('user-profile', {
                    url: '/profile',
                    template: '<ved-user-profile></ved-user-profile>'
                })
                .state('order-history', {
                    url: '/order-history',
                    template: '<ved-order-history></ved-order-history>'
                })
                // User registration
                .state('registration', {
                    url: '/registrering',
                    template: '<ved-user-registration></ved-user-registration>'
                })
                // User messe registration
                .state('messe', {
                    url: '/messe',
                    template: '<ved-private-customer-reg-messe></ved-private-customer-reg-messe>'
                })
                // Vendor
                .state('vendor-registration', {
                    url: '/vendor-registration',
                    template: '<ved-vendor-registration></ved-vendor-registration>'
                })
                .state('for-vendors', {
                    url: '/leverandor',
                    template: '<ved-for-vendors></ved-for-vendors>'
                })
                // Login
                .state('login', {
                    url: '/login',
                    template: '<ved-login></ved-login>',
                    params: {
                        vendor: null
                    }
                })
                .state('password-recovery', {
                    url: '/password-recovery',
                    template: '<ved-password-recovery></ved-password-recovery>'
                })
                .state('reset-password', {
                    url: '/reset-password',
                    template: '<ved-reset-password></ved-reset-password>'
                })

                .state('stilling', {
                    url: '/stilling',
                    template: '<ved-stilling></ved-stilling>'
                })
                .state('fireplace', {
                    url: '/ildstedet',
                    template: '<ved-fireplace></ved-fireplace>'
                })
                .state('fireplace-registration', {
                    url: '/fireplace/registration',
                    template: '<ved-fireplace-registration></ved-fireplace-registration>'
                })
                // .state('about-us', {
                //     url: '/about',
                //     template: '<ved-about></ved-about>'
                // })
                .state('privacy-policy', {
                    url: '/policy',
                    template: '<ved-privacy-policy class="container"></ved-privacy-policy>'
                })
                .state('terms-and-conditions', {
                    url: '/terms',
                    template: '<ved-terms class="container"></ved-terms>'
                })
                .state('faq', {
                    url: '/faq',
                    template: '<ved-faq></ved-faq>'
                });


            $urlRouterProvider
                .when('/contact-me', '/dugnad/contact-me')
                .when('/cart', '/cart/order-details')
                .when('/user', '/user/welcome')
                .otherwise('/');

            $locationProvider.html5Mode(true);
        }]);
})();