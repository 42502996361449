(() => {
    angular.module('VedApp').component('vedHome', {
        templateUrl: 'js/components/home/home.tpl.html',
        controller: VedHomeController
    });

    VedHomeController.$inject = ['$timeout'];

    function VedHomeController($timeout) {
        let ctrl = this;
        ctrl.checkRange = null;

        // Hook methods
        ctrl.$onInit = () => {
            $timeout(() => {
                if (sessionStorage.getItem('currentDateRange')) {
                    ctrl.checkRange = JSON.parse(sessionStorage.getItem('currentDateRange'));
                    ctrl.checkRange.name === 'EXTRA_LONG' ? sessionStorage.removeItem('currentDateRange') : null;
                }
            }, 1500);
        };
    }
})();