(() => {
    angular.module('VedApp').component('vedPostView', {
        templateUrl: 'js/components/home/posts/post-view/post-view.tpl.html',
        controller: VedPostViewController,
        bindings: {
            post: '<'
        }
    });

    function VedPostViewController() {
        let ctrl = this;
    }
})();