((angular, _) => {
    angular.module('VedApp').component('vedOrderPayment', {
        templateUrl: 'js/components/order/order-payment/order-payment.tpl.html',
        controller: vedOrderPaymentController,
        bindings: {
            order: '<'
        }
    });

    vedOrderPaymentController.$inject = ['$rootScope', '$mdDialog', '$translate', '$window', 'moment', 'OrderService'];

    function vedOrderPaymentController($rootScope, $mdDialog, $translate, $window, moment, OrderService) {
        let ctrl = this;

        ctrl.min = new Date().getFullYear();
        ctrl.max = ctrl.min + 34;
        ctrl.months = _.range(1, 13).map((month) => (month < 10) ? `0${month}` : month);
        ctrl.years = _.range(ctrl.min, ctrl.max);
        ctrl.mobile = $window.matchMedia("only screen and (max-width: 768px)").matches;
        ctrl.percentageOptions = [
            {value: 100},
            {value: 50},
            {value: 25},
            {value: 10}
        ];
        ctrl.payedPrice = 0;
        ctrl.chargedPrice = 0;
        ctrl.moment = moment;
        ctrl.card = null;
        ctrl.formInValid = true;

        ctrl.payment = {
            type: '',
            number: '',
            exp_month: null,
            exp_year: null,
            expiry: null,
            cvc: ''
        };

        ctrl.$onInit = () => {
            ctrl.card = stripeCard;
            ctrl.card.mount('#card-element');

            ctrl.card.addEventListener('change', function (event) {
                const displayError = document.getElementById('card-errors');

                if (event.error) {
                    displayError.textContent = event.error.message;
                    ctrl.formInValid = true;
                } else {
                    displayError.textContent = '';
                    ctrl.formInValid = ctrl.orderPaymentForm.$invalid;
                }
            });
        };

        // Hook methods
        ctrl.$doCheck = () => ctrl.selectPaymentPercentage();

        // Public methods
        ctrl.clearOrder = (order_id, status, confirmed_user, authorized) => {
            ctrl.order.orderState = Object.assign({}, {
                order_id,
                status,
                confirmed_user,
                authorized
            });
            sessionStorage.removeItem('order');
            sessionStorage.removeItem('deliveryRanges');
        };

        ctrl.getCardType = () => {
            ctrl.payment.type = null;

            const re = {
                visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
                mastercard: /^5[1-5][0-9]{14}$/,
                maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
                amex: /^3[47][0-9]{13}$/,
                discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
                unionpay: /^(62|88)\d+$/
            };

            for (let key in re) {
                if (re[key].test(ctrl.payment.number)) {
                    ctrl.payment.type = key
                }
            }
        };

        ctrl.getPercentage = (percentage, percentageFrom) => (percentage / 100) * percentageFrom;

        ctrl.roundNumber = (number) => number.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

        ctrl.selectPaymentPercentage = () => {
            let price = 0;
            if (ctrl.order.code.type === 'group_order' && ctrl.order.code.valid) {
                price = ctrl.order.orderTotalSum + ctrl.order.carryPrice + ctrl.order.deliveryCost / 2;
            } else if (ctrl.order.code.type !== 'group_order') {
                price = ctrl.order.orderTotalSum + ctrl.order.carryPrice + ctrl.order.deliveryCost;
            }
            ctrl.payedPrice = ctrl.getPercentage(ctrl.order.percentage, price);
            ctrl.chargedPrice = ctrl.roundNumber((price - ctrl.payedPrice));
        };

        ctrl.handlePaymentError = (error) => {
            ctrl.order.orderState.status = null;
            if (error.hasOwnProperty('custom_range.start_date') || error.hasOwnProperty('custom_range.end_date')) {
                OrderService.alert('errors.payment.extra-long-range');
            } else {
                (error.indexOf('_') !== -1) ? OrderService.alert(`errors.payment.${error}`) : OrderService.alert(error);
            }
        };

        // ctrl.createStripeToken = (card) => {
        //     Stripe.createToken(card, (status, response) => {
        //         if (response.error) {
        //             ctrl.handlePaymentError(response.error.code);
        //         } else if (response.id) {
        //             ctrl.order.stripeToken = response.id;
        //             OrderService.orderFirewood(ctrl.order)
        //                 .then(({data: {order_id, status, confirmed_user, errors, authorized}}) => {
        //                     (!errors) ?
        //                         ctrl.clearOrder(order_id, status, confirmed_user) :
        //                         ctrl.handlePaymentError(errors);
        //
        //                     authorized && authorized !== 'false' ? $rootScope.$broadcast('user-logged-in') : null;
        //                 });
        //         }
        //     });
        // };

        ctrl.createStripeToken = () => {
            stripe.createPaymentMethod('card', ctrl.card).then((response) => {
                if (response.error) {
                    // Inform the user if there was an error
                    const errorElement = document.getElementById('card-errors');
                    errorElement.textContent = response.error.message;
                    ctrl.formInValid = true;
                } else {
                    ctrl.order.stripeToken = response.paymentMethod.id;
                    OrderService.orderFirewood(ctrl.order)
                        .then(({data: {order_id, status, confirmed_user, errors, authorized}}) => {
                            (!errors) ?
                                ctrl.clearOrder(order_id, status, confirmed_user) :
                                ctrl.handlePaymentError(errors);

                            authorized && authorized !== 'false' ? $rootScope.$broadcast('user-logged-in') : null;
                        })
                        .catch(error => {
                            ctrl.handleServerErrorResponse(error.data);
                        });
                }
            });
        };

        ctrl.handleServerErrorResponse = (error) => {
            if (error.data.requires_action) {
                // Use Stripe.js to handle required card action
                stripe.handleCardAction(error.data.client_secret)
                    .then(response => {

                        if (response.error) {

                            const errorElement = document.getElementById('card-errors');
                            errorElement.textContent = response.error.message;
                            ctrl.formInValid = true;

                        } else {
                            ctrl.order.stripeToken = response.paymentIntent.payment_method;
                            ctrl.order.payment_intent = response.paymentIntent.id;

                            OrderService.orderFirewood(ctrl.order)
                                .then(({data: {order_id, status, confirmed_user, errors, authorized}}) => {
                                    (!errors) ?
                                        ctrl.clearOrder(order_id, status, confirmed_user) :
                                        ctrl.handlePaymentError(errors);

                                    authorized && authorized !== 'false' ? $rootScope.$broadcast('user-logged-in') : null;
                                })
                                .catch(error => {
                                    ctrl.formInValid = true;
                                    ctrl.handlePaymentError(error.data.data);
                                });
                        }
                    })
            }
        };

        ctrl.parseCard = () => {
            let card = {
                number: ctrl.payment.number,
                cvc: ctrl.payment.cvc
            };

            if (ctrl.mobile) {
                card = Object.assign({}, card, {
                    exp_month: ctrl.payment.exp_month,
                    exp_year: ctrl.payment.exp_year,
                });
            } else {
                let index = ctrl.payment.expiry.indexOf('/');
                card = Object.assign({}, card, {
                    exp_month: ctrl.payment.expiry.slice(0, index),
                    exp_year: ctrl.payment.expiry.slice((index + 1)),
                });
            }

            return card;
        };

        ctrl.buyFirewood = (form) => {
            ctrl.order.orderState.status = 'pending';
            // let card = ctrl.parseCard();

            // if (form.$valid) {
                ctrl.createStripeToken();
                localStorage.setItem('zip_code', ctrl.order.address.zipcode);
            // }
        }
    }
})(angular, _);