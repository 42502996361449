(() => {
    angular.module('VedApp').component('vedStepper', {
        templateUrl: 'js/components/common/stepper/stepper.tpl.html',
        controller: VedStepperController,
        bindings: {
            steps: '<'
        }
    });

    function VedStepperController() {}
})();