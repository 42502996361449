(() => {
    let OrderCartService = ($http) => {
        let groupMode = false;
        let orderToPay = null;

        let getProductsByFilters = (zipcode, category, packageType) => $http.get(apiURL + 'v2/orders/products', {
                params: {
                    zipcode,
                    'category[]': category,
                    'package[]': packageType
                }
            });

        let getOrder = (order_id) => $http.get(apiURL + `v2/orders/${order_id}`);

        let setOrderToPay = (selectedOrder) => orderToPay = selectedOrder;
        let getOrderToPay = () => orderToPay;

        let getWidgetProducts = (zipcode) => {
            return !!zipcode ?
            $http.get(apiURL + `v2/orders/products?flow=widget&zipcode=${zipcode}`) :
            $http.get(apiURL + `v2/orders/products?flow=widget`);
        };
        let getProduct = (id) => $http.get(apiURL + `v2/orders/products/${id}`);
        let getCategories = () => $http.get(apiURL + 'v2/products/categories');
        let getPackages = () => $http.get(apiURL + 'v2/products/packages');
        let getVendorPrices = (vendorId, addressId) => $http.get(apiURL + `v2/vendors/${vendorId}/prices`, {
                params: {
                    address_id: addressId
                }
            });
            
        let validateCouponCode = (coupon, location) => {
            return $http.get(apiURL + `v1/coupon/validate/${coupon}`, {
                params: {
                    city: location.city,
                    zipcode: location.zipcode,
                    street: location.street
                }
            });
        };
        let getVendorServices = (vendorId) => $http.get(apiURL + `v2/vendors/${vendorId}/services`);
        let getDefaultVendorPrices = () => $http.get(apiURL + 'v2/orders/products/prices');
        let getDefaultVendorServices = () => $http.get(apiURL + 'v2/services');
        let getDeliveryCostPrices = () => $http.get('/v1/delivery-cost').then(({data}) => data);
        let repeatOrderFirewood = (order) => {
            order.payment_intent = order.payment_intent ? order.payment_intent : null;

            return $http({
                method: 'POST',
                url: `${apiURL}v2/orders`,
                data: order
            })
        };
        let orderFirewood = (order) => {
            const orderToSave = getOrderToSave(order);

            return $http({
                method: 'POST',
                url: `${apiURL}v2/orders`,
                data: orderToSave
            });
        };

        let getOrderToSave = (order) => {
            let lastElem = order.products.slice(-1)[0];
            let newOrder = Object.assign({}, {
                vendor_id: lastElem.vendorForGetPricesId ? lastElem.vendorForGetPricesId : order.vendor_id,
                vendor_address_id: lastElem.vendorForGetPricesAddressId ? lastElem.vendorForGetPricesAddressId : order.vendor_address_id,
                first_name: order.first_name,
                last_name: order.last_name,
                email: order.email,
                phone_number: order.phone_number,
                location: order.location,
                products: getSelectedProducts(order.products),
                delivery_date_range: order.delivery_date_range ? order.delivery_date_range : '7_12',
                custom_range: order.custom_range,
                excluded_delivery_dates: getExcludedDeliveryDates(order.ranges.excludedDeliveryDates),
                carry: order.carry,
                delivery_time_morning: order.deliveryTime['8_12'],
                delivery_time_midday: order.deliveryTime['12_17'],
                delivery_time_evening: order.deliveryTime['17_22'],
                services: getOrderServices(order.services),
                prepaid_percentage: order.prepaid_percentage,
                coupon_code: order.coupon_code.value, 
                comment: order.comment,
                transaction_token: order.transaction_token,
                payment_intent: order.payment_intent ? order.payment_intent : null,
                images: order.images ? getSelectedImages(order.images) : [],
                payment_fi: order.payment_fi ? order.payment_fi : false
            });

            return newOrder;
        };

        let getExcludedDeliveryDates = (excludedDates) => {
            let sortedExcludedDates = [];

            _.forEach(excludedDates, (date) => {
                date = moment(date).format('YYYY-MM-DD');

               sortedExcludedDates.push(date);
            });

            return sortedExcludedDates;
        };

        let getSelectedImages = (images) => {
            let selectedImages = images.filter((image) => {
                return image.selected === true;
            });

            return selectedImages;
        };

        let getSelectedProducts = (products) => {
            let sortedProducts = [];

            _.forEach(products, (productItem) => {
                let newProd = {
                    category_id: productItem.category.id,
                    package_id: productItem.package.id,
                    quantity: productItem.quantity
                };

                sortedProducts.push(newProd);
            });

            return sortedProducts;
        };

        let getOrderServices = (services) => {
            let servicesToSend = [];

            for (let key in services) {
                let newObj = {};

                if (services[key] === true) {
                    newObj = {
                        "name": key
                    };
    
                    servicesToSend.push(newObj);
                }
            }

            return servicesToSend;
        };

        let disableWidgetIfCartOpened = false;

        let toggleWidgetAvailable = () => {
            disableWidgetIfCartOpened = !disableWidgetIfCartOpened;
        };

        let getWidgetAvailable = () => {
            return disableWidgetIfCartOpened;
        };

        let enableGroupMode = (value) => {
            groupMode = value;
        };

        let checkGroupMode = () => {
            return groupMode;
        };

        return {
            getProductsByFilters,
            getWidgetProducts,
            getProduct,
            getCategories,
            getPackages,
            getVendorPrices,
            getVendorServices,
            getDefaultVendorPrices,
            getDefaultVendorServices,
            getDeliveryCostPrices,
            orderFirewood,
            toggleWidgetAvailable,
            getWidgetAvailable,
            validateCouponCode,
            enableGroupMode,
            checkGroupMode,
            setOrderToPay,
            getOrderToPay,
            getOrder,
            repeatOrderFirewood
        };
    };

    angular.module('VedApp').factory('OrderCartService', OrderCartService);
    OrderCartService.$inject = ['$http'];
})();