(() => {
    angular.module('VedApp').component('vedFooter', {
        templateUrl: 'js/components/footer/footer.tpl.html',
        controller: VedFooterController
    });

    VedFooterController.$inject = ['$window'];

    function VedFooterController($window) {
        let ctrl = this;
        let isCollapsed = ($window.innerWidth <= 768) ? true : false;
        ctrl.lang = lang;
        ctrl.footerBlocks = [
            {
                title: 'footer.company',
                collapsed: isCollapsed,
                items: [
                    {
                        title: 'footer.mobile-app',
                        href: 'about-us',
                        lang: 'no',
                        disabled: true
                    },
                    {
                        title: 'footer.fireplace',
                        href: 'fireplace',
                        lang: 'no'
                    },
                    {
                        title: 'footer.dugnad',
                        href: 'dugnad'
                    },
                    {
                        title: 'footer.situation',
                        href: 'stilling',
                        lang: 'no'
                    }
                ]
            },
            {
                title: 'footer.vendor',
                collapsed: isCollapsed,
                items: [
                    {
                        title: 'footer.news-room',
                        href: 'https://www.vedaskog.no/',
                        outerLink: true,
                        lang: 'no'
                    },
                    {
                        title: 'footer.registration',
                        href: 'vendor-registration'
                    },
                    {
                        title: 'footer.login',
                        href: 'login'
                    }
                ]
            },
            {
                title: 'footer.customer',
                collapsed: isCollapsed,
                items: [
                    {
                        title: 'footer.order',
                        href: 'products-list'
                    },
                    {
                        title: 'footer.group-order',
                        href: 'group-order'
                    },
                    {
                        title: 'footer.registration',
                        href: 'registration'
                    },
                    {
                        title: 'footer.login',
                        href: 'login'
                    }
                ]
            },
            {
                title: 'footer.contact-us',
                collapsed: isCollapsed,
                text: 'footer.contact-us-description'
            },
            {
                addToPrevOnMobile: true,
                text: 'footer.send-email'
            }
        ];

        // Hook methods
        ctrl.$onInit = () => {
        };
    }
})();