(() => {
    let DugnadService = ($http) => {
        let createDugnadGroup = (data) => $http.post(`${apiURI}dugnad`, data);

        let updateDugnadGroup = (data) => $http.put(`${apiURI}dugnad/group`, data);

        let getUserDugnudGroup = () => $http.get(`${apiURI}dugnad/group`);

        let deleteGroupMember = (memberId) => $http.delete(`${apiURI}dugnad/group/member/${memberId}`);

        let sendContactMeRequest = (data) => $http.post(`${apiURI}contact-me`, data);

        return {
            createDugnadGroup,
            updateDugnadGroup,
            deleteGroupMember,
            getUserDugnudGroup,
            sendContactMeRequest
        };
    };

    angular.module('VedApp').factory('DugnadService', DugnadService);
    DugnadService.$inject = ['$http'];
})();