(() => {
    angular.module('VedApp').component('vedCustomerQuestion', {
        templateUrl: 'js/components/customer-question/customer-question.tpl.html',
        controller: VedCustomerQuestionController,
        bindings: {
            chat: '<'
        }
    });

    VedCustomerQuestionController.$inject = ['$stateParams', 'OrderService', '$scope', '$state', '$rootScope', 'CommunicationService'];

    function VedCustomerQuestionController($stateParams, OrderService, $scope, $state, $rootScope, CommunicationService) {
        let ctrl = this;

        ctrl.authorized = authorized;
        ctrl.orders = [];
        ctrl.orderStatuses = ['paid', 'scheduled', 'packed', 'delivering', 'delivered'];
        ctrl.moment = moment;
        ctrl.isOrderAvailable = false;
        ctrl.order = null;
        ctrl.generalPrice = 0;
        ctrl.isLoading = false;
        ctrl.showOrderInfo = true;
        ctrl.messagesEmpty = false;
        ctrl.prevState = null;
        $rootScope.previousState;

        // Hook methods
        ctrl.$onInit = () => {
            if ($stateParams.type === 'ORDER') {
                ctrl.isOrderAvailable = true;
                ctrl.isLoading = true;
                OrderService.getOrderHistory().then((orders) => {
                    ctrl.orders = orders;
                    ctrl.orders.forEach((item) => {
                        if (item.order_id == $stateParams.id) {
                            ctrl.order = item;

                            ctrl.order.products.forEach((product) => {
                                ctrl.generalPrice = ctrl.generalPrice + product.total_price;
                            });
                            $scope.$broadcast('orderLoaded', ctrl.order);
                        }
                    });

                    ctrl.isLoading = false;
                });
            } else if ($stateParams.type === 'GENERAL' || $stateParams.type === null) {
                ctrl.showOrderInfo = false;
            }


        };

        $scope.$on('messagesEmpty', () => {
            ctrl.messagesEmpty = true;
        });

        ctrl.goToHistory = () => {
            let prevState = CommunicationService.getState();
            prevState ? $state.go(prevState) : $state.go('messages');
        }

        ctrl.disabledDays = (order) => {
            return order.excluded_delivery_dates
                .sort((a, b) => moment.utc(a.valueOf()).diff(moment.utc(b.valueOf())))
                .map(disabledDay => ctrl.moment(disabledDay).format('DD.MM.YYYY'))
                .join(', ');
        };
    }
})();