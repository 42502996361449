(() => {
    angular.module('VedApp').component('vedLogin', {
        templateUrl: 'js/components/login/login.tpl.html',
        controller: VedLoginController
    });

    VedLoginController.$inject = ['$rootScope', '$state', '$stateParams', '$translate', '$window', 'ROLES', 'ToastService',
        'ProfileService', 'UserService', '$timeout'];

    function VedLoginController($rootScope, $state, $stateParams, $translate, $window, ROLES, ToastService, ProfileService,
                                UserService, $timeout) {
        let ctrl = this;

        ctrl.vendorLogin = !!$stateParams.vendor;

        ctrl.userData = {
            phone_number: '',
            password: ''
        };

        // Hook methods
        ctrl.$onInit = () => {};

        // Public methods
        ctrl.submitLogin = (loginForm) => {
            if(loginForm.$valid) {
                ctrl.isLoggingIn = true;
                UserService.login(ctrl.userData).then(() => {
                    ToastService.show(true, $translate.instant('errors.success'));
                    ProfileService.getUserData().then((data) => {
                        let user = data;

                        if (user.address.length > 0 && user.address[0].zipcode) {
                            sessionStorage.setItem('productListZip', user.address[0].zipcode);
                        }

                        UserService.role = user.role;
                        UserService.name = {
                            first: user.first_name,
                            last: user.last_name
                        };

                        if (UserService.role === ROLES.vendor) {
                            user.password_reset_access === true ? $window.location.pathname = '/reset-password' : $window.location.pathname = '/dashboard';
                        }

                        // Go to dashboard if vendor or admin, to profile if user, to home page if company or other

                        UserService.role === ROLES.admin ? $window.location.pathname = '/dashboard' :
                            UserService.role === ROLES.buyer ? $state.go('user-profile') : $state.go('main');

                    }).catch();

                    // Update top nav bar
                    authorized = true;
                    $rootScope.$broadcast('user-logged-out-login');
                    $timeout(() => {
                        $window.location.reload();
                    }, 1000);
                    
                }).finally(() => ctrl.isLoggingIn = false);
            }
        };
    }
})();