(() => {
    angular.module('VedApp').component('vedProductDetails', {
        templateUrl: 'js/components/products-list/product-details/product-details.tpl.html',
        controller: VedProductDetailsController,
        bindings: {
            product: '<'
        }
    });

    VedProductDetailsController.$inject = [];

    function VedProductDetailsController() {
        let ctrl = this;
        ctrl.owlAPI = null;
        ctrl.activeImage = null;
        ctrl.isProductDetails = true;
        ctrl.properties = {
            startPosition: 0,
            lazyContent: true,
            nav: false,
            dots: true,
            autoHeight: true,
            margin: 30,
            navText: [],
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 2
                },
                800: {
                    items: 3
                },
                1250: {
                    items: 3
                },
                1400: {
                    items: 3
                },
                1800: {
                    items: 3
                }
            },
            center: false
        };

        ctrl.ready = ($api) => ctrl.owlAPI = $api;

        ctrl.$onInit = () => {
            ctrl.activeImage = ctrl.product.images[0];
        };

        ctrl.changeActiveImage = (image) => {
            ctrl.activeImage = image;
        }
    }
})();