(() => {
    angular.module('VedApp').component('vedBreadcrumbs', {
        templateUrl: 'js/components/common/breadcrumbs/breadcrumbs.tpl.html',
        controller: VedBreadcrumbsController,
        bindings: {
            isProductDetails: '<'
        }
    });

    VedBreadcrumbsController.$inject = ['$window', '$scope'];

    function VedBreadcrumbsController($window, $scope) {
        let ctrl = this;
        
        ctrl.$onInit = () => {}
    }
})();