(() => {
    angular.module('VedApp').component('vedNewsroomArticleView', {
        templateUrl: 'js/components/newsroom/article-view/newsroom-article-view.tpl.html',
        controller: VedNewsroomArticleViewController,
        bindings: {
            slug: '@'
        }
    });

    VedNewsroomArticleViewController.$inject = ['$window', 'EditorialsService'];

    function VedNewsroomArticleViewController($window, EditorialsService) {
        let ctrl = this;

        ctrl.authorized = authorized;
        ctrl.newComment = '';
        ctrl.articleImageUrl = '/img/bg.png';

        // Hook methods
        ctrl.$onInit = () => {
            EditorialsService.getArticle(ctrl.slug).then(({data}) => {
                ctrl.article = data;
                ctrl.articleImageUrl = ctrl.article && ctrl.article.thumbnail.large ? ctrl.article.thumbnail.large : '/img/bg.png';
                EditorialsService.getArticleComments(ctrl.article.id).then(({data}) => ctrl.comments = data.data);
            });
        };

        // Public methods
        ctrl.addNewComment = () => {
            if (ctrl.newComment !== '') {
                EditorialsService.commentArticle(ctrl.article.id, {content: ctrl.newComment}).then(({data}) => {
                    ctrl.newComment = '';
                    ctrl.comments.push(data);
                    ctrl.article.comments_count++;
                });
            }
        };
        ctrl.likeArticle = () => {
            !ctrl.article.liked && ctrl.authorized ? EditorialsService.likeArticle(ctrl.article.id).then(() => {
                ctrl.article.liked = true;
                ctrl.article.likes_count++;
            }) : null;
        };
        ctrl.navigateToLogin = () => $window.location.pathname = '/login';
        ctrl.navigateToRegistration = () => $window.location.pathname = '/vendor-registration';
    }
})();