(() => {
    angular.module('VedApp').component('vedVendorRegistration', {
        templateUrl: 'js/components/vendor/vendor-registration/vendor-registration.tpl.html',
        controller: VedVendorRegistrationController
    });

    VedVendorRegistrationController.$inject = ['$mdDialog', '$translate', '$window', 'REGEXP', 'UserService'];

    function VedVendorRegistrationController($mdDialog, $translate, $window, REGEXP, UserService) {
        let ctrl = this;

        ctrl.vendor = {};
        ctrl.REGEXP = REGEXP;

        // Hook methods
        ctrl.$onInit = () => {
        };

        ctrl.createVendor = (formObject) => {
            if (formObject.$valid && ctrl.vendor['confirm_password'] === ctrl.vendor.password) {
                ctrl.vendorIsCreating = true;
                UserService.vendorRegistration(ctrl.vendor).then(() => {
                    // Navigate to dashboard
                    $window.location.pathname = '/dashboard/profile'
                }).catch(({data}) => {
                    data.errors.phone_number || data.errors.email ? showConfirm() : ToastService.show(false, data);
                }).finally(()=> ctrl.vendorIsCreating = false);
            }
        };

        // Private methods
        let showConfirm = () => {
            let confirm = $mdDialog.confirm()
                .title($translate.instant('errors.heading'))
                .textContent($translate.instant('errors.form.exist-email-password'))
                .clickOutsideToClose(true)
                .ok($translate.instant('errors.form.send-password'))
                .cancel($translate.instant('general.close'));
            $mdDialog.show(confirm).then(() => {
                UserService.sendPassword({
                    email: ctrl.vendor.email,
                    phone_number: ctrl.vendor.phone_number
                })
            }, () => null);
        };
    }
})();