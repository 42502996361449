((angular, $, _) => {
    angular.module('VedApp').component('vedOrderProducts', {
        templateUrl: 'js/components/order/order-products/order-products.tpl.html',
        controller: VedOrderProductsController,
        bindings: {
            onSave: '&',
            deliveryRanges: '<',
            order: '<'
        }
    });

    VedOrderProductsController.$inject = ['OrderService', 'REGEXP', '$mdDialog', 'ROLES', 'ORDER_TYPES', '$translate',
        '$window', 'moment', 'ToastService'];

    function VedOrderProductsController(OrderService, REGEXP, $mdDialog, ROLES, ORDER_TYPES, $translate, $window, moment, ToastService) {
        this.disableCarry = false;
        this.REGEXP = REGEXP;
        this.ROLES = ROLES;
        this.moment = moment;
        this.ORDER_TYPES = ORDER_TYPES;
        this.owlAPi = null;
        this.items = this.order.products.map((product, index) => index);
        this.properties = {
            startPosition: 0,
            lazyContent: true,
            nav: true,
            dots: true,
            autoHeight: true,
            margin: 30,
            navText: [],
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 2
                },
                800: {
                    items: 3
                },
                1250: {
                    items: 4
                },
                1400: {
                    items: 6
                },
                1800: {
                    items: 7
                }
            },
            center: false
        };
        this.highlightDays = [];
        this.allowedDays = [];
        this.currentDateRange = [];
        this.selectedDays = [];
        this.disabledDaysAmount = null;
        this.week = {
            start: null,
            end: null,
            selected: []
        };
        this.rangeLength = 0;
        this.startMonth = null;
        this.rangeOpen = false;
        this.deliveryPrices = [];
        this.codeAutocomplete = {
            searching: false,
            loading: false,
            results: null,
            error: null
        };

        this.$onInit = () => {
            OrderService.getDeliveryCostPrices().then(deliveryPrice => this.deliveryPrices = deliveryPrice);
            (this.order.type === this.ORDER_TYPES.quick) ? this.calculateProductPrice() : null;
            this.order.products.forEach((product) => {
                product.ui = {
                    measurement_units: product.capacity < 1000 ? product.capacity + 'l' +
                        (product.type ? $translate.instant('order.' + product.type) : '') :
                        $translate.instant('order.measurement-units', {
                            capacity: product.capacity,
                            type: $translate.instant('order.' + product.type) || ''
                        })
                };
            });
        };

        this.toggleRangeDatePickers = () => this.rangeOpen = !this.rangeOpen;

        this.setRange = (startFrom) => {
            let newRange = [];
            let startDate = this.order.deliveryRange === 'EXTRA_LONG' ? startFrom : undefined;

            for (let i = 0; i < this.rangeLength; i++) {
                newRange.push({
                    date: this.moment(startDate).add((i), 'days').valueOf(), selectable: true
                });
            }

            return newRange;
        };

        this.searchCode = () => {
            this.codeAutocomplete.searching = true;
            this.codeAutocomplete.loading = true;
            OrderService.getCode(this.order.code.value)
                .then(data => {
                    this.codeAutocomplete.loading = false;

                    if (data.status === 'success') {
                        this.codeAutocomplete = Object.assign({}, this.codeAutocomplete, {
                            error: null,
                            results: data.data
                        });
                    } else {
                        this.codeAutocomplete = Object.assign({}, this.codeAutocomplete, {
                            error: data,
                            results: null
                        });
                    }
                });
        };

        this.selectCode = (code) => {
            this.order.code.value = code;
            this.codeAutocomplete.loading = false;
            this.codeAutocomplete.searching = false;
        };

        this.getRange = () => (+(_.last(this.order.deliveryRange.split('_'))) + 1);

        this.setDisabledDaysAmount = () => {
            switch (this.order.deliveryRange) {
                case '7_12':
                    return 2;
                case '4_6':
                    return 1;
                case '2_3':
                    return 1;
                default:
                    return 0;
            }
        };

        this.resetDateRange = () => {
            this.highlightDays = [];
            this.allowedDays = [];
            this.currentDateRange = [];
            this.disabledDaysAmount = null;
        };

        this.setMonthView = (dateItem) => {
            this.startMonth = (!!dateItem.length) ?
                this.moment(_.first(dateItem).date) :
                this.moment(dateItem.date);
        };

        this.resetCustomRange = () => {
            this.order.customRange = {
                startDate: null,
                endDate: null
            };
        };

        this.setCustomRangeBorders = () => {
            if (this.order.deliveryRange === 'EXTRA_LONG' && this.highlightDays.length > 0) {
                this.order.customRange = {
                    startDate: this.moment(_.first(this.highlightDays).date).format('YYYY-MM-DD'),
                    endDate: this.moment(_.last(this.highlightDays).date).format('YYYY-MM-DD')
                };
            } else {
                this.resetCustomRange();
            }
        };

        this.setAllowedDays = () => this.allowedDays = this.highlightDays.map(({date}) => this.moment(date));

        this.calculateDateRange = (range, startFrom) => {
            this.rangeLength = range ? range : this.getRange();

            this.selectedDays = [];
            this.order.ranges.excludedDeliveryDates = [];
            this.disabledDaysAmount = this.setDisabledDaysAmount();
            this.currentDateRange = this.setRange(startFrom).map(({date}) => this.moment(date));
            this.highlightDays = this.setRange(startFrom);
            this.setAllowedDays(startFrom);
            this.setMonthView(this.highlightDays);
        };

        this.showExtraDeliveryRangeModal = (previousRange) => {
            $mdDialog.show({
                templateUrl: 'js/components/order/order-products/extra-long-delivery.tpl.html',
                locals: {previousRange: previousRange},
                clickOutsideToClose: true,
                fullscreen: false,
                controller: ['$scope', '$mdDialog', 'moment', 'previousRange', ($scope, $mdDialog, moment, previousRange) => {

                    // TODO mocked dates
                    let defaultStartDate = '2018-08-20',
                        defaultEndDate = '2018-09-20',
                        startRangeDate = '2018-08-01',
                        endRangeDate = '2018-09-30';


                    $scope.moment = moment;
                    $scope.generateDateRange = (startDate, endDate, key) => {
                        let range = [];
                        let endPoint = $scope.moment(endDate);
                        let startPoint = $scope.moment(startDate);

                        while (endPoint >= startPoint) {
                            range.push($scope.moment(startPoint.format('YYYY-MM-DD')));
                            startPoint.add(1, key);
                        }

                        return range;
                    };

                    $scope.isCustomRange = () => {
                        let customRangeNotEmpty = this.order.customRange.startDate !== null;
                        return customRangeNotEmpty && (this.order.customRange.startDate !== defaultStartDate || this.order.customRange.endDate !== defaultEndDate);
                    };

                    $scope.extraLongDelivery = {
                        custom: $scope.isCustomRange(),
                        startDate: this.order.customRange.startDate ? [$scope.moment(this.order.customRange.startDate)] : [],
                        endDate: this.order.customRange.endDate ? [$scope.moment(this.order.customRange.endDate)] : [],
                        defaultRange: {
                            start: $scope.moment(defaultStartDate),
                            end: $scope.moment(defaultEndDate)
                        },
                        range: $scope.generateDateRange(startRangeDate, endRangeDate, 'day')
                    };
                    $scope.dataPickersConfig = {
                        startDatePickerOpen: false,
                        endDatePickerOpen: false,
                        startDateBaseMonth: _.first($scope.extraLongDelivery.range),
                        endDateBaseMonth: _.last($scope.extraLongDelivery.range)
                    };

                    $scope.updateDate = (property, date) => {
                        $scope.extraLongDelivery[property] = [date];
                        $scope.dataPickersConfig[`${property}PickerOpen`] = false;
                    };
                    $scope.onStartDateSelect = (event, {date}) => $scope.updateDate('startDate', date);
                    $scope.onEndDateSelect = (event, {date}) => $scope.updateDate('endDate', date);

                    $scope.getRange = (startDate, endDate) => (endDate && startDate) ? endDate.diff(startDate, 'days') : 0;

                    $scope.saveExtraDeliveryRange = (form) => {
                        if (form.$valid) {
                            let range = 0;
                            let startDate = null;

                            if ($scope.extraLongDelivery.custom) {
                                range = $scope.getRange(
                                    _.first($scope.extraLongDelivery.startDate),
                                    _.first($scope.extraLongDelivery.endDate)
                                );
                                startDate = _.first($scope.extraLongDelivery.startDate);
                            } else {
                                range = $scope.getRange(
                                    $scope.extraLongDelivery.defaultRange.start,
                                    $scope.extraLongDelivery.defaultRange.end
                                );
                                startDate = $scope.extraLongDelivery.defaultRange.start;
                            }

                            this.calculateDateRange(range + 1, startDate);
                            this.setCustomRangeBorders();
                            $mdDialog.cancel();
                        }
                    };

                    $scope.close = () => {
                        this.order.deliveryRange = (previousRange || this.order.deliveryRange);
                        previousRange !== 'EXTRA_LONG' ? this.calculateDateRange() : null;
                        $mdDialog.cancel();
                    }
                }]
            });
        };

        (this.updateDateRange = (previousRange) => {
            if (this.order.deliveryRange === 'TODAY') {
                this.resetDateRange();
            } else {
                this.calculateDateRange();
            }

            (previousRange && this.order.deliveryRange === 'EXTRA_LONG') ?
                this.showExtraDeliveryRangeModal(previousRange) :
                null;
        })();

        this.getSelectedDays = (date) => {
            let amountOfSelectedDates = null;

            if (date.mdp.selected) {
                amountOfSelectedDates = (this.order.ranges.excludedDeliveryDates.length - 1);
                this.selectedDays = this.selectedDays.filter(day => !day.date.isSame(date.date));
            } else {
                amountOfSelectedDates = (this.order.ranges.excludedDeliveryDates.length + 1);
                this.selectedDays.push(date);
            }

            return amountOfSelectedDates;
        };

        this.disableCurrentWeek = () => {
            const selectedDays = this.selectedDays.map(({date}) => date);
            this.allowedDays = this.allowedDays
                .filter((day) => !day.isBetween(this.week.start, this.week.end))
                .concat(selectedDays);
        };

        this.setAdditionalDaysToRange = (amountOfSelectedDates, selectedDate) => {
            let startDate = _.first(this.highlightDays).date;
            this.rangeLength = selectedDate.mdp.selected ? (this.rangeLength - 1) : (this.rangeLength + 1);

            this.highlightDays = (this.disabledDaysAmount > 0 && (amountOfSelectedDates === this.disabledDaysAmount)) ?
                this.selectedDays :
                this.setRange(startDate);
            this.currentDateRange = this.setRange(startDate).map(({date}) => this.moment(date));

            this.setCustomRangeBorders();
        };

        this.setUnlimitedRange = (amountOfSelectedDates, date) => {
            this.week = Object.assign({}, this.week, {
                start: this.moment(),
                end: this.moment().add(7, 'days')
            });
            this.week.selected = this.selectedDays.filter(({date}) => {
                return (this.week.start.isSame(date, 'week')) || (date.isBetween(this.week.start, this.week.end));
            });

            this.setAdditionalDaysToRange(amountOfSelectedDates, date);
            this.setAllowedDays();

            (this.week.selected.length > 1) ? this.disableCurrentWeek() : this.setAllowedDays();
        };

        this.setLimitedRanges = (amountOfSelectedDates, date) => {
            (this.disabledDaysAmount >= amountOfSelectedDates) ? this.setAdditionalDaysToRange(amountOfSelectedDates, date) : null;
            this.setAllowedDays();
        };

        this.onDayClick = ($event, date) => {
            let amountOfSelectedDates = this.getSelectedDays(date);
            this.setMonthView(date);
            (this.order.deliveryRange === '12_30' || this.order.deliveryRange === 'EXTRA_LONG') ?
                this.setUnlimitedRange(amountOfSelectedDates, date) :
                this.setLimitedRanges(amountOfSelectedDates, date);
        };

        this.disabledDays = () => {
            if (this.order.ranges.excludedDeliveryDates.length > 0) {
                return this.order.ranges.excludedDeliveryDates
                    .sort((a, b) => moment.utc(a.valueOf()).diff(moment.utc(b.valueOf())))
                    .map(disabledDay => disabledDay.format('DD MMM'))
                    .join(', ');
            } else if (this.allowedDays.length > 0) {
                return `${_.first(this.allowedDays).format('DD MMM')} - ${_.last(this.allowedDays).format('DD MMM')}`;
            }
        };

        this.ready = ($api) => this.owlAPi = $api;

        this.applyCarry = () => {
            this.order.carryPrice = 0;

            this.order.products
                .filter(product => (+(product.quantity) > 0))
                .map(product => {
                    if (this.order.services.carry) {
                        product.carryPrice = (product.quantity * product.carry_price);
                        this.order.carryPrice += product.carryPrice;
                    } else {
                        product.carryPrice = 0;
                    }
                });
        };

        this.setDeliveryCost = () => {
            OrderService.getDeliveryCostPrices().then((data) => {
                let deliveryCostTable = data;
                let deliveryCosts = [0];
                this.order.products.map((product) => {
                    if (product.quantity && product.quantity > 0) {
                        deliveryCosts.push(deliveryCostTable[product.product_pack_id]);
                    }
                });
                this.order.deliveryCost = _.max(deliveryCosts);
            });
        };

        this.calculateOrderTotalSum = () => {
            this.order.orderTotalSum = this.order.products
                .filter(product => (+(product.quantity) > 0))
                .map(product => (product.totalPrice === 0 && product.carryPrice) ?
                    (product.totalPrice + product.carryPrice) :
                    product.totalPrice)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

            this.setDeliveryCost();
        };

        this.enableCarry = () => {
            let selectedProducts = this.order.products.filter(product => (+(product.quantity) > 0));
            this.disableCarry = (selectedProducts.length > 0) ?
                selectedProducts.every(product => (+(product.capacity) === 1000 || +(product.capacity) === 1500)) : false;

            if (this.disableCarry) {
                this.order.services.carry = !this.disableCarry;
                this.order.carryPrice = 0;
            } else {
                this.applyCarry();
            }
        };

        this.calculateProductPrice = (product) => {
            if (product) {
                product.quantity = (!product.quantity) ? 0 : product.quantity;
            }

            let deliveryRange = this.deliveryRanges.filter(range => range.value === this.order.deliveryRange)[0];

            this.order.products
                .filter(product => (+(product.quantity) > 0))
                .map(product => {
                    product.price = (product._price * (deliveryRange.index / 100 + 1)).toFixed(2);
                    product.totalPrice = product.price * product.quantity;
                });

            this.enableCarry();
            this.calculateOrderTotalSum();
        };

        this.showAlert = (message) => {
            $mdDialog.show(
                $mdDialog.alert()
                    .clickOutsideToClose(true)
                    .title($translate.instant('order.modals.search.heading'))
                    .textContent(message)
                    .ok($translate.instant('order.modals.search.close'))
            );
        };

        this.resetCode = () => {
            this.order.code = {
                type: '',
                value: '',
                valid: false
            };
            this.codeAutocomplete = {
                loading: false,
                searching: false,
                results: null,
                error: null
            };
        };

        this.applyCode = () => {
            this.order.code.valid = false;

            OrderService.applyCode(this.order.code.value, this.order.address)
                .then(({data: {type, message}}) => {
                    if (!type && message === 'invalid') {
                        this.showAlert($translate.instant(`order.modals.group-order.invalid-code`));
                    } else {
                        if (type === 'group_order') {
                            if (message !== 'ok') {
                                this.showAlert($translate.instant(`order.modals.group-order.${message}`));
                            } else {
                                this.order.code.valid = true;
                                this.order.code.type = type;

                                ToastService.show(true, $translate.instant('order.loading.success'));
                            }
                        } else if (type === 'dugnad' && message === 'valid') {
                            ToastService.show(true, $translate.instant('order.loading.success'));
                        }
                    }
                });
        };

        this.saveProducts = (form) => {
            if (this.order.deliveryRange !== 'EXTRA_LONG') {
                this.resetCustomRange();
            }
            (form.$valid) ? this.onSave({step: 3}) : null;
        }
    };
})(angular, jQuery, _);
