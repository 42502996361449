((angular, _) => {
    angular.module('VedApp')
        .filter('dateRangesFilter', ['$translate', ($translate) => (dateItem) => {
            let deliveryRangeName = '';
            if (dateItem === 'TODAY') {
                deliveryRangeName = `1 ${$translate.instant('order.day')}`;
            } else if (dateItem === '2_3') {
                deliveryRangeName = `3 ${$translate.instant('order.days')}`;
            } else if (dateItem === '4_6') {
                deliveryRangeName = `6 ${$translate.instant('order.days')}`;
            } else if (dateItem === '7_12') {
                deliveryRangeName = `12 ${$translate.instant('order.days')}`;
            } else if (dateItem === '12_30') {
                deliveryRangeName = `30 ${$translate.instant('order.days')}`;
            } else {
                deliveryRangeName = `${$translate.instant('order.EXTRA_LONG')}`;
            }

            return deliveryRangeName;
        }]);
})(angular, _);