((angular, _) => {

    angular.module('VedApp').component('vedNewsroom', {
        templateUrl: 'js/components/newsroom/newsroom.tpl.html',
        controller: VedNewsroomController
    });

    VedNewsroomController.$inject = ['$window', 'EditorialsService'];

    function VedNewsroomController($window, EditorialsService) {
        let ctrl = this;
        ctrl.newsList = [];
        ctrl.authorized = authorized;

        // Hook methods
        ctrl.$onInit = () => {
            EditorialsService.getArticles().then(({data}) => ctrl.newsList = data.data);
        };

        // Public methods
        ctrl.loadMoreNews = () => {
            // TODO
        };
        ctrl.openArticleView = (article) => {
            $window.location.pathname = '/newsroom/article/' + article.slug;
        };
        ctrl.likeArticle = (article, event) => {
            !article.liked && ctrl.authorized ? EditorialsService.likeArticle(article.id).then(() => {
                article.liked = true;
                article.likes_count++;
            }) : null;
            event.stopPropagation();
        };
    }
})(angular, _);