(() => {
    angular.module('VedApp').component('vedOrderQuestion', {
        templateUrl: 'js/components/order-question/order-question.tpl.html',
        controller: VedOrderQuestionController
    });

    VedOrderQuestionController.$inject = ['OrderService', 'ToastService', '$translate', '$state', 'CommunicationService'];

    function VedOrderQuestionController(OrderService, ToastService, $translate, $state, CommunicationService) {
        let ctrl = this;

        ctrl.authorized = authorized;
        ctrl.lang = lang;
        ctrl.isLoading = false;
        ctrl.expanded = false;
        ctrl.itemsLimit = 10;
        ctrl.orders = [];
        ctrl.selectedOrder = null;

        // Hook methods
        ctrl.$onInit = () => {
            ctrl.isLoading = true;
            OrderService.getOrderHistory().then((orders) => {
                ctrl.orders = orders;
                ctrl.selectedOrder = ctrl.orders[0];
                ctrl.isLoading = false;
            });
        };

        ctrl.expandLimit = () => {
            ctrl.itemsLimit = 100;
            ctrl.expanded = !ctrl.expanded;
        }

        ctrl.startConversation = () => {
            $state.go('customer-question', { id : ctrl.selectedOrder.order_id, type: 'ORDER'});
        }
    }
})();