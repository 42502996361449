((angular, _) => {
    let VedOrderHistoryController = function ($mdDialog, $translate, moment, PAID_STATUSES, ROLES, OrderService, ToastService, $state, CommunicationService, OrderCartService) {
        let ctrl = this;
        
        ctrl.orders = [];
        ctrl.chats = [];
        ctrl.isLoading = false;
        ctrl.orderStatuses = ['paid', 'scheduled', 'packed', 'delivering', 'delivered'];
        ctrl.moment = moment;
        ctrl.currentMonth = ctrl.moment(new Date());
        ctrl.PAID_STATUSES = PAID_STATUSES;
        ctrl.ROLES = ROLES;
        ctrl.user = null;

        ctrl.$onInit = () => {
            OrderService.getUser().then(({data}) => {
                ctrl.user = data;
            }).catch();
            ctrl.isLoading = true;
            OrderService.getOrderHistory().then((orders) => {
                ctrl.orders = orders;
                ctrl.isLoading = false;
                ctrl.orders.map((order) => ctrl.calculateCarryPrice(order));
            });

            CommunicationService.getChatsList().then(({data}) => {
                ctrl.chats = data.data;

                ctrl.orders.forEach((order) => {
                    ctrl.chats.forEach((chat) => {
                        if (chat.order_id === order.order_id) {
                            order.hasChat = true;
                            order.chatId = chat.id;
                        }
                    });
                });
            })
        };

        ctrl.goToOrderChat = (orderId) => {
            CommunicationService.setState('order-history');
            $state.go('customer-question', { id : orderId, type: 'ORDER'});
        }

        ctrl.repeatOrder = (selectedOrder) => {
            OrderCartService.setOrderToPay(selectedOrder);
            if (ctrl.user.role === 'BUYER') {
                lang === 'fi' ? $state.go('cart.payment-fi') : $state.go('cart.payment');
            } else if (ctrl.user.role === 'COMPANY') {
                $state.go('cart.personal-info');
            }
            
        }

        ctrl.moveToOrdersPage = () => {
            $state.go('order-question');
        }

        ctrl.existExcludedDays = (order) => (order.excluded_delivery_dates && order.excluded_delivery_dates.length > 0);

        ctrl.calculateCarryPrice = (order) => {
            let carryPrice = 0;

            order.products.map(product => {
                carryPrice += (+(product.carry_price) * +(product.product_quantity));
            });

            order.carryPrice = carryPrice;
            order._excDays = JSON.stringify(order.excluded_delivery_dates);
            return order;
        };

        ctrl.setRangeStartDay = (order) => {
            return (order.delivery_date_range === 'EXTRA LONG') ?
                ctrl.moment(_.first(order.delivery_date_raw)) :
                ctrl.moment(order.order_date_raw);
        };

        ctrl.setRange = (order, rangeLength) => {
            let startDay = ctrl.setRangeStartDay(order);

            let range = [{date: startDay.valueOf(), selectable: true}];
            for (let i = 0; i < rangeLength; i++) {
                range.push({
                    date: startDay.add((1), 'days').valueOf(), selectable: true
                });
            }

            order.currentDateRange = range.map(day => ctrl.moment(day.date));
            order.highlightDays = range;
        };

        ctrl.setAllowedDays = (order) => order.allowedDays = order.highlightDays.map(({date}) => date);

        ctrl.setExcludedDeliveryDatesFormat = (order) => {
            ctrl.existExcludedDays(order) ?
                order.excluded_delivery_dates = order.excluded_delivery_dates.map(day => ctrl.moment(day)) :
                null;
        };

        ctrl.setDisabledDaysAmount = (order) => {
            switch (order.delivery_date_range) {
                case '7-12':
                    return 2;
                case '4-6':
                    return 1;
                case '2-3':
                    return 1;
                default:
                    return 0;
            }
        };

        ctrl.disabledDays = (order) => {
            return order.excluded_delivery_dates
                .sort((a, b) => moment.utc(a.valueOf()).diff(moment.utc(b.valueOf())))
                .map(disabledDay => ctrl.moment(disabledDay).format('DD.MM.YYYY'))
                .join(', ');
        };

        ctrl.getRange = (order) => ctrl.moment(order.delivery_date_raw[1]).diff(order.delivery_date_raw[0], 'days');

        ctrl.setDaysOptions = (order) => {
            order.selectedDays = ctrl.existExcludedDays(order) ?
                order.excluded_delivery_dates.map(day => ({date: ctrl.moment(day), selectable: true})) :
                [];
            order.month = ctrl.setRangeStartDay(order);
            order.disabledDaysAmount = ctrl.setDisabledDaysAmount(order);
        };

        ctrl.setRangeDuration = (order, amountOfSelectedDates) => {
            (order.delivery_date_range === '12-30' || order.delivery_date_range === 'EXTRA LONG') ?
                ctrl.setUnlimitedRange(order, amountOfSelectedDates) :
                ctrl.setLimitedRanges(order, amountOfSelectedDates);
        };

        ctrl.setDatePickerState = (order) => {
            let amountOfSelectedDates = (ctrl.existExcludedDays(order) ? order.excluded_delivery_dates.length : 0);

            ctrl.setDaysOptions(order);
            ctrl.setExcludedDeliveryDatesFormat(order);
            ctrl.setRangeDuration(order, amountOfSelectedDates);
        };

        ctrl.toggleDatePicker = (order) => {
            order.datePicker = !order.datePicker;
            if (order.datePicker) {
                ctrl.setDatePickerState(order);
            } else {
                order.excluded_delivery_dates = JSON.parse(order._excDays);
            }
        };

        ctrl.disableCurrentWeek = (order, week) => {
            const selectedDays = order.selectedDays.map(({date}) => date);
            order.allowedDays = order.allowedDays
                .filter((day) => !ctrl.moment(day).isBetween(week.startDay, week.endDay))
                .concat(selectedDays);
        };

        ctrl.setUnlimitedRange = (order, amountOfSelectedDates) => {
            const startDay = ctrl.moment(order.order_date_raw);
            const endDay = ctrl.moment(order.order_date_raw).add(7, 'days');
            const week = Object.assign({}, {startDay, endDay});

            order.inFirstWeek = order.selectedDays.filter(({date}) => (week.startDay.isSame(date, 'week')) || (date.isBetween(week.startDay, week.endDay)));

            ctrl.setAdditionalDaysToRange(order, amountOfSelectedDates);
            ctrl.setAllowedDays(order);

            (order.delivery_date_range !== 'EXTRA LONG' && order.inFirstWeek.length > 1) ? ctrl.disableCurrentWeek(order, week) : ctrl.setAllowedDays(order);
        };

        ctrl.setProperRange = (order, amountOfSelectedDates) => {
            let range = (ctrl.getRange(order) + amountOfSelectedDates);

            if (range) {
                ctrl.setRange(order, range);
                if (order.delivery_date_range !== 'EXTRA LONG' && order.disabledDaysAmount > 0 && amountOfSelectedDates === order.disabledDaysAmount) {
                    order.highlightDays = order.selectedDays;
                }
            } else {
                order.highlightDays = [];
            }
        };

        ctrl.setAdditionalDaysToRange = (order, amountOfSelectedDates) => {
            ctrl.setProperRange(order, amountOfSelectedDates);
        };

        ctrl.setLimitedRanges = (order, amountOfSelectedDates) => {
            (order.disabledDaysAmount >= amountOfSelectedDates) ? ctrl.setAdditionalDaysToRange(order, amountOfSelectedDates) : null;
            ctrl.setAllowedDays(order);
        };

        ctrl.getSelectedDays = (date, order) => {
            let amountOfSelectedDates = null;

            if (date.mdp.selected) {
                amountOfSelectedDates = (order.excluded_delivery_dates.length - 1);
                order.selectedDays = order.selectedDays.filter(day => !day.date.isSame(date.date));
            } else {
                amountOfSelectedDates = (order.excluded_delivery_dates.length + 1);
                order.selectedDays.push(date);
            }

            return amountOfSelectedDates;
        };

        ctrl.onDayClick = ($event, date) => {
            const orderId = $($event.target).parents('multiple-date-picker').data('id');
            const order = ctrl.orders.filter((order => order.order_id === orderId))[0];
            let amountOfSelectedDates = ctrl.getSelectedDays(date, order);
            ctrl.setRangeDuration(order, amountOfSelectedDates);
        };

        ctrl.showPaymentModal = (order) => {
            $mdDialog.show({
                templateUrl: 'js/components/orders-history/payment.tpl.html',
                clickOutsideToClose: true,
                fullscreen: false,
                onComplete: afterShowAnimation,
                controller: ['$scope', '$mdDialog', 'moment', '$window', 'OrderService', ($scope, $mdDialog, moment, $window, OrderService) => {
                    $scope.moment = moment;
                    $scope.order = order;
                    $scope.payment = {
                        number: '',
                        exp_month: null,
                        exp_year: null,
                        expiry: null,
                        cvc: ''
                    };
                    $scope.min = new Date().getFullYear();
                    $scope.max = $scope.min + 34;
                    $scope.months = _.range(1, 13).map((month) => (month < 10) ? `0${month}` : month);
                    $scope.years = _.range($scope.min, $scope.max);
                    $scope.mobile = $window.matchMedia("only screen and (max-width: 768px)").matches;
                    $scope.cardNumber = cardNumber;
                    $scope.cardExpiry = cardExpiry;
                    $scope.cardCvc = cardCvc;
                    $scope.cardValid = false;

                    $scope.handlePaymentError = (error) => {
                        if (error.hasOwnProperty('custom_range.start_date') || error.hasOwnProperty('custom_range.end_date')) {
                            OrderService.alert('errors.payment.extra-long-range');
                        } else {
                            (error.indexOf('_') !== -1) ? OrderService.alert(`errors.payment.${error}`) : OrderService.alert(error);
                        }
                    };

                    // $scope.createStripeToken = (card) => {
                    //     Stripe.createToken(card, (status, response) => {
                    //         if (response.error) {
                    //             $scope.handlePaymentError(`errors.payment.${response.error.code}`);
                    //         } else if (response.id) {
                    //             OrderService.payForFireWood(order.order_id, response.id)
                    //                 .then((orderItem) => {
                    //                     order.paid_status = orderItem.paid_status;
                    //                     ToastService.show(true, $translate.instant('errors.success'));
                    //                 })
                    //                 .catch(({data: {errors}}) => $scope.handlePaymentError(errors));
                    //         }
                    //         $scope.closeModal();
                    //     });
                    // };

                    $scope.createStripeToken = () => {
                        stripe.createPaymentMethod('card', $scope.cardNumber).then((response) => {
                            //$scope.handlePaymentError(`errors.payment.${response.error.code}`);
                            if (response.error) {
                                // Inform the user if there was an error
                                const error = document.getElementById('card-errors');
                                const errorMessage = error.querySelector('.message');
                                errorMessage.textContent = response.error.message;
                                $scope.cardValid = false;
                            } else {
                                OrderService.payForFireWood(order.order_id, response.paymentMethod.id)
                                    .then((orderItem) => {
                                        order.paid_status = orderItem.paid_status;
                                        ToastService.show(true, $translate.instant('errors.success'));

                                        $scope.closeModal();
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        $scope.handleServerErrorResponse(error);
                                    });
                            }
                        });
                    };

                    $scope.handleServerErrorResponse = (error) => {
                        if(error.data.requires_action){
                            // Use Stripe.js to handle required card action
                            stripe.handleCardAction(error.data.client_secret)
                                .then(response => {
                                    if (response.error) {
                                        // Inform the user if there was an error
                                        const error = document.getElementById('card-errors');
                                        const errorMessage = error.querySelector('.message');
                                        errorMessage.textContent = response.error.message;
                                        $scope.cardValid = false;
                                    } else {
                                        OrderService.payForFireWood(order.order_id, response.paymentIntent.payment_method, response.paymentIntent.id)
                                            .then((orderItem) => {
                                                order.paid_status = orderItem.paid_status;
                                                ToastService.show(true, $translate.instant('errors.success'));

                                                $scope.closeModal();
                                            })
                                            .catch(error => {
                                                $scope.cardValid = false;
                                                OrderService.alert('errors.payment.' + error.data.errors);
                                            });
                                    }
                                })
                        } else {
                            OrderService.alert(error.data.errors);
                        }
                    };

                    $scope.parseCard = () => {
                        let card = {
                            number: $scope.payment.number,
                            cvc: $scope.payment.cvc
                        };

                        if ($scope.mobile) {
                            card = Object.assign({}, card, {
                                exp_month: $scope.payment.exp_month,
                                exp_year: $scope.payment.exp_year,
                            });
                        } else {
                            let index = $scope.payment.expiry.indexOf('/');
                            card = Object.assign({}, card, {
                                exp_month: $scope.payment.expiry.slice(0, index),
                                exp_year: $scope.payment.expiry.slice((index + 1)),
                            });
                        }

                        return card;
                    };

                    $scope.payForFirewood = () => {
                        // let card = $scope.parseCard();
                        $scope.createStripeToken();
                    };
                    $scope.closeModal = () => $mdDialog.cancel();

                    $scope.registerStripeElements = (elements) => {
                        const error = document.getElementById('card-errors');
                        const errorMessage = error.querySelector('.message');

                        // Listen for errors from each Element, and show error messages in the UI.
                        let savedErrors = {};

                        elements.forEach(function(element, idx) {
                            element.on('change', function(event) {
                                if (event.error) {
                                    error.classList.add('visible');
                                    savedErrors[idx] = event.error.message;
                                    errorMessage.innerText = event.error.message;
                                    $scope.cardValid = false;
                                } else {
                                    savedErrors[idx] = null;

                                    // Loop over the saved errors and find the first one, if any.
                                    let nextError = Object.keys(savedErrors)
                                        .sort()
                                        .reduce(function(maybeFoundError, key) {
                                            return maybeFoundError || savedErrors[key];
                                        }, null);

                                    if (nextError) {
                                        // Now that they've fixed the current error, show another one.
                                        errorMessage.innerText = nextError;
                                    } else {
                                        // The user fixed the last error; no more errors.
                                        error.classList.remove('visible');
                                        $scope.fieldsNotEmpty();
                                    }
                                }
                            });
                        });
                    };

                    $scope.fieldsNotEmpty = () => {
                        if(!$scope.cardCvc._empty && !$scope.cardExpiry._empty && !$scope.cardNumber._empty){
                            $scope.cardValid = true;
                        }
                    };
                }]
            });

            function afterShowAnimation(scope) {
                scope.cardNumber.mount('#card-number');
                scope.cardExpiry.mount('#card-expiry');
                scope.cardCvc.mount('#card-cvc');

                scope.registerStripeElements([scope.cardNumber, scope.cardExpiry, scope.cardCvc]);
            }
        };

        ctrl.saveExcludedDays = (order) => {
            OrderService.updateOrder(order)
                .then(({data: {excluded_delivery_dates, delivery_date_from, delivery_date_to, delivery_date_raw}}) => {
                    order.datePicker = false;
                    order._excDays = JSON.stringify(excluded_delivery_dates);
                    order.delivery_date_raw = order.delivery_date_range !== 'EXTRA LONG' ?
                        delivery_date_raw : [delivery_date_from, delivery_date_to];
                })
                .catch(({data: {errors}}) => OrderService.alert(errors));
        }
    };

    VedOrderHistoryController.$inject = ['$mdDialog', '$translate', 'moment', 'PAID_STATUSES', 'ROLES', 'OrderService', 'ToastService', '$state', 'CommunicationService', 'OrderCartService'];

    angular.module('VedApp').component('vedOrderHistory', {
        templateUrl: 'js/components/orders-history/orders-history.tpl.html',
        controller: VedOrderHistoryController
    });

})(angular, _);