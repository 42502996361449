((angular, _) => {
    angular.module('VedApp')
        .filter('productTypeFilter', ['$translate', ($translate) => (type) => {
            let productType = null;

            switch (type) {
                case 'stablet':
                    productType = 'TETT';
                    break;
                case 'ustablet':
                    productType = 'LØST';
                    break;
                default:
                    break;
            }

            return productType;
        }]);
})(angular, _);