(() => {
    angular.module('VedApp').component('vedPrivateCustomerReg', {
        templateUrl: 'js/components/user-registration/private-customer-reg/private-customer-reg.tpl.html',
        controller: VedPrivateCustomerRegController,
        bindings: {
            onRegisteredCallback: '&'
        }
    });

    VedPrivateCustomerRegController.$inject = ['REGEXP', 'UserService'];

    function VedPrivateCustomerRegController(REGEXP, UserService) {
        let ctrl = this;

        ctrl.client = {
            newsletter: true
        };
        ctrl.REGEXP = REGEXP;

        // Hook methods
        ctrl.$onInit = () => {
        };

        // Public methods
        ctrl.createPrivateCustomer = (formObject) => {
            if (formObject.$valid && ctrl.client.password === ctrl.client.confirm_password) {
                UserService.registration(ctrl.client).then(ctrl.onRegisteredCallback);
            }
        };
    }
})();