(() => {
    angular.module('VedApp').component('vedOrderTabs', {
        templateUrl: 'js/components/main/order-tabs/order-tabs.tpl.html',
        controller: VedOrderTabsController
    });

    VedOrderTabsController.$inject = ['$stateParams', '$scope', '$state', '$mdDialog', '$window', 'REGEXP', 'OrderCartService', 'OrderService',
        'ProfileService', 'ToastService', 'ZipLocationService'];

    function VedOrderTabsController($stateParams, $scope, $state, $mdDialog, $window, REGEXP, OrderCartService, OrderService,
                                    ProfileService, ToastService, ZipLocationService) {
        let ctrl = this;
        let products = [];
        let pageSize = ($window.innerWidth <= 768) ? 2 : 3;

        ctrl.activeTab = $stateParams.activeGroupOrderTab ? 'group-order' : 'quick-order';
        ctrl.currentPage = 0;
        ctrl.pages = 0;
        ctrl.REGEXP = REGEXP;
        ctrl.isLoading = true;
        ctrl.zipItem = null;
        ctrl.userZipItem = null;
        ctrl.zipcodesList = [];
        ctrl.userZipCode = '';
        ctrl.enabledGroupMode = false;
        ctrl.campaignCode = '';

        // Hook methods
        ctrl.$onInit = () => {
            ZipLocationService.getZipcodesList().then(({data}) => {
                ctrl.zipcodesList = data;
            }).catch();
            let storedZipCode = sessionStorage.getItem('productListZip');

            if (!storedZipCode || storedZipCode === 'undefined') {
                if (authorized) {
                    ProfileService.getUserData().then(user => {
                        if (user.address.length > 0 && user.address[0].zipcode) {
                            ctrl.userZipCode = user.address[0].zipcode;
                        }
                    }).catch().finally(() => ctrl.setZipCode(ctrl.userZipCode));
                } else {
                    ctrl.setZipCode();
                }
            } else {
                ctrl.setZipCode(storedZipCode);
            }

            $scope.$on('newZipcode', (event, data) => ctrl.setZipCode(data && data.zipcode ? data.zipcode : null));
        };

        // Public methods
        ctrl.getProductsView = () => {
            if (window.innerWidth <= 426) {
                return products;
            }
            return products.slice(ctrl.currentPage * pageSize, ctrl.currentPage * pageSize + pageSize);
        };

        ctrl.goToPrev = () => {
            ctrl.currentPage--;
        };
        ctrl.goToNext = () => {
            ctrl.currentPage++;
        };

        ctrl.onZipChanged = (newZipCode) => {
            ctrl.setZipCode(newZipCode).catch(() => {
                ctrl.zipItem = angular.copy(ctrl.userZipItem);
            });
        };

        ctrl.querySearch = (query) => {
            if (query.length < 2) {
                return [];
            }
            return _.filter(ctrl.zipcodesList, (zipItem) => {
                return zipItem.zipcode.indexOf(query) === 0;
            });
        };

        ctrl.setZipCode = (newZipCode) => {
            console.log('!!! setZipCode');
            if (newZipCode && newZipCode.search(ctrl.REGEXP.ZIP_CODE_REGEXP) >= 0 && (!ctrl.userZipItem || ctrl.userZipItem.zipcode !== newZipCode)) {
                ctrl.isLoading = true;
                products = [];
                prepareProducts();
                sessionStorage.setItem('productListZip', newZipCode);

                ToastService.toggleNotifications();
                ZipLocationService.getLocationByZip(newZipCode)
                    .then(({data}) => {
                        ctrl.zipItem = data;
                        ctrl.userZipItem = data;
                        OrderCartService.getWidgetProducts(ctrl.zipItem.zipcode).then(({data}) => {
                            products = data;
                            ctrl.pages = Math.round(products.length / pageSize);
                            prepareProducts();
                            ctrl.isLoading = false;
                        });
                    }).catch(() => {
                    ctrl.searchText = newZipCode;
                    OrderCartService.getWidgetProducts(ctrl.searchText).then(({data}) => {
                        products = data;
                        ctrl.pages = Math.round(products.length / pageSize);
                        prepareProducts();
                        ctrl.isLoading = false;
                    });
                }).finally(() => ToastService.toggleNotifications());
            } else if (!newZipCode || newZipCode === '') {
                sessionStorage.removeItem('productListZip');
                ctrl.isLoading = true;
                OrderCartService.getWidgetProducts().then(({data}) => {
                    products = data;
                    ctrl.pages = Math.round(products.length / pageSize);
                    prepareProducts();
                    ctrl.isLoading = false;
                });
            }
        };

        ctrl.navigateToGroupOrder = () => {
            $state.go('group-order');
        };

        ctrl.navigateToMainPage = () => {
            $state.go('main');
        };

        ctrl.generateCampaignCode = () => {
            ctrl.enabledGroupMode = OrderCartService.checkGroupMode();
            OrderService.generateCode().then(({data: {name}}) => {
                if (ctrl.enabledGroupMode === true) {
                    OrderService.assignGroupCode(localStorage.getItem('orderId'), name).then((data) => {
                        ctrl.campaignCode = name;
                        sessionStorage.setItem('groupCode', ctrl.campaignCode);
                        ctrl.disableGenerating = true;
                    });
                } else {
                    ctrl.campaignCode = name;
                    sessionStorage.setItem('groupCode', ctrl.campaignCode);
                }

                OrderCartService.enableGroupMode(false);
            });
        };

        ctrl.navigateToOrder = () => {
            sessionStorage.setItem('coupon_code', ctrl.campaignCode);
            $window.location.pathname = '/order';
        };

        // Private methods
        let prepareProducts = () => {
            products.forEach((product) => {
                product.quantity = (product.package.capacity === 40 || product.package.capacity === 60) ? 10 : 1;
            });

            // set Delivery Costs
            OrderService.getDeliveryCostPrices().then((data) => {
                let deliveryCostTable = data;
                products.forEach((product) => {
                    product['delivery_cost'] = deliveryCostTable[product.package.id];
                });
            });
        };
    }
})();