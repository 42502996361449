(() => {
    angular.module('VedApp').component('vedDugnad', {
        templateUrl: 'js/components/dugnad/dugnad.tpl.html',
        controller: VedDugnadController
    });

    //VedDugnadController.$inject = [];

    function VedDugnadController() {
        let ctrl = this;

        ctrl.lang = lang;

        ctrl.hidden = true;

        // Hook methods
        ctrl.$onInit = () => {
        };
    }
})();