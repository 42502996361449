(() => {
    let ProfileService = ($http) => {
        let getUserData = () => $http.get(`/user`).then(({data}) => data);

        let getCompanyData = () => $http.get(`/user/company`).then(({data}) => data);

        let deleteDepartment = (departmentId) => $http.delete(`/user/address/${departmentId}`);

        let deleteImage = (id) => $http.delete(`/user/address/image/${id}`);

        let saveCompany = (data) => $http.post(`/user/company`, data);

        let saveUser = (data) => $http.post(`/user/profile/update`, data);

        let changePassword = (data) => $http.post(`/user/change-password`, data);

        let getMapTheme = () => $http.get('./js/dashboard/map-theme.json');

        return {
            getUserData,
            getCompanyData,
            deleteDepartment,
            deleteImage,
            saveUser,
            saveCompany,
            changePassword,
            getMapTheme
        };
    };

    angular.module('VedApp').factory('ProfileService', ProfileService);
    ProfileService.$inject = ['$http'];
})();