(() => {
    angular.module('VedApp').component('vedProductCard', {
        templateUrl: 'js/components/common/product-card/product-card.tpl.html',
        controller: VedProductCardController,
        bindings: {
            product: '<'
        }
    });

    VedProductCardController.$inject = ['$rootScope', '$timeout', '$translate', '$mdDialog', 'ToastService'];

    function VedProductCardController($rootScope, $timeout, $translate, $mdDialog, ToastService) {
        let ctrl = this;

        ctrl.productListZip = sessionStorage.getItem('productListZip');

        // Public methods
        ctrl.addProductToCart = (e, product) => {
            // if (!sessionStorage.getItem('productListZip') && product.package.capacity >= 1000) {
            if (!sessionStorage.getItem('productListZip')) {
                // ToastService.show(false, $translate.instant('new-order.errors.required-postcode'));

                ctrl.openZipCodeModal();
                return;
            }

            if (typeof (product.quantity) !== 'undefined') {
                let items = sessionStorage.getItem('cartItems') ? JSON.parse(sessionStorage.getItem('cartItems')) : [];
                let isNewProduct = true;
                let vendorMatchFound = false;

                _.forEach(items, (item) => {
                    if (item.category.id === product.category.id && item.package.id === product.package.id) {
                        isNewProduct = false;
                        item.quantity = Number(item.quantity) + Number(product.quantity) || 1;
                    }
                });

                if (isNewProduct === true) {
                    if (items.length > 0) {
                        //for each vendor in product
                        _.forEach(product.vendors, (productVendor) => {
                            //check every item in items

                            let productHasCommonVendor = items.every((item) => {
                                let commonVendorsWithProduct = item.vendors.find((vendor) => {
                                    return (vendor.vendor_id === productVendor.vendor_id) && (vendor.address_id === productVendor.address_id);
                                });

                                if (commonVendorsWithProduct) {
                                    _.forEach(items, (item) => {
                                        item.vendorForGetPricesId = commonVendorsWithProduct.vendor_id;
                                        item.vendorForGetPricesAddressId = commonVendorsWithProduct.address_id;
                                        product.vendorForGetPricesId = commonVendorsWithProduct.vendor_id;
                                        product.vendorForGetPricesAddressId = commonVendorsWithProduct.address_id;
                                    });
                                    return true;
                                }
                            });

                            if (productHasCommonVendor === true) {
                                vendorMatchFound = true;
                                product.hasCommonVendor = true;
                            }
                        });

                        let showAlert = () => {
                            if (vendorMatchFound === false) {
                                let alert = $mdDialog.alert()
                                    .clickOutsideToClose(true)
                                    .textContent($translate.instant('new-order.product-list.different-vendors'))
                                    .ok($translate.instant('OK'));

                                $mdDialog.show(alert).then(() => null, () => null);
                            }
                        };

                        if (vendorMatchFound && product.hasCommonVendor) {
                            items.push(product);
                        } else {
                            showAlert();
                        }
                    } else {
                        let minVendorDistance = _.min(product.vendors, (vendor) => {
                            return vendor.distance;
                        });

                        product.vendorForGetPricesId = minVendorDistance.vendor_id;
                        product.vendorForGetPricesAddressId = minVendorDistance.address_id;
                        items.push(product);
                    }
                } else {
                    //TODO write validation logic
                }

                sessionStorage['cartItems'] = JSON.stringify(items);
                $rootScope.$broadcast('newItemAppearedInCart', product);
                $timeout(() => {
                    $rootScope.$broadcast('openCartWidget');
                }, 800);
            } else {
                ToastService.show(false, $translate.instant('new-order.product-list.qty-error'));
                return false;
            }
        };

        ctrl.openZipCodeModal = () => {
            $mdDialog.show({
                parent: angular.element(document.body),
                templateUrl: 'js/components/main/order-tabs/zip-code-modal.tpl.html',
                clickOutsideToClose: true,
                fullscreen: false,
                controller: ['$scope', '$mdDialog', ($scope, $mdDialog)=> {
                    $scope.close = () => $mdDialog.cancel();
                }],
            });
        };

        ctrl.checkProductQty = (product) => {
            if (!!product) {
                product.quantity = (product.quantity < 0 || !product.quantity) ? null : product.quantity;
            }
        };
    }
})();