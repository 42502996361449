((_) => {
    angular.module('VedApp').component('vedFireplaceRegistration', {
        templateUrl: 'js/components/fireplace/registration/registration.tpl.html',
        controller: VedFireplaceRegistrationController
    });

    VedFireplaceRegistrationController.$inject = ['$translate', 'REGEXP', 'FireplaceService', 'FileUploader',
        'ProfileService', 'ToastService', 'OrderService'];

    function VedFireplaceRegistrationController($translate, REGEXP, FireplaceService, FileUploader, ProfileService,
                                                ToastService, OrderService) {
        let ctrl = this;

        ctrl.REGEXP = REGEXP;
        ctrl.imageIsTooBig = false;
        ctrl.successfullySubmited = false;
        ctrl.userHasFireplaceRequest = false;
        ctrl.fpRegistrationRequest = {
            first_name: '',
            last_name: '',
            phone_number: '',
            email: '',
            city: '',
            street: '',
            zipcode: '',
            comment: '',
            contact_me: true,
            images: []
        };
        ctrl.uploader = new FileUploader({
            url: '/order/image',
        });

        // Hook methods
        ctrl.$onInit = () => {
            if (authorized) {
                ProfileService.getUserData().then(user => {
                    ctrl.userHasFireplaceRequest = !_.isUndefined(user.fireplace_request_count) && user.fireplace_request_count > 0;
                    ctrl.fpRegistrationRequest.first_name = user.first_name;
                    ctrl.fpRegistrationRequest.last_name = user.last_name;
                    ctrl.fpRegistrationRequest.first_name = user.first_name;
                    ctrl.fpRegistrationRequest.phone_number = user.phone_number;
                    ctrl.fpRegistrationRequest.email = user.email;
                    let userAddress = user.address[0];
                    ctrl.fpRegistrationRequest.city = userAddress.city;
                    ctrl.fpRegistrationRequest.street = userAddress.street;
                    ctrl.fpRegistrationRequest.zipcode = userAddress.zipcode;
                }).catch();
            }
        };

        // Public methods
        ctrl.onFieldChanged = (name, value, form) => {
            ctrl.fpRegistrationRequest[name] = value;
        };
        ctrl.submitFPRegRequest = (formObject) => {
            if (formObject.$valid) {
                let fpRegistrationRequest = _.omit(ctrl.fpRegistrationRequest, 'images');
                fpRegistrationRequest.images = _.map(ctrl.fpRegistrationRequest.images, img => img.id);
                FireplaceService.fireplaceRegistration(fpRegistrationRequest)
                    .then(() => {
                        ctrl.successfullySubmited = true;
                    })
                    .catch(({data}) => {
                        let errorMessage = $translate.instant('errors.form.' + data.message).toString() != data.message ?
                            $translate.instant('errors.form.' + data.message) : $translate.instant('errors.form.general');
                        ToastService.show(false, errorMessage);
                    });
            }
        };

        ctrl.deleteImage = (image) => {
            if (authorized) {
                ProfileService.deleteImage(image.id).then(() => {
                    ctrl.fpRegistrationRequest.images = ctrl.fpRegistrationRequest.images.filter(imageItem => imageItem.id !== image.id);
                    ToastService.show(true, $translate.instant('errors.success'));
                });
            } else {
                ctrl.fpRegistrationRequest.images = ctrl.fpRegistrationRequest.images.filter(imageItem => imageItem.id !== image.id);
            }
        };

        ctrl.uploader.filters.push({
            name: 'imageFilter',
            fn(item) {
                let type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                return '|jpg|png|jpeg|'.indexOf(type) !== -1;
            }
        });

        ctrl.uploader.onSuccessItem = (fileItem, response) => {
            ctrl.fpRegistrationRequest.images.unshift(Object.assign({}, response, {
                selected: true
            }));
        };

        ctrl.uploader.onAfterAddingAll = () => {
            ctrl.imageIsTooBig = false;
            let validImages = _.filter(ctrl.uploader.queue, (queueFile) => {
                let fileSize = queueFile._file.size;
                ctrl.imageIsTooBig = ctrl.imageIsTooBig || fileSize > 3000000;
                return fileSize <= 3000000;
            });
            ctrl.uploader.queue = validImages;

            ctrl.uploader.uploadAll();
        };

        ctrl.uploader.onErrorItem = () => {
            ToastService.show(false, $translate.instant('errors.general'));
        };

        ctrl.useGeoLocation = () => {
            navigator.geolocation ?
                navigator.geolocation.getCurrentPosition((position) => {
                    OrderService.getAddressBasedOnLocation(position.coords)
                        .then(data => {
                            parseAddress(data[0].address_components);
                        });
                }, (error) => {
                    OrderService.alert($translate.instant('order.geolocation-error'));
                }) :
                OrderService.alert($translate.instant('order.geolocation-error'));
        };

        // Private methods
        let parseAddress = (place) => {
            let parsedAddress = {};
            if (place) {
                place.map((address, index) => {
                    let addressType = place[index].types[0];
                    let componentForm = {
                        street_number: 'short_name',
                        route: 'long_name',
                        locality: 'long_name',
                        postal_town: 'long_name',
                        zipcode: 'short_name',
                        postal_code: 'short_name'
                    };
                    parsedAddress[addressType] = place[index][componentForm[addressType]];
                });

                ctrl.fpRegistrationRequest.city = parsedAddress.postal_town || parsedAddress.locality || '';
                ctrl.fpRegistrationRequest.street = `${parsedAddress.route} ${parsedAddress.street_number}`;
                ctrl.fpRegistrationRequest.zipcode = parsedAddress.zipcode || parsedAddress.postal_code || '';

            }
        };
    }
})(_);