(() => {
    let CommunicationService = ($http) => {
        let previousState = null;

        let createChat = (form) => $http.post(`${apiURL}v2/chats`, form);
        let sendMessage = (message, chat_id) => $http.post(`${apiURL}v2/chats/${chat_id}/messages`, message);
        let getChatDetails = (chat_id) => $http.get(`${apiURL}v2/chats/${chat_id}`);
        let getChatsList = () => $http.get(`${apiURL}v2/chats`);
        let getNotifications = () => $http.get(`${apiURL}v2/chats/notifications`, {
            limit: 100
        });
        let updateChat = (chat_id, updatedTitle) => $http.put(`${apiURL}v2/chats/${chat_id}`, {
            title: updatedTitle
        });

        let setViewed = (chat_id) => $http.put(`${apiURL}v2/chats/${chat_id}/set-viewed`);

        let setState = (newState) => {
            previousState = newState;
        };

        let getState = () => {
            return previousState;
        }

        return {
            createChat,
            sendMessage,
            getChatDetails,
            getChatsList,
            updateChat,
            getNotifications,
            setState,
            getState,
            setViewed
        };
    };

    angular.module('VedApp').factory('CommunicationService', CommunicationService);
    CommunicationService.$inject = ['$http'];
})();