(() => {
    angular.module('VedApp').component('vedPrivateCustomerRegMesse', {
        templateUrl: 'js/components/user-registration/private-customer-reg-messe/private-customer-reg-messe.tpl.html',
        controller: VedPrivateCustomerRegMesseController,
    });

    VedPrivateCustomerRegMesseController.$inject = ['REGEXP', 'UserService'];

    function VedPrivateCustomerRegMesseController(REGEXP, UserService) {
        let ctrl = this;

        ctrl.client = {
            newsletter: true
        };
        ctrl.REGEXP = REGEXP;

        // Hook methods
        ctrl.$onInit = () => {
        };

        // Public methods
        ctrl.createPrivateCustomer = (formObject) => {
            if (formObject.$valid) {
                UserService.messeRegistration(ctrl.client).then(
                    ctrl.successMesseRegister = true
                );
            }
        };
    }
})();