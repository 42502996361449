(() => {
    angular.module('VedApp').component('vedCustomerChat', {
        templateUrl: 'js/components/customer-chat/customer-chat.tpl.html',
        controller: VedCustomerChatController,
        bindings: {
        }
    });

    VedCustomerChatController.$inject = ['$stateParams', 'OrderService', 'CommunicationService', '$scope', '$translate', 'FileUploader', '$timeout', 'WebSliderService', 'ToastService', 'moment'];

    function VedCustomerChatController($stateParams, OrderService, CommunicationService, $scope, $translate, FileUploader, $timeout, WebSliderService, ToastService, moment) {
        let ctrl = this;

        ctrl.authorized = authorized;
        ctrl.selectedOption = null;
        ctrl.conversation = false;
        ctrl.broadcastingBranch = broadcastingBranch;
        ctrl.chatId = null;
        ctrl.isOrderAvailable = false;
        ctrl.chosenChat = null;
        ctrl.moment = moment;
        ctrl.orders = [];
        ctrl.order = null;
        ctrl.activeMessage = '';
        ctrl.matchedChat = null;
        ctrl.imageIsTooBig = false;
        ctrl.chatCreated = null;
        ctrl.disableCategories = false;
        ctrl.viewChatImage = WebSliderService.viewImages;
        ctrl.chatsList = null;
        ctrl.uploader = new FileUploader({
            url: '/order/image'
        });
        ctrl.deliveryCategories = [
            {
                name: $translate.instant('customer-question.when-firewood')
            },
            {
                name: $translate.instant('customer-question.wrong-delivery')
            },
            {
                name: $translate.instant('customer-question.change-dates')
            },
            {
                name: $translate.instant('customer-question.other-question')
            }
        ];
        ctrl.messages = [];

        // Hook methods
        ctrl.$onInit = () => {
            if (Echo && authUserId) {
                Echo.private(ctrl.broadcastingBranch + '.user.' + authUserId).listen('.chat.message.created', (data) => {
                    let newMessage = data.message;

                    let momentData = moment(newMessage.created_at);
                    newMessage.created_at = momentData._d;

                    ctrl.messages.push(newMessage);
                    $timeout(() => {
                        scrollToBottom();
                    }, 1000);

                });
            }

            ctrl.selectedOption = ctrl.deliveryCategories[0];

            OrderService.getOrderHistory().then((orders) => {
                ctrl.orders = orders;

                if ($stateParams.type === 'ORDER') {
                    ctrl.orders.forEach((item) => {
                        if (item.order_id == $stateParams.id) {
                            ctrl.order = item;
    
                            ctrl.matchedChat = ctrl.order;
                        }
                    });
                } else if ($stateParams.type === 'GENERAL') {
                    ctrl.matchedChat = { 
                        chat_id: $stateParams.id
                    }
                    ctrl.matchedChat.chat_id = $stateParams.id;
                }

                if (ctrl.matchedChat && ctrl.matchedChat.chat_id !== null) {
                    CommunicationService.getChatDetails(ctrl.matchedChat.chat_id).then(({data}) => {
                        data.messages.forEach((message) => {
                            let momentData = moment(message.created_at);
                            message.created_at = momentData._d;
                        });

                        ctrl.messages = data.messages;
                        ctrl.chatTitle = data.title;
                        ctrl.chosenChat = data;
                        ctrl.chatId = data.id;
                        ctrl.chatCreated = data.created_at;

                        (ctrl.messages.length > 0) ? (ctrl.disableCategories = true) : (ctrl.disableCategories = false, $scope.$emit('messagesEmpty'));

                        $timeout(() => {
                            scrollToBottom();
                        }, 300);
                    });
                } else {
                    let chatObject;
                    if ($stateParams.type === 'GENERAL') {
                        chatObject = {
                            type: 'GENERAL',
                            title: ''
                        };
                    } else if ($stateParams.type === 'ORDER') {
                        chatObject = {
                            order_id: $stateParams.id,
                            type: 'ORDER',
                            title: 'Order #' + $stateParams.id
                        };
                    }

                    CommunicationService.createChat(chatObject).then(({data}) => {
                        ctrl.messages = data.messages;
                        ctrl.isAdminMessage = false;
                        ctrl.chatId = data.id;
                        $scope.$emit('messagesEmpty');
                    });
                }
            });
        };

        ctrl.uploader.onErrorItem = () => {
            ToastService.show(false, $translate.instant('errors.general'));
        };

        ctrl.uploader.filters.push({
            name: 'imageFilter',
            fn(item) {
                let type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                return '|jpg|png|jpeg|'.indexOf(type) !== -1;
            }
        });

        ctrl.uploader.onSuccessItem = (fileItem, response) => {
            ctrl.sendImage(response);
        };

        ctrl.uploader.onAfterAddingAll = () => {
            ctrl.imageIsTooBig = false;
            let validImages = _.filter(ctrl.uploader.queue, (queueFile) => {
                let fileSize = queueFile._file.size;
                ctrl.imageIsTooBig = ctrl.imageIsTooBig || fileSize > 3000000;
                (ctrl.imageIsTooBig === true) ? (ToastService.show(false, $translate.instant('errors.form.image_size'))) : null;
                return fileSize <= 3000000;
            });
            ctrl.uploader.queue = validImages;

            ctrl.uploader.uploadAll();
        };

        ctrl.sendImage = (response) => {
            let chatImage = {
                type: 'IMAGE',
                data: {
                    image: {
                        id: response.id
                    }
                }
            };

            CommunicationService.sendMessage(chatImage, ctrl.chatId).then(({data}) => {
                if (ctrl.messages.length === 0) {
                    CommunicationService.updateChat(ctrl.chatId, ctrl.selectedOption.name).then(({data}) => {
                        ctrl.selectedOption.name = data.title;
                        ctrl.disableCategories = true;
                    });
                }

                if (data.created_at) {
                    let momentData = moment(data.created_at);
                    data.created_at = momentData._d;
                }

                ctrl.messages.push(data);

                scrollToBottom();
            });
        };

        ctrl.sendMessage = () => {
            if (ctrl.activeMessage.length > 0) {
                let textMessage = {
                    type: 'TEXT',
                    message: ctrl.activeMessage
                };

                ctrl.activeMessage = '';

                CommunicationService.sendMessage(textMessage, ctrl.chatId).then(({data}) => {
                    if (ctrl.messages.length === 0) {
                        CommunicationService.updateChat(ctrl.chatId, ctrl.selectedOption.name).then(({data}) => {
                            ctrl.selectedOption.name = data.title;
                            ctrl.disableCategories = true;
                        });
                    }
                    let momentData = moment(data.created_at);
                    data.created_at = momentData._d;

                    ctrl.messages.push(data);
                    
                    scrollToBottom();
                });

            }
            
        };

        let scrollToBottom = () => {
            let objDiv = document.getElementById('chat-div');

            $timeout(() => {
                objDiv.scrollTop = objDiv.scrollHeight;
            }, 500);
        }
    }
})();