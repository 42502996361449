(() => {
    angular.module('VedApp').component('vedForVendors', {
        templateUrl: 'js/components/vendor/for-vendors/for-vendors.tpl.html',
        controller: VedForVendorsController
    });

    //VedForVendorsController.$inject = [];

    function VedForVendorsController() {
        let ctrl = this;

        // Hook methods
        ctrl.$onInit = () => {
        };
    }
})();