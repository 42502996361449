((angular, _) => {
    angular.module('VedApp').component('vedUserProfile', {
        templateUrl: 'js/components/profile/user-profile.tpl.html',
        controller: vedUserProfileController
    });

    vedUserProfileController.$inject = ['ProfileService', 'OrderService', 'ROLES', 'REGEXP', '$translate', 'ToastService', '$scope', '$timeout'];

    function vedUserProfileController(ProfileService, OrderService, ROLES, REGEXP, $translate, ToastService, $scope, $timeout) {
        let ctrl = this;
        ctrl.user = {
            newsletter: true
        };
        ctrl.REGEXP = REGEXP;
        ctrl.ROLES = ROLES;
        ctrl.isAddressExpanded = {};
        ctrl.addressesListName = 'address';
        ctrl.updateProfile = ProfileService.saveUser;
        ctrl.componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            postal_town: 'long_name',
            zipcode: 'short_name',
            postal_code: 'short_name'
        };

        ctrl.$onInit = () => {
            // Get user data
            $timeout(() => {
                sessionStorage.getItem('currentDateRange') ? sessionStorage.removeItem('currentDateRange'): null;
            }, 2000);
            ProfileService.getUserData().then((user) => {
                ctrl.user = Object.assign({}, user, ctrl.user);

                ctrl.natoUser = ctrl.user.data && ctrl.user.data.nato;
                if (ctrl.user.role === ctrl.ROLES.company) {
                    ProfileService.getCompanyData().then(company => ctrl.user = Object.assign({}, company, ctrl.user));
                    ctrl.updateProfile = ProfileService.saveCompany;
                    ctrl.addressesListName = 'departments';
                }
            });
        };

        ctrl.addAddress = (userForm) => {
            if (ctrl.user.role === ctrl.ROLES.company) {
                ctrl.user[ctrl.addressesListName].push({
                    department: '',
                    delivery_address: '',
                    city: '',
                    zip_code: '',
                    images: []
                });
                ctrl.isAddressExpanded[ctrl.user.departments.length - 1] = true;
            } else {
                ctrl.user[ctrl.addressesListName].push({
                    street: '',
                    city: '',
                    zipcode: '',
                    images: []
                });
                ctrl.isAddressExpanded[ctrl.user.address.length - 1] = true;
            }
            userForm.$setPristine();
            userForm.$setUntouched();
        };
        ctrl.isAddressDeletable = (address) => {
            return !address.id || ctrl.user[ctrl.addressesListName].filter(addressItem => !!addressItem.id).length > 1;
        };

        ctrl.deleteAddress = (address, index) => {
            if (address.id) {
                ProfileService.deleteDepartment(address.id)
                    .then(() => {
                        ctrl.user[ctrl.addressesListName] = ctrl.user[ctrl.addressesListName].filter(addressItem => addressItem.id !== address.id);
                        ToastService.show(true, $translate.instant('errors.success'));
                    })
                    .catch(({data}) => {
                        let errorMessage = $translate.instant('errors.form.' + data.error).toString() != data.error ?
                            $translate.instant('errors.form.' + data.error) : $translate.instant('errors.form.general');
                        ToastService.show(false, errorMessage);
                    });
            } else {
                ctrl.user[ctrl.addressesListName].splice(index, 1);
            }
        };

        $scope.$on('departmentChanged', (event, data) => {

        });

        ctrl.saveUserInformation = () => {
            ctrl.isSaveing = true;
            const dataToSave = Object.assign({}, ctrl.user, {addresses: ctrl.user.address});
            ctrl.updateProfile(dataToSave)
                .then(({data}) => {
                    ctrl.user[ctrl.addressesListName] = ctrl.user.role === ctrl.ROLES.company ? data.departments : data;
                    ToastService.show(true, $translate.instant('errors.success'));
                    ctrl.isSaveing = false;
                });
        };

        ctrl.onSaveUserInformation = (form) => {
            (form.$valid) ? ctrl.saveUserInformation() : null};

        ctrl.useGeoLocation = (address) => {
            navigator.geolocation ?
                navigator.geolocation.getCurrentPosition((position) => {
                    OrderService.getAddressBasedOnLocation(position.coords)
                        .then(data => {
                            parseAddress(data[0].address_components, address);
                        });
                }, (error) => {
                        OrderService.alert($translate.instant('order.geolocation-error'));
                }) :
                OrderService.alert($translate.instant('order.geolocation-error'));
        };

        // Private methods
        let parseAddress = (place, address) => {
            let parsedAddress = {};
            if (place) {
                place.map((address, index) => {
                    let addressType = place[index].types[0];
                    if (ctrl.componentForm[addressType]) {
                        parsedAddress[addressType] = place[index][ctrl.componentForm[addressType]];
                    }
                });

                address.city = parsedAddress.postal_town || parsedAddress.locality || '';
                address.zipcode = parsedAddress.zipcode;
                if (ctrl.user.role === ctrl.ROLES.company) {
                    address.delivery_address = `${parsedAddress.route} ${parsedAddress.street_number}`;
                    address.zip_code = parsedAddress.zipcode || parsedAddress.postal_code || '';
                } else {
                    address.street = `${parsedAddress.route} ${parsedAddress.street_number}`;
                    address.zipcode = parsedAddress.zipcode || parsedAddress.postal_code || '';
                }

            }
        };
    }
})(angular, _);