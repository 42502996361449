((_) => {
    angular.module('VedApp').component('vedZipCode', {
        templateUrl: 'js/components/common/zip-code/zip-code.tpl.html',
        controller: vedZipCodeController,
        bindings: {
            zipItem: '<?',
            closeZipPanel: '&'
        }
    });

    vedZipCodeController.$inject = ['$rootScope','$scope', '$mdDialog','$translate', 'NgMap', 'GOOGLE_MAP', 'REGEXP', 'ProfileService', 'OrderService', 'ZipLocationService', '$window', 'ToastService'];

    function vedZipCodeController($rootScope, $scope, $mdDialog, $translate, NgMap, GOOGLE_MAP, REGEXP, ProfileService, OrderService, ZipLocationService, $window) {
        let ctrl = this;

        ctrl.mapTheme = null;
        ctrl.map = null;
        ctrl.lang = lang;
        ctrl.currentLocation = null;
        ctrl.disabledZipcode = null;
        ctrl.authorized = authorized;
        ctrl.temporaryCoordinates = null;
        ctrl.stopEvent = false;
        ctrl.cart = [];
        ctrl.searchText = null;
        ctrl.mobile = $window.matchMedia("only screen and (max-width: 768px)").matches;
        ctrl.googleMapsUrl = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP.key}&language=${GOOGLE_MAP.language}&region=${GOOGLE_MAP.region}`;
        ctrl.address = {};
        ctrl.zipcodesList = [];
        ctrl.REGEXP = REGEXP;
        ctrl.window = angular.element($window);

        ctrl.window.bind('resize', () => {
        (window.innerWidth <= 768) ? (ctrl.mobile = true) : (ctrl.mobile = false); 
        });

        // Hook methods
        ctrl.$onInit = () => {
            setInitAddress();
            (sessionStorage.getItem('productListZip') !== 'undefined') ? (ctrl.zipItem = sessionStorage.getItem('productListZip')) : null;
            (ctrl.zipItem !== null) ? ctrl.setMapView(ctrl.zipItem) : null;

            ZipLocationService.getZipcodesList().then(({data}) => {
                ctrl.zipcodesList = data;
            }).catch();

            sessionStorage.setItem('customerAddress', JSON.stringify(ctrl.address));
            ProfileService.getMapTheme().then(response => ctrl.mapTheme = response.data);
            NgMap.getMap().then((map) => {
                ctrl.map = map;
            });
        };

        // Public methods
        ctrl.setMapView = (item) => {
            if (typeof(item) !== 'undefined') {
                ZipLocationService.getLocationByZip(item).then(({data}) => {
                    ctrl.address.zipcode = data.zipcode;
                    ctrl.address.city = data.city;
                    ctrl.address.latitude = data.latitude;
                    ctrl.address.longitude = data.longitude;

                    sessionStorage.setItem('customerAddress', JSON.stringify(ctrl.address));
                });
            }
        };

        ctrl.setZipCode = (newZipCode) => {
            ctrl.cart = sessionStorage.getItem('cartItems') ? JSON.parse(sessionStorage['cartItems']) : [];

            if (ctrl.cart.length !== 0) {
                if (ctrl.zipItem && (ctrl.zipItem.zipcode === ctrl.disabledZipcode || ctrl.zipItem === ctrl.disabledZipcode)) {
                    return;
                } else {
                    ctrl.disabledZipcode = false;
                    ctrl.setCurrentLocation(newZipCode);
                }
            } else {
                ctrl.stopEvent = false;
                ctrl.disabledZipcode = false;

                if (newZipCode && newZipCode.search(ctrl.REGEXP.ZIP_CODE_REGEXP) >= 0) {
                    ZipLocationService.getLocationByZip(newZipCode).then(({data}) => {
                        ctrl.address.zipcode = data.zipcode;
                        ctrl.address.city = data.city;
                        ctrl.address.latitude = data.latitude;
                        ctrl.address.longitude = data.longitude;
                        ctrl.address.street = data.street;

                        sessionStorage.setItem('customerAddress', JSON.stringify(ctrl.address))
                    });
                } else {
                    ctrl.address.latitude = (ctrl.lang === 'no') ? '59.9094275': '60.167471';
                    ctrl.address.longitude = (ctrl.lang === 'no') ? '10.7555123': '24.941942';
                    sessionStorage.removeItem('customerAddress');
                }

                ctrl.cart = sessionStorage.getItem('cartItems') ? JSON.parse(sessionStorage.getItem('cartItems')) : [];
                sessionStorage.setItem('productListZip', newZipCode);

                if (!_.isUndefined(newZipCode)) {
                    if (ctrl.cart.length === 0) {
                        if (ctrl.zipItem === null || !ctrl.zipItem || typeof(ctrl.zipItem) === 'undefined') {
                            ctrl.zipItem = {
                                zipcode: newZipCode
                            };
                            // $scope.$emit('newZipcode', ctrl.zipItem);
                            $rootScope.$broadcast('newZipcode', ctrl.zipItem);
                        } else {
                            $rootScope.$broadcast('newZipcode', ctrl.zipItem);
                            // $scope.$emit('newZipcode', ctrl.zipItem);
                        }
                    }
                } else {
                    setInitAddress();
                    $rootScope.$broadcast('newZipcode', ctrl.zipItem);
                    // $scope.$emit('newZipcode', ctrl.zipItem);
                }

                $rootScope.$broadcast('clearFilters');
            }
        };

        ctrl.checkEnterPressed = (event, zipItem) => {
            if (ctrl.searchText !== null) {
                let keyCode = event.which || event.keyCode;
                if (keyCode === 13) {
                    if (ctrl.searchText.length === 4 ) {
                        ctrl.setZipCode(ctrl.searchText);
                    }
                }
            }
        };

        ctrl.clickedSubmitButton = () => {
            if (ctrl.searchText !== null && ctrl.searchText.length === 4) {
                ctrl.setZipCode(ctrl.searchText);
                $mdDialog.cancel()
            }
        };

        ctrl.querySearch = (query) => {
            if (query.length < 2) {
                return [];
            }
            return _.filter(ctrl.zipcodesList, (zipItem) => {
                return zipItem.zipcode.indexOf(query) === 0;
            });
        };

        ctrl.setCurrentLocation = (newZipCode) => {
            const confirmZipCodeChange = $mdDialog.confirm({
                clickOutsideToClose: true,
                title: $translate.instant('new-order.buyer-location.sure'),
                textContent: $translate.instant('new-order.buyer-location.reset'),
                ok: $translate.instant('new-order.buyer-location.yes'),
                cancel: $translate.instant('new-order.buyer-location.no')
            });

            let cartStatus = JSON.parse(sessionStorage.getItem('cartItems'));

            if (cartStatus.length > 0) {
                $mdDialog.show(confirmZipCodeChange).then(() => {
                    ctrl.cart = [];
                    sessionStorage['cartItems'] = JSON.stringify(ctrl.cart);
                    
                    $rootScope.$broadcast('newItemAppearedInCart');

                    if (newZipCode && newZipCode.search(ctrl.REGEXP.ZIP_CODE_REGEXP) >= 0) {
                        ZipLocationService.getLocationByZip(newZipCode).then(({data}) => {
                            ctrl.address.zipcode = data.zipcode;
                            ctrl.address.city = data.city;
                            ctrl.address.latitude = data.latitude;
                            ctrl.address.longitude = data.longitude;
                            ctrl.address.street = data.street;

                            sessionStorage.setItem('customerAddress', JSON.stringify(ctrl.address))
                        });
                    } else {
                        ctrl.address.latitude = (ctrl.lang === 'no') ? '59.9094275': '60.167471';
                        ctrl.address.longitude = (ctrl.lang === 'no') ? '10.7555123': '24.941942';
                        sessionStorage.removeItem('customerAddress');
                    }
                
                    ctrl.cart = sessionStorage.getItem('cartItems') ? JSON.parse(sessionStorage.getItem('cartItems')) : [];
                    sessionStorage.setItem('productListZip', newZipCode);

                    if (typeof(newZipCode) !== 'undefined') {

                        if (ctrl.cart.length === 0) {
                            if (ctrl.zipItem === null) {
                                ctrl.zipItem = {
                                    zipcode: newZipCode
                                };
                                $rootScope.$broadcast('newZipcode', ctrl.zipItem);
                                // $scope.$emit('newZipcode', ctrl.zipItem);
                            } else {
                                $rootScope.$broadcast('newZipcode', ctrl.zipItem);
                                // $scope.$emit('newZipcode', ctrl.zipItem);
                            }
                        }
                    }

                    if (typeof(newZipCode) === 'undefined') {
                        $rootScope.$broadcast('newZipcode', ctrl.zipItem);
                        // $scope.$emit('newZipcode', ctrl.zipItem);
                    }

                    $rootScope.$broadcast('clearFilters');
                }, //end of then promise
                () => {
                    ctrl.stopEvent = true;
                    ctrl.disabledZipcode = sessionStorage.getItem('productListZip');
                    if (ctrl.zipItem === null) {
                        ctrl.zipItem = sessionStorage.getItem('productListZip');
                    } else {
                        ctrl.zipItem.zipcode = sessionStorage.getItem('productListZip');
                    }
                });
            }
        
        };

        let setInitAddress = () => {
            ctrl.address = {
                street: '',
                city: '',
                zipcode: '',
                latitude: (ctrl.lang === 'no') ? '59.9094275': '60.167471',
                longitude: (ctrl.lang === 'no') ? '10.7555123': '24.941942'
            };
        }
    }
})(_);
