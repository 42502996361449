(() => {
    let UserService = ($http) => {
        let login = (data) => $http.post(`${apiURL}login`, data);
        let registration = (data) => $http.post(`${apiURL}buyer-registration`, data);
        let messeRegistration = (data) => $http.post(`${apiURL}messe-registration`, data);
        let companyRegistration = (data) => $http.post(`${apiURL}company-registration`, data);
        let vendorRegistration = (data) => $http.post(`${apiURL}vendor-registration`, data);
        let logout = () => $http.get(`${apiURL}logout`);
        let recoverPassword = (email) => $http.post(`${apiURI}password/reset`, {email: email});
        let resetPassword = (data) => $http.post(`${apiURL}v1/password/set`, data);
        let sendPassword = (data) => $http.post(`${apiURL}send-password`, data);

        return {
            login,
            logout,
            recoverPassword,
            resetPassword,
            sendPassword,
            registration,
            messeRegistration,
            companyRegistration,
            vendorRegistration
        };
    };

    angular.module('VedApp').factory('UserService', UserService);
    UserService.$inject = ['$http'];
})();