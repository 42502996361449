(() => {
    angular.module('VedApp').component('vedFaq', {
        templateUrl: 'js/components/faq/faq.tpl.html',
        controller: VedFaqController
    });

    VedFaqController.$inject = [];

    function VedFaqController() {
        let ctrl = this;

        ctrl.authorized = authorized;
        ctrl.faqList = [];

        // Hook methods
        ctrl.$onInit = () => {
            initFaqItems();
        };

        const initFaqItems = () => {

            for(let i = 1; i <= 22; i++){

                ctrl.faqList.push({
                    "question": "faq.question." + i,
                    "answer"  : "faq.answer." + i
                });

            }

        };

        ctrl.toggleAccordion = (event, index) => {

            ctrl.faqList.map((item, key) => {
                item.active = key === index ? !item.active : false;

                if(key === index && !item.maxHeight){
                    item.maxHeight = event.target.nextElementSibling.scrollHeight + 'px';
                } else {
                    item.maxHeight = null;
                }
            });

        }
    }
})();