(() => {
    angular.module('VedApp').component('vedPersonalInfo', {
        templateUrl: 'js/components/cart/personal-info/personal-info.tpl.html',
        controller: VedPersonalInfoController,
        bindings: {
            order: '<',
            step: '<',
            onSave: '&',
            onImageUpload: '&'
        }
    });

    VedPersonalInfoController.$inject = ['$rootScope', 'OrderService', 'REGEXP', 'FileUploader', 'ROLES', '$translate',
     'ToastService', '$scope', '$window', '$interval', 'ProfileService', '$mdDialog', '$state', 'OrderCartService', 'NgMap', 'GOOGLE_MAP', 'ZipLocationService'];

    function VedPersonalInfoController($rootScope, OrderService, REGEXP, FileUploader, ROLES, $translate,
         ToastService, $scope, $window, $interval, ProfileService, $mdDialog, $state, OrderCartService, NgMap, GOOGLE_MAP, ZipLocationService) {
        let ctrl = this;
        ctrl.authorized = authorized;
        ctrl.lang = lang;

        ctrl.imageIsTooBig = false;
        ctrl.REGEXP = REGEXP;
        ctrl.interval = null;
        ctrl.allowedDays = null;
        ctrl.isFromGeolocation = false;
        ctrl.copiedObject = null;
        ctrl.oldAddress = null;
        ctrl.packages = [];
        ctrl.order2Save = null;
        ctrl.currentDateRange = null;
        ctrl.googleMapsUrl = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP.key}&language=${GOOGLE_MAP.language}&region=${GOOGLE_MAP.region}`;
        ctrl.place = null;
        ctrl.placeCountry = lang;
        ctrl.componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            postal_town: 'long_name',
            zipcode: 'short_name',
            postal_code: 'short_name'
        };
        ctrl.isMobile = false;
        ctrl.mobile = $window.matchMedia("only screen and (max-width: 600px)").matches;
        ctrl.uploader = new FileUploader({
            url: '/order/image',
        });

        ctrl.isMobileDevice = () => {
            let check = false;
            (function (a) {
                if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        };

        ctrl.$onInit = () => {
            sessionStorage.getItem('currentDateRange') ? ctrl.currentDateRange = JSON.parse(sessionStorage.getItem('currentDateRange')) : ctrl.currentDateRange;
            sessionStorage.getItem('newOrder') ? (ctrl.order = JSON.parse(sessionStorage.getItem('newOrder'))) : ctrl.order;
            sessionStorage.getItem('allowedDays') ? (ctrl.allowedDays = JSON.parse(sessionStorage.getItem('allowedDays'))) : ctrl.allowedDays;

            if (ctrl.authorized) {
                ProfileService.getUserData().then((data) => {
                    // if user has multiple addresses, select first one
                    ctrl.order.first_name = data.first_name;
                    ctrl.order.last_name = data.last_name;
                    ctrl.order.email = data.email;
                    ctrl.order.confirm_email = data.email;
                    ctrl.order.phone_number = data.phone_number;
                    ctrl.order.location.street = data.address[0].street;
                    ctrl.order.location.zipcode = data.address[0].zipcode;
                    ctrl.order.location.city = data.address[0].city;
                    ctrl.order.location.latitude = data.address[0].latitude;
                    ctrl.order.location.longitude = data.address[0].longitude;
                    ctrl.order.images = data.address[0].images;

                    //TODO images for company users

                    if (sessionStorage.getItem('customerAddress') && sessionStorage.getItem('productListZip')
                     && sessionStorage.getItem('productListZip') !== ctrl.order.location.zipcode && !sessionStorage.getItem('validationPassed')) {
                        ctrl.order.location = JSON.parse(sessionStorage.getItem('customerAddress'));
                    } else if (sessionStorage.getItem('customerAddress') && sessionStorage.getItem('validationPassed')) {
                        ctrl.order.location = JSON.parse(sessionStorage.getItem('customerAddress'));
                        ZipLocationService.getLocationByZip(ctrl.order.location.zipcode).then(({data}) => {
                            ctrl.order.location.latitude = data.latitude;
                            ctrl.order.location.longitude = data.longitude;
                        });
                    }

                    if (sessionStorage.getItem('customerAddress') && ctrl.order.selectedAddress === true) {
                        ctrl.order.location = JSON.parse(sessionStorage.getItem('customerAddress'));
                    }
                }).catch();
            }
            
            ctrl.interval = $interval(ctrl.checkFormValidity, 2000);
            ctrl.isMobile = ctrl.isMobileDevice();
            

            if (sessionStorage.getItem('customerAddress') && !ctrl.authorized) {
                ctrl.order.location = JSON.parse(sessionStorage.getItem('customerAddress'));
            } else if (!ctrl.authorized) {
                ctrl.order.location.latitude = (ctrl.lang === 'no') ? '59.9094275' : '60.167471';
                ctrl.order.location.longitude = (ctrl.lang === 'no') ? '10.7555123' : '24.941942';
            }

            ctrl.carryPrice = Number(sessionStorage.getItem('carryPrice'));
            ctrl.copiedObject = OrderCartService.getOrderToPay();

            OrderCartService.getPackages().then(({data}) => {
                ctrl.packages = Object.values(data);
            });

            if (ctrl.copiedObject &&  ctrl.copiedObject !== null) {
                OrderCartService.getOrder(ctrl.copiedObject.order_id).then(({data}) => {
                    ctrl.order2Save = {
                        vendor_id: data.vendor_id,
                        vendor_address_id: data.vendor_address_id,
                        first_name: data.first_name,
                        last_name: data.last_name,
                        email: data.email,
                        phone_number: data.phone_number,
                        location: {
                            city: data.city,
                            street: data.street,
                            zipcode: data.zipcode
                        },
                        products: ctrl.sortProductsForOrder(data.lines),
                        delivery_date_range: data.delivery_date_range,
                        custom_range: null,
                        excluded_delivery_dates: ctrl.getExcludedDeliveryDates(data.excluded_delivery_dates),
                        carry: data.carry,
                        delivery_time_morning: data.delivery_time_morning,
                        delivery_time_midday: data.delivery_time_midday,
                        delivery_time_evening: data.delivery_time_evening,
                        services: [],
                        prepaid_percentage: data.prepaid_percentage,
                        coupon_code: data.coupon_code,
                        comment: data.comment,
                        transaction_token: null,
                        images: []
                    };

                    ctrl.order = Object.assign({}, ctrl.order, {
                        vendor_id: data.vendor_id,
                        vendor_address_id: data.vendor_address_id,
                        min_order_value: null,
                        first_name: data.first_name,
                        last_name: data.last_name,
                        totalSum: 0,
                        email: data.email,
                        confirm_email: data.email,
                        phone_number: data.phone_number,
                        location: {
                            zipcode: data.zipcode,
                            city: data.city,
                            street: data.street,
                            department: '',
                            latitude: '',
                            longitude: ''
                        },
                        products: ctrl.sortProductsForView(data.lines),
                        delivery_date_range: data.delivery_date_range,
                        custom_range: {
                            start_date: null,
                            end_date: null
                        },
                        carry: false,
                        services: {
                            not_at_home: false,
                            not_big_car: false
                        },
                        deliveryTime: {
                            '8_12': data.delivery_time_morning,
                            '12_17': data.delivery_time_midday,
                            '17_22': data.delivery_time_evening
                        },
                        prepaid_percentage: data.prepaid_percentage,
                        orderTotalSum: 0,
                        ranges: {
                            excludedDeliveryDates: data.excluded_delivery_dates
                        },
                        deliveryCost: (data.delivery_costs < 200) ? data.delivery_costs * 2 : data.delivery_costs,
                        coupon_code: {
                            type: '',
                            value: data.coupon_code,
                            valid: false
                        },
                        comment: data.comment,
                        transaction_token: data.transaction_token,
                        images: data.images
                    });

                    let totalCount = 0;
                    ctrl.order.products.map((product) => {
                        totalCount = totalCount + product.product_total;
                    });
                    ctrl.order.totalSum = totalCount;
                    ctrl.carryPrice = ctrl.copiedObject ? ctrl.copiedObject.carryPrice : null;
                    sessionStorage.setItem('carryPrice', ctrl.carryPrice);
                });
            }
        };

        ctrl.checkFormValidity = () => {
            ctrl.step.validated = ctrl.personalInfoForm.$valid;

            if (ctrl.step.validated) {
                sessionStorage.setItem('newOrder', JSON.stringify(ctrl.order));
            }
        };

        ctrl.$onDestroy = () => {
            $interval.cancel(ctrl.interval);
            sessionStorage.setItem('newOrder', JSON.stringify(ctrl.order));
            sessionStorage.setItem('customerAddress', JSON.stringify(ctrl.order.location));
        };

        ctrl.findProductImage = (item) => {
            let createdImage = ctrl.packages.find((pack) => {
                if (pack.capacity == item.product_package) {
                    return pack.image;
                }
            });
            return createdImage.image;
        }

        $scope.$on('addressChanged', (event, data) => {
            ctrl.order.location.street = data.street;
            ctrl.order.location.zipcode = data.zipcode;
            ctrl.order.location.city = data.city;
            ctrl.order.location.latitude = data.latitude;
            ctrl.order.location.longitude = data.longitude;
            ctrl.order.selectedAddress = true;
            sessionStorage.setItem('customerAddress', JSON.stringify(ctrl.order.location));
        });

        ctrl.sortProductsForView = (products) => {
            let sortedProducts = [];

            _.forEach(products, (productItem) => {
                let newProd = {
                    quantity: productItem.product_quantity,
                    id: productItem.id,
                    price: productItem.price,
                    carryPrice : productItem.carry_price,
                    product_total: productItem.total,
                    category: {
                        id: productItem.product_category_id,
                        name: productItem.product_name,
                    },
                    package: {
                        capacity: productItem.product_package,
                        image: ctrl.findProductImage(productItem),
                        id: productItem.product_pack_id
                    }
                };

                sortedProducts.push(newProd);
            });

            return sortedProducts;
        };

        ctrl.getExcludedDeliveryDates = (excludedDates) => {
            let sortedExcludedDates = [];

            _.forEach(excludedDates, (date) => {
                date = moment(date).format('YYYY-MM-DD');

               sortedExcludedDates.push(date);
            });

            return sortedExcludedDates;
        };

        ctrl.sortProductsForOrder = (products) => {
            let sortedProducts = [];

            _.forEach(products, (productItem) => {
                let newProd = {
                    category_id: productItem.product_category_id,
                    package_id: productItem.product_pack_id,
                    quantity: productItem.product_quantity
                };

                sortedProducts.push(newProd);
            });

            return sortedProducts;
        }

        $window.onbeforeunload = () => {
            sessionStorage.setItem('newOrder', JSON.stringify(ctrl.order));
            sessionStorage.setItem('customerAddress', JSON.stringify(ctrl.order.location));
        };

        ctrl.autocompleteSubmitHandler = function () {
            ctrl.oldAddress = ctrl.order.location.street;
            ctrl.place = this.getPlace();

            if (ctrl.place.length === 0) {
                return;
            }

            ctrl.parseAddress(ctrl.place.address_components);
            ctrl.parseCoordinates(ctrl.place.geometry.location);
        };

        ctrl.parseAddress = (place) => {
            let parsedAddress = {};
            if (place) {
                place.map((address, index) => {
                    let addressType = place[index].types[0];
                    if (ctrl.componentForm[addressType]) {
                        parsedAddress[addressType] = place[index][ctrl.componentForm[addressType]];
                    }
                });

                ctrl.saveLocationAddress(parsedAddress);
            }
        };

        ctrl.parseCoordinates = (location) => {
            ctrl.order.location.latitude = location.lat();
            ctrl.order.location.longitude = location.lng();
        };

        ctrl.saveLocationAddress = ({postal_town = '', locality = '',  postal_code = '', route = '', street_number = '', zipcode = ''}) => {
            const address = Object.assign({}, {
                city: postal_town || locality,
                zipcode: postal_code || zipcode,
                street: `${route} ${street_number}`
            });

            ctrl.updateAddress(address);
        };

        ctrl.openAddressModal = () => {
            $mdDialog.show({
                parent: angular.element(document.body),
                template: `
                    <div class="address-modal-info">
                    <h5>{{'new-order.personal.select-address' | translate}}</h5>
                    <md-input-container>
                    <span class="address-label">{{'new-order.personal.address' | translate}}</span>
                    <md-select ng-model="loggedUserAddress">
                        <md-option ng-value="option" ng-repeat="option in addresses">
                            <span>{{option.street}}, {{option.city}}, {{option.zipcode}}</span>
                        </md-option>
                    </md-select>
                    </md-input-container>

                    <div class="address-buttons">
                        <button class="cancel" ng-click="closeModal()">{{'new-order.personal.cancel' | translate}}</button>
                        <button class="submit" ng-click="submitModal()">{{'new-order.personal.ok' | translate}}</button>
                    </div>
                    </div>
                    
                `,
                clickOutsideToClose: true,
                fullscreen: false,
                controller: ['$scope', '$mdDialog', 'ProfileService', '$rootScope', '$translate', ($scope, $mdDialog, ProfileService, $rootScope, $translate) => {
                    $scope.addresses = [];
                    $scope.loggedUserAddress = null;

                    ProfileService.getUserData().then((data) => {
                        $scope.addresses = data.address;
                        $scope.loggedUserAddress = data.address[0];
                    }).catch();

                    $scope.submitModal = () => {
                        let newAddress = {
                            street: $scope.loggedUserAddress.street,
                            city: $scope.loggedUserAddress.city,
                            zipcode: $scope.loggedUserAddress.zipcode,
                            latitude: $scope.loggedUserAddress.latitude,
                            longitude: $scope.loggedUserAddress.longitude
                        };
                        $rootScope.$broadcast('addressChanged', newAddress);
                        $mdDialog.hide();
                        // sessionStorage.setItem('newAddress', JSON.stringify(newAddress));
                    };

                    $scope.closeModal = () => {
                        $mdDialog.hide();
                    }
                }]
            });
        };

        ctrl.checkZipcodeChanged = (zipcode) => {
            let productListZip = sessionStorage.getItem('productListZip');

            if (zipcode !== productListZip && (productListZip && productListZip.length === 4)) {
                const zipcodeChangeConfirm = $mdDialog.confirm({
                    clickOutsideToClose: true,
                    title: $translate.instant('new-order.personal.change-code'),
                    ok: $translate.instant('new-order.personal.change'),
                    cancel: $translate.instant('new-order.personal.cancel')
                });

                $mdDialog.show(zipcodeChangeConfirm).then(() => {

                    $rootScope.$broadcast('clearCart');
                    sessionStorage.clear();
                    $state.go('products-list');
                    
                }, () => {
                    ctrl.order.location.zipcode = productListZip;
                });

            }
        };

        ctrl.updateAddress = (address) => {
            let productListZip = sessionStorage.getItem('productListZip');
            if ((ctrl.order.location.zipcode !== address.zipcode && ctrl.order.location.zipcode.length > 0 && (productListZip && productListZip.length === 4)) ||
                (ctrl.order.location.zipcode !== address.zipcode && ctrl.order.location.zipcode.length > 0 && !ctrl.authorized && sessionStorage.getItem('productListZip'))) {
                const zipcodeChangeConfirm = $mdDialog.confirm({
                    clickOutsideToClose: true,
                    title: $translate.instant('new-order.personal.change-code'),
                    ok: $translate.instant('new-order.personal.change'),
                    cancel: $translate.instant('new-order.personal.cancel')
                });

                $mdDialog.show(zipcodeChangeConfirm).then(() => {
                    ctrl.order.location.city = address.city;
                    ctrl.order.location.street = address.street;
                    ctrl.order.location.zipcode = address.zipcode;

                    $rootScope.$broadcast('clearCart');
                    sessionStorage.clear();
                    sessionStorage.setItem('productListZip', address.zipcode);
                    $state.go('products-list');
                    
                }, () => {
                    let item = JSON.parse(sessionStorage.getItem('customerAddress'));
                    if (item.street) {
                        ctrl.order.location.street = item.street;
                    } else {
                        ctrl.order.location.street = '';
                    }
                });
            } else if ((ctrl.order.location.zipcode === address.zipcode) && (ctrl.order.location.street !== address.street) &&
                         (ctrl.order.coupon_code.valid === true) && (ctrl.order.coupon_code.type === 'group_order')) {
                let coupon = ctrl.order.coupon_code.value;
                let location = address;
                OrderCartService.validateCouponCode(coupon, location).then(({data}) => {
                    if (data.status === 'error') {
                        if (data.message === 'out_of_area') {
                            const deliveryAddressChangeConfirm = $mdDialog.confirm({
                                clickOutsideToClose: true,
                                title: $translate.instant('new-order.personal.radius-error'),
                                ok: $translate.instant('new-order.personal.ok'),
                                cancel: $translate.instant('new-order.personal.cancel')
                            });

                            $mdDialog.show(deliveryAddressChangeConfirm).then(() => {
                                ctrl.order.location.street = address.street;
                                ctrl.order.coupon_code.value = null;
                            }, () => {
                                let item = JSON.parse(sessionStorage.getItem('customerAddress'));
                                if (item.street) {
                                    ctrl.order.location.street = item.street;
                                } else {
                                    ctrl.order.location.street = '';
                                }
                            });
                        }
                    }
                });
            } else if ((!ctrl.authorized && sessionStorage.getItem('productListZip') === 'undefined') 
            || (!ctrl.authorized && !sessionStorage.getItem('productListZip')) || (ctrl.isFromGeolocation === true)) {
                ctrl.order.location.city = address.city;
                ctrl.order.location.street = address.street;
                ctrl.order.location.zipcode = address.zipcode;
            }
        };

        ctrl.handlePosition = (position) => {
            OrderService.getAddressBasedOnLocation(position.coords)
                .then(data => {
                    ctrl.isFromGeolocation = true;
                    ctrl.parseAddress(data[0].address_components)
                });
        };

        ctrl.useCurrentLocation = () => {
            (navigator.geolocation) ?
                navigator.geolocation.getCurrentPosition(ctrl.handlePosition, ctrl.geolocationError) :
                OrderService.alert($translate.instant('order.geolocation-error'));
        };

        ctrl.geolocationError = (error) => OrderService.alert($translate.instant(`errors.geolocation.${error.code}`));

        ctrl.uploader.filters.push({
            name: 'imageFilter',
            fn(item) {
                let type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                return '|jpg|png|jpeg|'.indexOf(type) !== -1;
            }
        });

        ctrl.uploader.onSuccessItem = (fileItem, response) => {
            (!ctrl.order.hasOwnProperty('images')) ? ctrl.order.images = [] : null;
            ctrl.order.images.unshift(Object.assign({}, response, {
                selected: true
            }));
        };

        ctrl.uploader.onAfterAddingAll = () => {
            ctrl.imageIsTooBig = false;
            let validImages = _.filter(ctrl.uploader.queue, (queueFile) => {
                let fileSize = queueFile._file.size;
                ctrl.imageIsTooBig = ctrl.imageIsTooBig || fileSize > 3000000;
                return fileSize <= 3000000;
            });
            ctrl.uploader.queue = validImages;

            ctrl.uploader.uploadAll();
        };

        ctrl.toggleImageSelection = (image) => image.selected = !image.selected;

        ctrl.uploader.onErrorItem = () => {
            ToastService.show(false, $translate.instant('errors.general'));
        };

        ctrl.onFieldChanged = (form) => {
            ctrl.step.validated = form.$valid;

            if (ctrl.step.validated) {
                sessionStorage.setItem('newOrder', JSON.stringify(ctrl.order));
            }
        }
    }
})();