((angular) => {
    angular.module('VedApp').component('vedBanner', {
        templateUrl: 'js/components/home/banner/banner.tpl.html',
        controller: vedBannerController
    });

    vedBannerController.$inject = ['$window'];

    function vedBannerController ($window) {
        let ctrl = this;

        ctrl.description = $window.matchMedia("only screen and (min-width: 768px)").matches;
        ctrl.lang = lang;

        // Hook methods
        ctrl.$onInit = () => {
        };

        ctrl.toggleDescription = () => ctrl.description = !ctrl.description;
    }
})(angular);