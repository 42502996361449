((angular) => {
    angular.module('VedApp').component('vedTutorials', {
        templateUrl: 'js/components/main/tutorials/tutorials.tpl.html',
        controller: VedTutorialsController
    });

    VedTutorialsController.$inject = ['$state', '$mdDialog', '$sce', '$window', 'TutorialsService'];

    function VedTutorialsController($state, $mdDialog, $sce, $window, TutorialsService) {
        let ctrl = this;
        let tutorials = [];
        ctrl.tutorialsView = [];
        ctrl.lang = lang;

        // Hook methods
        ctrl.$onInit = () => {
            lang === 'no' && TutorialsService.getTutorials().then(({data}) => {
                tutorials = data.data;
                ctrl.tutorialsView = tutorials.slice(0, 1);
            });
        };

        ctrl.goTo = (url) => {
            $state.go(url);
        };

        // Public methods
        ctrl.openTutorial = (url) => {
            $mdDialog.show({
                parent: angular.element(document.body),
                template: `
                    <md-dialog>
                    <md-dialog-content class="md-dialog-content" role="document">
                        <md-button class="md-icon-button close-button" ng-click="close()">
                            <i class="material-icons">close</i>
                        </md-button>
                        <div class="row padding-top no-vr-margin">
                            <div class="col s12">
                                <iframe src="${url}" style="max-width: 100% !important;" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                            </div>
                        </div>
                    </md-dialog-content>
                    </md-dialog>`,
                controller: ['$scope', '$mdDialog', ($scope, $mdDialog)=> {
                    $scope.close = () => $mdDialog.cancel();
                }],
                clickOutsideToClose: true,
                fullscreen: false
            });
        };
    }
})(angular);