(() => {
    let ArticlesService = ($http) => {
        return {
            getArticles: () => $http.get(`${apiURI}articles`),
            getArticle: (articleName) => $http.get(`${apiURI}articles/${articleName}`)
        }
    };

    angular.module('VedApp').factory('ArticlesService', ArticlesService);
    ArticlesService.$inject = ['$http'];
})();