(() => {
    angular.module('underscore', []).factory('_', ['$window', ($window) => $window._]);

    angular.module('VedApp', [
            'ui.router',
            'pascalprecht.translate',
            'underscore',
            'ngMessages',
            'ngMaterial',
            'ngMap',
            'ngPassword',
            'ngAnimate',
            'angularMoment',
            'angularFileUpload',
            'angularPayments',
            'angular-owl-carousel-2',
            'angular-click-outside',
            'multipleDatePicker',
            'duScroll',
            'ngMap',
            'tmh.dynamicLocale'
        ])
        .config(['$translateProvider', ($translateProvider) => {
            $translateProvider.useSanitizeValueStrategy(null);
            $translateProvider.useStaticFilesLoader({
                prefix: '/../i18n/',
                suffix: '.json'
            });
            $translateProvider.preferredLanguage(lang);
        }])
        .config(['tmhDynamicLocaleProvider', (tmhDynamicLocaleProvider) => {
            tmhDynamicLocaleProvider.localeLocationPattern(`./../translations/locale/{{locale}}/angular-locale_{{locale}}.js`);
            tmhDynamicLocaleProvider.defaultLocale(lang);
        }])
        .config(['$compileProvider', '$mdThemingProvider', '$mdDateLocaleProvider', ($compileProvider, $mdThemingProvider, $mdDateLocaleProvider) => {
            $compileProvider.preAssignBindingsEnabled(true);

            // custom md theme
            $mdThemingProvider.definePalette('vedPrimaryColor', {
                '50': 'FFFFFF',
                '100': 'BFD5CA',
                '200': '94B9A7',
                '300': '699C84',
                '400': '488769',
                '500': '28724F',
                '600': '246A48',
                '700': '1E5F3F',
                '800': '185536',
                '900': '0F4226',
                'A100': 'cfe8db',
                'A200': '49FF94',
                'A400': '16FF75',
                'A700': '00FC67',
                'contrastDefaultColor': 'light',    // whether, by default, text on this palette should be dark or light
                'contrastDarkColors': ['50', '100', '200', '300', '400', 'A100'], //hues which contrast should be 'dark' by default
                'contrastLightColors': undefined    // could also specify this if default was 'dark'
            });
            $mdThemingProvider.theme('default')
                .primaryPalette('vedPrimaryColor', {
                    'default': '500',
                    'hue-1': 'A100',
                })
                .accentPalette('brown');
            $mdDateLocaleProvider.firstDayOfWeek = 1;
        }])
        .config(['$httpProvider', ($httpProvider) => $httpProvider.interceptors.push('HttpInterceptor')])
        .run(['$rootScope', 'amMoment', 'LANGUAGES', '$templateCache', ($rootScope, amMoment, $templateCache) => {
            amMoment.changeLocale(lang);
            //$rootScope.$on('$viewContentLoaded', () => $templateCache.removeAll());
        }]);
})();