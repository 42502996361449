(() => {
    angular.module('VedApp').component('vedTopNavMenu', {
        templateUrl: 'js/components/top-nav-menu/top-nav-menu.tpl.html',
        controller: VedTopNavMenuController
    });

    VedTopNavMenuController.$inject = ['$rootScope', '$scope', '$state', '$document', '$element', '$window', '$timeout'];

    function VedTopNavMenuController($rootScope, $scope, $state, $document, $element, $window, $timeout) {
        let ctrl = this;

        ctrl.menuList = [];
        ctrl.lang = lang;
        ctrl.isAuthorized = authorized;
        ctrl.isSideMenuOpened = false;
        ctrl.menuItemName = '';
        ctrl.isResetPasswordPage = false;

        // Hook methods
        ctrl.$onInit = () => {
            initMenuItems();
            setSelectedMenuItem();
            ctrl.stateChangeListener = $rootScope.$on('$stateChangeSuccess', setSelectedMenuItem);
            ctrl.logoutListener = $scope.$on('user-logged-out-login', () => {
                ctrl.isAuthorized = authorized;
                $state.go('user-profile');
            });
            ctrl.loginListener = $scope.$on('user-logged-in', () => {
                authorized = true;
                ctrl.isAuthorized = authorized;
            });

            $document.on('click', closeDropdownMenus);

            $window.location.pathname == '/reset-password' ? ctrl.isResetPasswordPage = true : ctrl.isResetPasswordPage = false;
        };

        ctrl.$onDestroy = () => {
            $document.off('click', closeDropdownMenus);
            ctrl.stateChangeListener();
        };

        // Public methods
        ctrl.openMenuItem = (menuItem) => {
            if (ctrl.isSideMenuOpened === true) {
                ctrl.isSideMenuOpened = false;
            }

            $state.go(menuItem.href);
        };
        ctrl.openSubmenuItem = (menuItem, subMenuItem) => {
            menuItem.showDropdown = !menuItem.showDropdown;
            if (ctrl.isSideMenuOpened === true) {
                ctrl.isSideMenuOpened = false;
            }

            //go to products list fix
            $state.go(subMenuItem.href);
            // subMenuItem.href !== ctrl.menuItemName ? $state.go(subMenuItem.href) : $state.reload();
        };

        ctrl.toggleSideMenu = () => {
            ctrl.isSideMenuOpened = !ctrl.isSideMenuOpened;
        };

        ctrl.hideMenu = () => {
            if (ctrl.isSideMenuOpened === true) {
                ctrl.isSideMenuOpened = false;
            }
        }

        // Private methods
        let initMenuItems = () => {
            ctrl.menuList = [
                {
                    title: 'header.menu.home',
                    href: 'main',
                    childRoutes: ['main']
                },
                {
                    title: 'header.menu.order',
                    childRoutes: ['order', 'group-order'],
                    subItems: [
                        {
                            title: 'header.menu.ordinary-order',
                            href: 'products-list'
                        },
                        {
                            title: 'header.menu.group-order',
                            href: 'group-order'
                        }
                    ]
                },
                {
                    title: 'header.menu.fireplace',
                    href: 'fireplace',
                    lang: 'no',
                    childRoutes: ['fireplace']
                },
                {
                    title: 'header.menu.app',
                    href: 'about-us',
                    childRoutes: ['about-us'],
                    disabled: true,
                    lang: 'no'
                },
                {
                    title: 'header.menu.vendors',
                    href: 'for-vendors',
                    onlyForUnauthorized: true,
                    childRoutes: ['for-vendors', 'vendor-registration']
                },
                {
                    title: 'header.menu.registration',
                    href: 'registration',
                    onlyForUnauthorized: true,
                    childRoutes: ['registration']
                },
                {
                    title: 'dugnad.menu-title',
                    href: 'dugnad',
                    childRoutes: ['dugnad', 'create-dugnad', 'dugnad-contact-me']
                },
                {
                    title: 'header.menu.contact-us',
                    href:  'contacts',
                    childRoutes: ['contacts']
                },
                {
                    title: 'header.menu.need-help',
                    href: 'helper',
                },
                {
                    title: 'header.menu.faq',
                    href: 'faq',
                    lang: 'no',
                }
            ];
        };

        let setSelectedMenuItem = () => {
            // Close side menu, if it was opened
            ctrl.isSideMenuOpened = false;

            // Deactivate all menu items
            ctrl.menuList.map(i => {
                i.active = false;
                i.showDropdown = false;
            });

            // Find Active parent menu item
            ctrl.menuItemName = $state.current.name;
            let initMenuItem = {};
            let activeMenuItem = _.find(ctrl.menuList, {href: ctrl.menuItemName});
            if (!activeMenuItem) {
                _.map(ctrl.menuList, (menuItem) => {
                    activeMenuItem = menuItem.childRoutes && menuItem.childRoutes.indexOf(ctrl.menuItemName) > -1 ? menuItem : null;
                });
            }
            if (activeMenuItem) {
                activeMenuItem.active = true;
            }
            initMenuItem.active = true;
        };

        const closeDropdownMenus = (event) => {
            if (event.target !== $element[0] && _.isFunction($element.find) && $element.find(event.target).length === 0) {
                ctrl.menuList.forEach(i => i.showDropdown = false);
                $scope.$apply();
            }
        }
    }
})();