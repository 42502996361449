(() => {
    let OrderService = ($http, ROLES, moment, $mdDialog, $translate, GOOGLE_MAP) => {
        let getUser = () => $http.get(`/user`);

        let getPreSelectedProduct = ({category_id, pack_id}, {city, street: address, zipcode}) => {
            return $http.post(`/v1/vendors/select`, {
                category_id: +(category_id),
                pack_ids: JSON.stringify([pack_id]),
                delivery_date_range: '7_12',
                location: JSON.stringify({
                    city,
                    zipcode,
                    address
                })
            });
        };
        //assign group code

        let assignGroupCode = (orderId, campaignCode) => $http.post(`v2/orders/${orderId}/set-group-code`, {
            group_code: campaignCode
        });
        // Group code
        let generateCode = () => $http.get('/coupon/generate');
        let sendCouponCode = (code, neighbors) => $http.post(`/coupon/share/${code}`, JSON.stringify(neighbors));
        let applyCode = (code, {city, zipcode, street}) => $http.post(`/api/validate-coupon-code/${code}`, {
            city,
            zipcode,
            street
        });

        let orderFirewood = (order) => {
            const dataToSave = getDataToSave(order);

            return $http({
                method: 'POST',
                url: `${apiURI}orders/save`,
                data: dataToSave
            });
        };

        let getAddressImages = (address) => (address.hasOwnProperty('images')) ? address.images.filter(image => image.selected).map(image => image.id) : [];

        let getSelectedProducts = (products) => {
            return products
                .filter(product => product.quantity > 0)
                .map(({product_category_id: category_id, product_pack_id: pack_id, quantity}) => {
                    return {
                        category_id,
                        pack_id,
                        quantity
                    };
                });
        };

        let getVendor = (products) => products.filter(product => (product.quantity > 0 && product.vendor_id))[0].vendor_id;

        let getDisabledDays = ({ranges}) => ranges.excludedDeliveryDates.map(day => day.format('YYYY-MM-DD'));

        let getDataToSave = (order) => {
            let newOrder = Object.assign({}, {
                location: JSON.stringify({
                    street: order.address.street,
                    zipcode: order.address.zipcode,
                    city: order.address.city
                }),
                delivery_date_range: order.deliveryRange,
                first_name: order.salesperson.firstName,
                last_name: order.salesperson.lastName,
                phone: order.salesperson.phoneNumber,
                purchase_number: order.salesperson.purchaseNumber,
                email: order.salesperson.confirmEmail,
                comment: order.salesperson.comment,
                products: getSelectedProducts(order.products),
                vendor_id: getVendor(order.products),
                coupon_code: (order.salesperson.role !== ROLES.company) ? order.code.value : '',
                images: getAddressImages(order.address),
                delivery_time: order.deliveryTime,
                carry: order.services.carry ? "on" : 0,
                custom_range: {
                    start_date: order.customRange.startDate,
                    end_date: order.customRange.endDate,
                },
                prepaid_percentage: order.percentage,
                services: {
                    carry: order.services.carry,
                    deliver_no_at_home: order.services.deliverNoAtHome,
                    big_car: order.services.noBigCar
                },
                excluded_delivery_dates: getDisabledDays(order)
            });

            (order.salesperson.role !== ROLES.company) ? newOrder.transaction_token = (order.stripeToken ? order.stripeToken : null) : null;

            return newOrder;
        };

        let getProducts = ({city, street, zipcode}) => $http.get(`v1/vendors/find-closest`, {
            params: {
                city,
                street,
                zipcode
            }
        });

        let getOrderHistory = () => $http.get(`/user/order-history-api`).then(({data}) => data);

        let updateOrder = ({order_id, excluded_delivery_dates, highlightDays}) => {
            let dataToUpdate = {
                excluded_delivery_dates: excluded_delivery_dates.map(day => moment(day).format('YYYY-MM-DD')),
                custom_range: {
                    start_date: moment(_.first(highlightDays).date).format('YYYY-MM-DD'),
                    end_date: moment(_.last(highlightDays).date).format('YYYY-MM-DD')
                }
            };

            return $http.put(`/user/order/update/${order_id}`, dataToUpdate);
        };

        let getWidgetProducts = (category) => $http.get(`${apiURI}products/info/${category}`).then(({data}) => data);

        let alert = (message) => {
            const defaultErrorMessage = $translate.instant(`order.modals.search.message`);

            message = message || defaultErrorMessage;

            let alert = $mdDialog.alert()
                .clickOutsideToClose(true)
                .title($translate.instant('order.modals.search.heading'))
                .textContent($translate.instant(message))
                .ok($translate.instant('order.modals.search.close'));

            $mdDialog.show(alert).then(() => null, () => null);
        };

        const getAddressBasedOnLocation = ({latitude, longitude}) => {
            return $http.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
                    params: {
                        key: GOOGLE_MAP.key,
                        latlng: `${latitude},${longitude}`,
                        sensor: GOOGLE_MAP.true,
                        language: GOOGLE_MAP.language,
                        region: GOOGLE_MAP.region
                    }
                })
                .then(({data}) => data.results);
        };

        const getDeliveryCostPrices = () => $http.get(`/v1/delivery-cost`).then(({data}) => data);

        const getDeliveryCost = (price) => $http.get(`/v1/delivery-cost/${price}`).then(({data}) => data);

        const getCode = (code) => $http.get(`/v1/dugnad/search`, {params: {code}}).then(({data}) => data);

        const payForFireWood = (orderId, transaction_token, payment_intent = null) => {
            return $http.post(`/user/pay/${orderId}`, {
                transaction_token,
                payment_intent,
                custom_range: {
                    start_date: '',
                    end_date: '',
                }
            }).then(({data}) => data);
        };

        return {
            getProducts,
            getPreSelectedProduct,
            generateCode,
            sendCouponCode,
            applyCode,
            orderFirewood,
            getUser,
            getOrderHistory,
            updateOrder,
            getWidgetProducts,
            alert,
            getAddressBasedOnLocation,
            getDeliveryCostPrices,
            getDeliveryCost,
            getCode,
            payForFireWood,
            assignGroupCode
        };
    };

    angular.module('VedApp').factory('OrderService', OrderService);
    OrderService.$inject = ['$http', 'ROLES', 'moment', '$mdDialog', '$translate', 'GOOGLE_MAP'];
})();