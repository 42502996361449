(() => {
    angular.module('VedApp').component('vedUserMenu', {
        templateUrl: 'js/components/top-nav-menu/user-menu/user-menu.tpl.html',
        controller: VedUserMenuController
    });

    VedUserMenuController.$inject = ['$document', '$element', '$rootScope', '$scope', '$state', '$translate', '$window',
        'DugnadService', 'ProfileService', 'ToastService', 'UserService', '$timeout'];

    function VedUserMenuController($document, $element, $rootScope, $scope, $state, $translate, $window, DugnadService,
                                   ProfileService, ToastService, UserService, $timeout) {
        let ctrl = this;

        ctrl.user = {};
        ctrl.showDropdown = false;

        // Hook methods
        ctrl.$onInit = () => {
            if (authorized) {
                ProfileService.getUserData().then(user => {
                    ctrl.user = user
                }).catch();
            }
            $scope.$on('$stateChangeSuccess', () => ctrl.showDropdown = false);
            $document.on('click', closeMenu);
        };
        ctrl.$onDestroy = () => {
            $document.off('click', closeMenu);
        };

        // Public methods
        ctrl.logOut = () => {
            UserService.logout().then(() => {
                ToastService.show(true, $translate.instant('errors.success'));
                authorized = false;
                $rootScope.$broadcast('user-logged-out-login');
                $state.go('main');

                $timeout(() => {
                    $rootScope.$broadcast('clearCart');
                    sessionStorage.clear();
                    $window.location.reload();
                }, 1000);
            });
        };

        // Private methods
        let closeMenu = (event) => {
            if (event.target !== $element[0] && $element.find(event.target).length === 0) {
                ctrl.showDropdown = false;
                $scope.$apply();
            }
        };
    }
})();