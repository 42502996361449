(() => {
    angular.module('VedApp').component('vedStilling', {
        templateUrl: 'js/components/stilling/stilling.tpl.html',
        controller: VedStillingController
    });

    //VedStillingController.$inject = [];

    function VedStillingController() {
        let ctrl = this;

        // Hook methods
        ctrl.$onInit = () => {
        };
    }
})();