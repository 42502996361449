(() => {
   angular.module('VedApp').component('vedResetPassword', {
       templateUrl: 'js/components/reset-password/reset.tpl.html',
       controller: VedResetPasswordController
   });

   VedResetPasswordController.$inject = ['$rootScope', '$state', '$stateParams', '$translate', '$window',
       'ToastService', 'UserService'];

   function VedResetPasswordController($rootScope, $state, $stateParams, $translate, $window, ToastService,
                                       UserService) {
       let ctrl = this;

       ctrl.passwordData = {
           'password': '',
           'confirm_password': ''
       };

       ctrl.$onInit = () => {};

       //public methods
       ctrl.submitResetPassword = (resetForm) => {
           if (ctrl.passwordData.password !== ctrl.passwordData.confirm_password) {
               ToastService.show(false, $translate.instant('reset.errors.confirm-password'));
               return false;
           }

           if (resetForm.$valid) {
               ctrl.isResettingPassword = true;
               UserService.resetPassword(ctrl.passwordData)
                   .then(() => {
                       ToastService.show(true, $translate.instant('reset.change-success'));
                       $window.location.pathname = '/dashboard';
                    })
                   .catch(({data}) => {})
                   .finally(() => ctrl.isResettingPassword = false)
           }
       }
   }
})();