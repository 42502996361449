(() => {
    angular.module('VedApp').component('vedPasswordRecovery', {
        templateUrl: 'js/components/password-recovery/password-recovery.tpl.html',
        controller: VedPasswordRecoveryController
    });

    VedPasswordRecoveryController.$inject = ['$mdDialog', '$state', '$translate', 'UserService', 'ToastService', '$timeout'];

    function VedPasswordRecoveryController($mdDialog, $state, $translate, UserService, ToastService, $timeout) {
        let ctrl = this;

        ctrl.email = '';
        ctrl.passRecoveryError = '';

        // Hook methods
        ctrl.$onInit = () => {
        };

        // Public methods
        ctrl.recoverPass = () => {
            ToastService.toggleNotifications();
            UserService.recoverPassword(ctrl.email).then((data) => {
                $timeout(() => {
                    ToastService.toggleNotifications();
                }, 1000);

                let alert = $mdDialog.alert()
                    .clickOutsideToClose(true)
                    .title($translate.instant('password-recovery.modal.title'))
                    .textContent($translate.instant('password-recovery.modal.body'))
                    .ok($translate.instant('general.ok'));

                $mdDialog.show(alert).then(() => {$state.go('login')}, () => null);
            }).catch(({data}) => {

                ctrl.passRecoveryError = $translate.instant('password-recovery.' + data.message) == 'password-recovery.' + data.message ?
                    $translate.instant('errors.form.general') : $translate.instant('password-recovery.' + data.message);

                    $timeout(() => {
                        ToastService.toggleNotifications();
                        ToastService.show(false, ctrl.passRecoveryError);
                    }, 1000);
            });
        };
    }
})();