((angular) => {
    angular.module('VedApp').component('vedWidgetProduct', {
        templateUrl: 'js/components/order-widget/widget-product/widget-product.tpl.html',
        controller: VedOrderWidgetProductController,
        bindings: {
            product: '<'
        }
    });

    VedOrderWidgetProductController.$inject = ['REGEXP', '$mdDialog', '$translate', 'ToastService'];

    function VedOrderWidgetProductController(REGEXP, $mdDialog, $translate, ToastService) {
        let ctrl = this;
        ctrl.REGEXP = REGEXP;

        ctrl.$onInit = () => {
            ctrl.product.quantity = (ctrl.product.capacity === 40 || ctrl.product.capacity === 60) ? 10 : 1;
            ctrl.product.ui = {
                measurement_units: ctrl.product.capacity < 1000 ? ctrl.product.capacity + 'l' :
                    $translate.instant('home.widget.measurement-units', {
                        capacity: ctrl.product.capacity,
                        type: $translate.instant('home.widget.' + ctrl.product.type) || ''
                    })

            };
        };

        ctrl.order = () => {
            if (sessionStorage.getItem('productListZip')) {
                localStorage.setItem('widgetProduct', JSON.stringify(ctrl.product));
                location.href = '/order';
            } else {
                if (ctrl.product.capacity === 1000 || ctrl.product.capacity === 1500) {
                    ToastService.show(false, $translate.instant('new-order.errors.widget-order'));
                } else {
                    localStorage.setItem('widgetProduct', JSON.stringify(ctrl.product));
                    location.href = '/order';
                }
            }
        };
    }
})(angular);