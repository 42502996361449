((angular, _) => {
    angular.module('VedApp').directive('formatDate', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModelController) {
                ngModelController.$parsers.push(function (data) {
                    //convert data from view format to model format
                    return data; //converted
                });

                ngModelController.$formatters.push(function (data) {
                    //convert data from model format to view format
                    if (_.first(data)) {
                        return _.first(data).format('DD.MM.YYYY'); //converted
                    } else {
                        return data;
                    }
                });
            }
        };
    });
})(window.angular, _);