(() => {
    function VedUploadDugnadMembersController(FileUploader) {
        let ctrl = this;

        ctrl.activeTab = 'create-manually';
        ctrl.uploader = new FileUploader({
            url: `${apiURI}dugnad`,
            alias: 'template'
        });

        // Hook methods
        ctrl.$onInit = () => {
            ctrl.templatePath = `${apiURI}dugnad/template.xlsx`;
        };

        // Public methods
        ctrl.addNewMember = () => {
            ctrl.membersList.push({
                first_name: '',
                last_name: '',
                email: ''
            });
            if (angular.isFunction(ctrl.onChangeCallback)) {
                ctrl.onChanges();
            }
        };
        ctrl.deleteGroupMember = (itemIndex, item) => {
            ctrl.membersList.splice(itemIndex, 1);
            ctrl.onChanges();
        };
        ctrl.getSelectedFile = () => {
            return ctrl.uploader.queue.length > 0 ? ctrl.uploader.queue[ctrl.uploader.queue.length - 1].file : null;
        };
        ctrl.updateMember = (itemIndex, item) => {
            ctrl.membersList[itemIndex] = item;
            ctrl.onChanges();
        };
        ctrl.setLastActiveTab = (activeTabName) => {
            ctrl.activeTab = activeTabName;
            if (ctrl.activeTab != 'create-manually') {
                ctrl.tempMembersList = angular.copy(ctrl.membersList);
                ctrl.membersList = [];
            } else {
                ctrl.membersList = angular.copy(ctrl.tempMembersList);
            }
            ctrl.onChanges();
        };

        // Private methods
        ctrl.uploader.onAfterAddingAll = () => {
            ctrl.onChanges();
        };
        ctrl.onChanges = () => {
            ctrl.onChangeCallback({
                membersList: ctrl.membersList,
                templateUploader: ctrl.uploader,
                activeTab: ctrl.activeTab
            });
        };
    }

    VedUploadDugnadMembersController.$inject = ['FileUploader'];

    angular.module('VedApp').component('vedUploadDugnadMembers', {
        templateUrl: 'js/components/dugnad/upload-dugnad-members/upload-dugnad-members.tpl.html',
        controller: VedUploadDugnadMembersController,
        bindings: {
            membersList: '<',
            activeTab: '<',
            formObject: '<',
            onChangeCallback: '&'
        }
    });
})();