(() => {
    angular.module('VedApp').factory('ToastService', ToastService);
    ToastService.$inject = ['$mdToast', 'TOAST_HIDE_DELAY', '$translate'];

    function ToastService($mdToast, TOAST_HIDE_DELAY, $translate) {
        let hideNotifications = false;

        let toggleNotifications = () => {
            hideNotifications = !hideNotifications;
        };

        let classFromType = (type) => {
            let typesToClasses = {info: 'toast-success', error: 'toast-error'};
            return (type in typesToClasses) ? typesToClasses[type] : typesToClasses.info;
        };

        let showMessage = ({text, type}, messagesLeft) => {
            if (!text) return;
            messagesLeft = messagesLeft || 0;
            messagesLeft = messagesLeft > 0 ? ` [+${messagesLeft}]` : '';
            let toast = $mdToast.simple({hideDelay: TOAST_HIDE_DELAY, parent: document.getElementById('toast-container')})
                .toastClass(classFromType(type))
                .position('top right')
                .textContent($translate.instant(text) + messagesLeft);
            !hideNotifications && $mdToast.show(toast);
        };

        /*
         * The problem with toast is that it can display
         * one message at a time.
         *
         * This creates a function that accepts any (reasonable!)
         * count of messages, saves them in pool and than
         * displays one by one.
         *
         * Solution is not so straitforward, so if anyone will come
         * out with better (smaller and simpler) solution do not
         * hesitate - do it :)
         */
        let consecutiveShow = (() => {
            let messages = [];
            let inProgress = false;

            let showNext = () => {
                if (messages.length == 0) {
                    inProgress = false;
                    return;
                }
                inProgress = true;
                let nextMessage = messages.shift();
                showMessage(nextMessage, messages.length > 0 ? messages.length - 1 : 0);
                setTimeout(() => showNext(), TOAST_HIDE_DELAY + 100);
            };

            let addInterval;

            return (message) => {
                messages.push(message);

                // postpone displaying to show correct count of left messages
                clearInterval(addInterval);
                addInterval = setInterval(() => {
                    if (!inProgress) {
                        showNext();
                    }
                }, 100);
            };
        })();

        let newShow = (message) => {
            if (typeof message.text === 'object') {
                message.text = Object.keys(message.text).map((error) => message.text[error]);
            }
            consecutiveShow(message);
        };

        let show = (success, message) => {
            let msg = ((typeof message === 'object')) ? (Object.keys(message).map((error) => message[error])).join('. ') : message;
            let toast = $mdToast.simple({
                hideDelay: TOAST_HIDE_DELAY,
                parent: document.getElementById('toast-container')
            }).toastClass(success ? 'toast-success' : 'toast-error')
                .position('top right')
                .textContent($translate.instant(msg));
            !hideNotifications && $mdToast.show(toast);
        };

        let showMany = (messages) => {
            messages.forEach((message) => newShow(message));
        };

        return {
            newShow,
            show,
            showMany,
            toggleNotifications
        };
    }
})();
