(() => {
    angular.module('VedApp').component('vedDugnadProfile', {
        templateUrl: 'js/components/dugnad/dugnad-profile/dugnad-profile.tpl.html',
        controller: VedDugnadProfileController
    });

    VedDugnadProfileController.$inject = ['$scope', '$state', '$translate', '$window', 'FileUploader', 'REGEXP', 'DugnadService'];

    function VedDugnadProfileController($scope, $state, $translate, $window, FileUploader, REGEXP, DugnadService) {
        let ctrl = this;
        ctrl.dugnad = {};
        ctrl.REGEXP = REGEXP;
        ctrl.activeTab = 'orders-list';
        ctrl.orderHisrotyHeaders = [
            {
                name: 'id',
                label: 'profile.dugnad-profile.order-id',
                class: 's2'
            },
            {
                name: 'created_at',
                label: 'profile.dugnad-profile.order-date',
                date: true,
                class: 's3'
            },
            {
                name: 'total',
                label: 'profile.dugnad-profile.order-sum',
                class: 's3'
            },
            {
                name: 'earned',
                label: 'profile.dugnad-profile.payment-percentage',
                class: 's3'
            }
        ];
        ctrl.paymentsHeaders = [
            {
                name: 'amount',
                label: 'profile.dugnad-profile.payment-sum',
                class: 's5'
            },
            {
                name: 'updated_at',
                label: 'profile.dugnad-profile.payment-date',
                date: true,
                class: 's6'
            }
        ];

        ctrl.setMenuActive = () => {
        };

        // Hook methods
        ctrl.$onInit = () => {
            ctrl.setMenuActive();

            // Mocked dugnad data
            ctrl.dugnad = {};

            DugnadService.getUserDugnudGroup().then(({data: {group}}) => {
                ctrl.dugnad = group;

                // calculate earnings from orders
                ctrl.dugnad.orders.forEach((order) => {
                    order.earned = order.refund ? (order.total - order.refund.amount) * 0.05 : order.total * 0.05;
                    order.earned = order.earned.toFixed(2);
                });
            });
        };

        // Public methods
        ctrl.onDugnadChanged = (name, value, form) => {
            ctrl.dugnad[name] = value;

            DugnadService.updateDugnadGroup(ctrl.dugnad).then(() => {
            });
        };
        ctrl.addNewMember = () => {
            ctrl.dugnad.members.push({
                first_name: '',
                last_name: '',
                email: ''
            });
        };
        ctrl.deleteGroupMember = (itemIndex, item) => {
            ctrl.dugnad.members.splice(itemIndex, 1);
            item.id? DugnadService.deleteGroupMember(item.id).then(() => {}) : null;
        };
        ctrl.updateMember = (itemIndex, item) => {
            ctrl.membersListIsLoading = true;
            ctrl.dugnad.members[itemIndex] = item;

            DugnadService.updateDugnadGroup(ctrl.dugnad).then(({data}) => {
                ctrl.dugnad = data;
                ctrl.membersListIsLoading = false;
            });
        };

        ctrl.navigateToUserProfile = () => {
            $state.go('user-profile');
        };
    }
})();