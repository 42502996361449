(() => {
    angular.module('VedApp').component('vedMessagesWidget', {
        templateUrl: 'js/components/messages-widget/messages-widget.tpl.html',
        controller: VedWidgetController
    });

    VedWidgetController.$inject = ['$document', '$scope', '$state', '$rootScope', '$element', 'REGEXP', '$window', '$translate', '$timeout', 'CommunicationService', 'ProfileService', 'moment'];

    function VedWidgetController($document, $scope, $state, $rootScope, $element, REGEXP, $window, $translate, $timeout, CommunicationService, ProfileService, moment) {
        let ctrl = this;

        ctrl.messagesWidgetOpened = false;
        ctrl.messagesItemsCount = 0;
        ctrl.user = null;
        ctrl.moment = moment;
        ctrl.authorized = authorized;
        ctrl.userChat = null;
        ctrl.newNotifications = false;
        ctrl.broadcastingBranch = broadcastingBranch;
        ctrl.chatsList = null;
        ctrl.selectedChat = null;
        ctrl.REGEXP = REGEXP;
        ctrl.mobile = $window.matchMedia("only screen and (max-width: 426px)").matches;
        ctrl.messagesEnabled = true;
        ctrl.messagesItems = [];
        ctrl.window = angular.element($window);

        //Hook methods
        ctrl.$onInit = () => {
            ProfileService.getUserData().then((data) => {
                ctrl.user = data;
            });

            if (Echo && authUserId) {
                Echo.private(ctrl.broadcastingBranch + '.user.' + authUserId).listen('.chat.message.created', (data) => {
                    ctrl.messagesWidgetOpened = false;
                    ctrl.messagesItems = [];

                    CommunicationService.getNotifications().then((data) => {
        
                        ctrl.chatsList = data.data;
        
                        ctrl.chatsList.forEach((chat) => {
                            let itemToPush = chat.messages[0];

                            if (itemToPush) {
                                let momentData = moment(itemToPush.created_at);
                                itemToPush.created_at = momentData._d;
                                itemToPush.chatViewed = chat.viewed;

                                ctrl.messagesItems.push(itemToPush);
                            }

                            (!chat.viewed && chat.messages.length > 0) ? (ctrl.newNotifications = true) : null;
                        });
        
                        ctrl.messagesItemsCount = ctrl.messagesItems.length;
                    });

                    $scope.$apply();
                });
            }


            CommunicationService.getNotifications().then((data) => {

                ctrl.chatsList = data.data;

                ctrl.chatsList.forEach((chat) => {
                    let itemToPush = chat.messages[0];
                    if (itemToPush) {
                        let momentData = moment(itemToPush.created_at);
                        itemToPush.created_at = momentData._d;
                        itemToPush.chatViewed = chat.viewed;

                        ctrl.messagesItems.push(itemToPush);
                    }

                    (!chat.viewed && chat.messages.length > 0) ? (ctrl.newNotifications = true) : null;

                });

                ctrl.messagesItemsCount = ctrl.messagesItems.length;
            });

            $document.on('click', closeMessagesWidget);
        };

        ctrl.closeMessages = () => {
            ctrl.messagesWidgetOpened = false;
        }

        ctrl.viewConversation = (message) => {
            CommunicationService.getNotifications().then((data) => {
                let updatedMessages = data.data;

                updatedMessages.forEach((chat) => {
                    chat.messages.forEach((chatMessage) => {
                        if (chatMessage.chat_id === message.chat_id && chatMessage.id === message.id) {
                            ctrl.selectedChat = chat;

                            if (ctrl.selectedChat.type === 'GENERAL') {
                                ctrl.toggleMessagesWidget();
                                CommunicationService.setViewed(ctrl.selectedChat.id).then(() => {
                                    ctrl.messagesItems.forEach((messageItem) => {
                                        if (messageItem.message === message.message) {
                                            messageItem.chatViewed = true;
                                        }
                                    });

                                    ctrl.newNotifications = ctrl.messagesItems.some((testItem) => {
                                        return testItem.chatViewed === false;
                                    });

                                    $state.go('customer-question', { id: ctrl.selectedChat.id, type: 'GENERAL' });
                                })
                                
                            } else if (ctrl.selectedChat.type === 'ORDER') {
                                if (ctrl.user.role === 'VENDOR') {
                                    window.location.href = `/dashboard#!/order/${ctrl.selectedChat.order_id}`;
                                    return false;
                                }

                                ctrl.toggleMessagesWidget();
                                CommunicationService.setViewed(ctrl.selectedChat.id).then(() => {
                                    ctrl.messagesItems.forEach((messageItem) => {
                                        if (messageItem.message === message.message) {
                                            messageItem.chatViewed = true;
                                        }
                                    });

                                    ctrl.newNotifications = ctrl.messagesItems.some((testItem) => {
                                        return testItem.chatViewed === false;
                                    });

                                    $state.go('customer-question', { id: ctrl.selectedChat.order_id, type: 'ORDER' });
                                })
                                
                            } else {
                                return false;
                            }

                        }
                    });
                });

            });

        }

        ctrl.seeAllMessages = () => {
            $state.go('messages');
        }

        ctrl.$onDestroy = () => {
            $document.off('click', closeMessagesWidget);
        }

        ctrl.toggleMessagesWidget = () => {
            ctrl.messagesWidgetOpened = !ctrl.messagesWidgetOpened;
        }

        const closeMessagesWidget = (event) => {
            if (event.target !== $element[0] && $element.find(event.target).length === 0) {
                ctrl.messagesWidgetOpened = false;
                $scope.$apply();
            }
        }
    }
})();