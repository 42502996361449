(() => {
    angular.module('VedApp').component('vedAbout', {
        templateUrl: 'js/components/about/about.tpl.html',
        controller: VedAboutController
    });

    //VedAboutController.$inject = [];

    function VedAboutController() {
        let ctrl = this;

        ctrl.lang = lang;
        // Hook methods
        ctrl.$onInit = () => {
        };
    }
})();