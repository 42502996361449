((angular, _) => {
    angular.module('VedApp')
        .filter('deliveryDate', ['$translate', ($translate) => (date) => {
            let deliveryDate = new Date();

            let parseDate = (symbol) => {
                const lastDay = _.last(date.split(symbol));

                if (isNaN(+(lastDay))) {
                    return `${date.split(symbol)[1]} ${$translate.instant('order.days')}`
                } else {
                    return `${lastDay} ${$translate.instant('order.days')}`
                }
            };

            if (date === 'TODAY' || date === '1_day') {
                deliveryDate = $translate.instant(`1 ${$translate.instant('order.day')}`);
            } else if (date === 'EXTRA_LONG' || date === 'EXTRA LONG') {
                deliveryDate = $translate.instant(`${$translate.instant('order.EXTRA_LONG')}`);
            } else {
                if ((date.indexOf('_') !== -1)) {
                    deliveryDate = parseDate('_');
                } else {
                    deliveryDate = parseDate('-');
                }
            }

            return deliveryDate;
        }]);
})(angular, _);