(() => {
    angular.module('VedApp').component('vedRegisterDugnadGroup', {
        templateUrl: 'js/components/dugnad/register-dugnad-group/register-dugnad-group.tpl.html',
        controller: VedRegisterDugnadGroupController
    });

    VedRegisterDugnadGroupController.$inject = ['$scope', '$translate', '$window', 'FileUploader', 'REGEXP', 'DugnadService', 'ProfileService', 'ToastService'];

    function VedRegisterDugnadGroupController($scope, $translate, $window, FileUploader, REGEXP, DugnadService, ProfileService, ToastService) {
        let ctrl = this;

        ctrl.REGEXP = REGEXP;
        ctrl.activeTab = 'create-manually';
        ctrl.newDugnadGroup = {
            membersList: []
        };
        ctrl.newDugnadCreated = false;
        ctrl.dugnadCreatingError = '';
        ctrl.templateUploader = null;
        ctrl.user = {};
        ctrl.isBuyer = true;
        ctrl.hasDugnad = false;

        // Hook methods
        ctrl.$onInit = () => {
            if (authorized) {
                ProfileService.getUserData().then(user => {
                    ctrl.user = user;

                    ctrl.isBuyer = ctrl.user.role == 'BUYER';
                    ctrl.hasDugnad = !!ctrl.user.dugnad_group;

                    ctrl.newDugnadGroup.first_name = ctrl.user.first_name;
                    ctrl.newDugnadGroup.last_name = ctrl.user.last_name;
                    ctrl.newDugnadGroup.street = ctrl.user.address[0].street;
                    ctrl.newDugnadGroup.zipcode = ctrl.user.address[0].zipcode;
                    ctrl.newDugnadGroup.city = ctrl.user.address[0].city;
                    if (ctrl.isBuyer && !ctrl.hasDugnad) {
                        ctrl.newDugnadGroup.phone_number = ctrl.user.phone_number;
                        ctrl.newDugnadGroup.email = ctrl.user.email;
                    }
                }).catch();
            }
        };

        // Public methods
        ctrl.onDugnadChanged = (name, value, form) => {
            ctrl.newDugnadGroup[name] = value;
        };
        ctrl.submitRegistration = (event, registerDugnadGroupForm) => {
            if (registerDugnadGroupForm.$valid) {
                let dataToSave = {
                    group_name: ctrl.newDugnadGroup.group_name,
                    first_name: ctrl.newDugnadGroup.first_name,
                    last_name: ctrl.newDugnadGroup.last_name,
                    phone_number: ctrl.newDugnadGroup.phone_number,
                    email: ctrl.newDugnadGroup.email,
                    street: ctrl.newDugnadGroup.street,
                    zipcode: ctrl.newDugnadGroup.zipcode,
                    city: ctrl.newDugnadGroup.city,
                    membersList: ctrl.newDugnadGroup.membersList,
                    unit_name: ctrl.newDugnadGroup.unit_name || '',
                    subgroup_name: ctrl.newDugnadGroup.subgroup_name || ''
                };

                if (ctrl.activeTab != 'create-manually' && ctrl.templateUploader && ctrl.templateUploader.queue.length > 0) {
                    let templateIndex = ctrl.templateUploader.queue.length - 1;
                    ctrl.newDugnadGroup.membersList = [];
                    ctrl.templateUploader.queue[templateIndex].formData = [];
                    for (let key in dataToSave) {
                        let field = {};
                        field[key] = dataToSave[key];
                        ctrl.templateUploader.queue[templateIndex].formData.push(field);
                    }
                    ctrl.templateUploader.onSuccessItem = (fileItem, response) => {
                        ctrl.newDugnadGroup = response.data;
                        ctrl.newDugnadCreated = true;
                    };
                    ctrl.templateUploader.onErrorItem = (fileItem, response) => {
                        ctrl.dugnadCreatingError = response.errors && response.errors.template ? $translate.instant('errors.form.template') :
                            $translate.instant('errors.form.general');
                    };

                    ctrl.templateUploader.queue[templateIndex].upload();
                } else {
                    ctrl.templateUploader = null;
                    DugnadService.createDugnadGroup(dataToSave).then(({data}) => {
                        ctrl.newDugnadGroup = data.data;
                        ctrl.newDugnadCreated = true;
                        ToastService.show(true, $translate.instant('errors.success'));
                    }).catch(({data}) => {
                        let errorMessage = $translate.instant('errors.form.' + data.message).toString() != data.message ?
                            $translate.instant('errors.form.' + data.message) : $translate.instant('errors.form.general');
                    });
                }
            } else {
                ctrl.dugnadCreatingError = $translate.instant('errors.form.general');
            }
        };
        ctrl.onCancel = () => {
            $window.history.back();
        };
        ctrl.onMembersListUpdated = (membersList, templateUploader, activeTab) => {
            ctrl.templateUploader = templateUploader;
            ctrl.newDugnadGroup.membersList = membersList;
            ctrl.activeTab = activeTab;
        };
    }
})();