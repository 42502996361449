(() => {
    angular.module('VedApp').component('vedOpportunities', {
        templateUrl: 'js/components/main/opportunities/opportunities.tpl.html',
        controller: VedOpportunitiesController
    });

    function VedOpportunitiesController() {
        let ctrl = this;

        // Hook methods
        ctrl.$onInit = () => {
        };

        // Public methods
    }
})();