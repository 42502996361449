(() => {
    angular.module('VedApp').component('vedProductsList', {
        templateUrl: 'js/components/products-list/products-list.tpl.html',
        controller: vedProductsListController
    });

    vedProductsListController.$inject = ['OrderService', 'ProfileService', 'ZipLocationService', 'OrderCartService',
        '$scope', '$rootScope', '$mdDialog', '$translate', 'ToastService', 'REGEXP', '$window', '$timeout', '$state', 'WebSliderService'];

    function vedProductsListController(OrderService, ProfileService, ZipLocationService, OrderCartService, $scope,
                                       $rootScope, $mdDialog, $translate, ToastService, REGEXP, $window, $timeout, $state, WebSliderService) {
        let ctrl = this;

        ctrl.products = [];
        ctrl.mapTheme = null;
        ctrl.lang = lang;
        ctrl.REGEXP = REGEXP;
        ctrl.productListZip = null;
        ctrl.mobile = $window.matchMedia("only screen and (max-width: 768px)").matches;
        ctrl.zipCode = '';
        ctrl.categoryIdList = [];
        ctrl.packageIdList = [];
        ctrl.isMobile = $window.matchMedia("only screen and (max-width: 600px)").matches;
        ctrl.capacityList = [];
        ctrl.typeList = [];
        ctrl.categoryNamesList = [];
        ctrl.filtersCounter = null;
        ctrl.isZipPanelOpened = false;
        ctrl.isFiltersPanelOpened = false;
        ctrl.storageProduct = null;
        ctrl.window = angular.element($window);
        ctrl.viewImages = WebSliderService.viewImages;

        ctrl.window.bind('resize', () => {
        (window.innerWidth <= 768) ? (ctrl.mobile = true) : (ctrl.mobile = false); 
        });

        // Hook methods
        ctrl.$onInit = () => {
            ctrl.filtersCounter = sessionStorage.getItem('filtersCounter') ? (sessionStorage.getItem('filtersCounter')) : 0;
            ctrl.productListZip = sessionStorage.getItem('productListZip') ? (sessionStorage.getItem('productListZip')) : '' ;
            ctrl.isLoading = true;
            if (localStorage.getItem('widgetProduct')) {
                ctrl.storageProduct = JSON.parse(localStorage.getItem('widgetProduct'));
            }

            ctrl.updateProductList(sessionStorage.getItem('productListZip'));
            
            if (ctrl.storageProduct !== null && ctrl.storageProduct) {
                $timeout(() => ctrl.setWidgetProductToCart(ctrl.storageProduct), 1000);
            }

            ProfileService.getMapTheme().then(({data}) => ctrl.mapTheme = data);

            if(!ctrl.productListZip){
                ctrl.openZipCodeModal();
            }
        };

        ctrl.openZipCodeModal = () => {
            $mdDialog.show({
                parent: angular.element(document.body),
                templateUrl: 'js/components/main/order-tabs/zip-code-modal.tpl.html',
                clickOutsideToClose: true,
                fullscreen: false,
                controller: ['$scope', '$mdDialog', ($scope, $mdDialog)=> {
                    $scope.close = () => $mdDialog.cancel();
                }],
            });
        };

        ctrl.setWidgetProductToCart = (storageProduct) => {
            let productToCart = ctrl.products.filter((product) => {
                return (storageProduct.category_id === product.category.id)
                     && (storageProduct.pack_id === product.package.id);
            });


            productToCart[0].quantity = storageProduct.quantity;
            ctrl.addProductToCart(event, productToCart[0]);
            localStorage.removeItem('widgetProduct');
            $rootScope.$broadcast('openCartWidget');
        };

        ctrl.toggleZipPanel = () => {
            ctrl.isZipPanelOpened = !ctrl.isZipPanelOpened;
        };

        ctrl.toggleFiltersPanel = () => {
            ctrl.isFiltersPanelOpened = !ctrl.isFiltersPanelOpened;
        };

        ctrl.viewProductDetails = (product) => {
            $state.go('product-details', {
                id: product.id
            });
        };

        ctrl.addProductToCart = (e, product) => {
            if(!ctrl.productListZip || ctrl.productListZip === 'undefined'){
                // ToastService.show(false, $translate.instant('new-order.errors.required-postcode'));

                ctrl.openZipCodeModal();
                return;
            }

            if (typeof (product.quantity) !== 'undefined') {
                let items = sessionStorage.getItem('cartItems') ? JSON.parse(sessionStorage.getItem('cartItems')) : [];
                let isNewProduct = true;
                let vendorMatchFound = false;
    
                _.forEach(items, (item) => {
                    if (item.category.id === product.category.id && item.package.id === product.package.id) {
                        isNewProduct = false;
                        item.quantity = Number(item.quantity) + Number(product.quantity) || 1;
                    }
                });
    
                if (items.length === 0 && isNewProduct === true) {
                    if (isNewProduct) {
                        let minVendorDistance = _.min(product.vendors, (vendor) => {
                            return vendor.distance;
                        });

                        product.vendorForGetPricesId = minVendorDistance.vendor_id;
                        product.vendorForGetPricesAddressId = minVendorDistance.address_id;
                    }

                    isNewProduct ? items.push(product) : null;
                } else if (items.length > 0 && isNewProduct === true) {
                    //for each vendor in product
                    _.forEach(product.vendors, (productVendor) => {
                        //check every item in items

                        let productHasCommonVendor = items.every((item) => {
                            let commonVendorsWithProduct = item.vendors.find((vendor) => {
                               return (vendor.vendor_id === productVendor.vendor_id) && (vendor.address_id === productVendor.address_id); 
                            });

                            if (commonVendorsWithProduct) {
                                _.forEach(items, (item) => {
                                    item.vendorForGetPricesId = commonVendorsWithProduct.vendor_id;
                                    item.vendorForGetPricesAddressId = commonVendorsWithProduct.address_id;
                                    product.vendorForGetPricesId = commonVendorsWithProduct.vendor_id;
                                    product.vendorForGetPricesAddressId = commonVendorsWithProduct.address_id;
                                })
                                return true;
                            }
                        });

                        if (productHasCommonVendor === true) {
                            vendorMatchFound = true;
                            product.hasCommonVendor = true;
                        }
                    });                    
    
                    let showAlert = () => {
                        if (vendorMatchFound === false) {
                            let alert = $mdDialog.alert()
                                .clickOutsideToClose(true)
                                .textContent($translate.instant('new-order.product-list.different-vendors'))
                                .ok($translate.instant('OK'));
    
                            $mdDialog.show(alert).then(() => null, () => null);
                        }
                    };

                    if (vendorMatchFound && product.hasCommonVendor) {
                        items.push(product);
                    } else {
                        showAlert();
                    }

                } else {
                    //TODO write validation logic
                }
    
                sessionStorage['cartItems'] = JSON.stringify(items);
                $rootScope.$broadcast('newItemAppearedInCart', product);
                $timeout(() => {
                    $rootScope.$broadcast('openCartWidget');
                }, 800);
            } else {
                ToastService.show(false, $translate.instant('new-order.product-list.qty-error'));
                return false;
            }
        };

        ctrl.checkProductQty = (product) => {
            console.log(product.package.capacity, product.quantity, typeof(product.quantity));
            
            if (product) {
                product.quantity = (product.quantity < 0 || !product.quantity) ? null : product.quantity;
            }
        };

        // Public methods
        ctrl.updateProductList = (zipcode, categories, packages) => {
            ctrl.capacityList = [];
            ctrl.categoryNamesList = [];
            ctrl.typeList = [];
            OrderCartService.getProductsByFilters(zipcode, categories, packages).then(({data}) => {
                ctrl.products = data;
                _.forEach(ctrl.products, (product) => {
                    ctrl.categoryNamesList.push(product.category.name);
                    ctrl.capacityList.push(product.package.capacity);
                    ctrl.typeList.push(product.package.type);
                });

                let filtersObject = {
                    categoryNamesList: ctrl.categoryNamesList,
                    capacityList: ctrl.capacityList,
                    typeList: ctrl.typeList
                };

                $scope.$broadcast('filterAvailableItems', filtersObject);

                ctrl.isLoading = false;
                setDeliveryCosts();
            });
        };

        $scope.$on('countChanged', () => {
            ctrl.filtersCounter = sessionStorage.getItem('filtersCounter') ? (sessionStorage.getItem('filtersCounter')) : 0;
        });

        ctrl.showOnlyNecessaryProducts = (categories, packages) => {
            if (categories.length > 0 && packages.length === 0) {
                _.forEach(ctrl.products, (product) => {
                    (categories.includes(product.category.id)) ? product.hidden = false : product.hidden = true;
                });
            } else if (categories.length > 0 && packages.length > 0) {
                _.forEach(ctrl.products, (product) => {
                    (categories.includes(product.category.id) && packages.includes(product.package.id)) ? 
                        product.hidden = false : product.hidden = true;
                });
            } else if (categories.length === 0 && packages.length > 0) {
                _.forEach(ctrl.products, (product) => {
                    (packages.includes(product.package.id)) ? product.hidden = false : product.hidden = true;
                });
            } else {
                _.forEach(ctrl.products, (product) => {
                    product.hidden = false;
                });
            }
        };

        // Watchers
        $scope.$on('reloadProducts', (event, data) => {
            ctrl.categoryIdList = data[0];
            ctrl.packageIdList = data[1];

            ctrl.showOnlyNecessaryProducts(ctrl.categoryIdList, ctrl.packageIdList);
            // ctrl.updateProductList(ctrl.zipCode, ctrl.categoryIdList, ctrl.packageIdList);
        });

        $scope.$on('resetFilters', () => {
            ctrl.updateProductList(ctrl.zipCode, null, null);
        });

        $scope.$on('newZipcode', (event, data) => {
            ctrl.productListZip = sessionStorage.getItem('productListZip');
            if (data !== null) {
                if (!data.zipcode && data) {
                    ctrl.zipCode = data;
                    ctrl.updateProductList(data, ctrl.categoryIdList, ctrl.packageIdList);
                } else {
                    ctrl.zipCode = data.zipcode;
                    if (data.zipcode === '' || data.zipcode.length < 4) return false;
                    ctrl.updateProductList(data.zipcode, ctrl.categoryIdList, ctrl.packageIdList);
                }
            } else {
                ctrl.zipCode = null;
                ctrl.updateProductList(null, ctrl.categoryIdList, ctrl.packageIdList);
            }
        });

        let setDeliveryCosts = () => {
            OrderService.getDeliveryCostPrices().then((data) => {
                let deliveryCostTable = data;
                ctrl.products.map((product) => {
                    product['delivery_cost'] = deliveryCostTable[product.package.id];
                });
            });
        };
    }
})();
