(() => {
    angular.module('VedApp').component('vedQuestionForm', {
        templateUrl: 'js/components/question-form/question-form.tpl.html',
        controller: VedQuestionFormController
    });

    VedQuestionFormController.$inject = ['CommunicationService', 'ProfileService', 'ToastService', '$translate', 'FileUploader', '$state', '$scope', '$timeout', 'REGEXP'];

    function VedQuestionFormController(CommunicationService, ProfileService, ToastService, $translate, FileUploader, $state, $scope, $timeout, REGEXP) {
        let ctrl = this;

        ctrl.authorized = authorized;
        ctrl.lang = lang;
        ctrl.REGEXP = REGEXP;
        ctrl.isLoading = false;
        ctrl.imageIsTooBig = false;
        ctrl.images = [];
        ctrl.questionForm = {
            subject: '',
            message: '',
            files: [],
            name: '',
            email: '',
            zipcode: ''
        }

        ctrl.uploader = new FileUploader({
            url: '/order/image'
        });

        // Hook methods
        ctrl.$onInit = () => {
            if (ctrl.authorized) {
                ProfileService.getUserData().then((data) => {
                    ctrl.questionForm.name = data.first_name;
                    ctrl.questionForm.email = data.email;
                    ctrl.questionForm.zipcode = data.address[0].zipcode;

                    let inputName = document.getElementById('question-name');
                    let inputEmail = document.getElementById('question-email');

                    inputName.readOnly = true;
                    inputEmail.readOnly = true;
                })
            } else {
                $timeout(() => {
                    let storageZip = sessionStorage.getItem('productListZip');
                    ctrl.questionForm.zipcode = storageZip;
                }, 1000);
            }
  
        };

        ctrl.uploader.onErrorItem = () => {
            ToastService.show(false, $translate.instant('errors.general'));
        };

        ctrl.uploader.filters.push({
            name: 'imageFilter',
            fn(item) {
                let type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                return '|jpg|png|jpeg|'.indexOf(type) !== -1;
            }
        });

        ctrl.uploader.onSuccessItem = (fileItem, response) => {
            (!ctrl.questionForm.hasOwnProperty('files')) ? ctrl.questionForm.files = [] : null;
            ctrl.questionForm.files.unshift(Object.assign({}, response, {
                selected: true
            }));
            ctrl.images.push(response);
        };

        ctrl.uploader.onAfterAddingAll = () => {
            ctrl.imageIsTooBig = false;
            let validImages = _.filter(ctrl.uploader.queue, (queueFile) => {
                let fileSize = queueFile._file.size;
                ctrl.imageIsTooBig = ctrl.imageIsTooBig || fileSize > 3000000;
                return fileSize <= 3000000;
            });
            ctrl.uploader.queue = validImages;

            ctrl.uploader.uploadAll();
        };

        ctrl.toggleImageSelection = (image) => image.selected = !image.selected;

        ctrl.submitQuestionForm = (form) => {
            let createChatObject = {};
            ctrl.isLoading = true;

            if (ctrl.images.length > 0) {
                createChatObject = {
                    type: 'GENERAL',
                    title: form.subject,
                    messages: [
                        {
                            type: 'TEXT',
                            message: ctrl.questionForm.message
                        }
                    ],
                    data: {
                        name: ctrl.questionForm.name,
                        email: ctrl.questionForm.email,
                        zipcode: ctrl.questionForm.zipcode
                    }
                };

                ctrl.images.forEach((image) => {
                    let newImage = {
                        type: 'IMAGE',
                        data: {
                            image: {
                                id: image.id
                            }
                        }
                    };

                    createChatObject.messages.push(newImage);
                });
            } else {
                createChatObject = {
                    type: 'GENERAL',
                    title: form.subject,
                    messages: [
                        {
                            type: 'TEXT',
                            message: ctrl.questionForm.message
                        }
                    ],
                    data: {
                        name: ctrl.questionForm.name,
                        email: ctrl.questionForm.email,
                        zipcode: ctrl.questionForm.zipcode
                    }
                };
            }
            
            CommunicationService.createChat(createChatObject).then((data) => {
                ToastService.show(true, $translate.instant('Success'));
                ctrl.isLoading = false;
                $state.go('question-success');
            }).catch((data) => {
                ctrl.isLoading = false;
            });
        };
    }
})();