(() => {
    angular.module('VedApp').component('vedCompanyReg', {
        templateUrl: 'js/components/user-registration/company-reg/company-reg.tpl.html',
        controller: VedCompanyRegController,
        bindings: {
            onRegisteredCallback: '&'
        }
    });

    VedCompanyRegController.$inject = ['REGEXP', 'UserService'];

    function VedCompanyRegController(REGEXP, UserService) {
        let ctrl = this;

        ctrl.company = {
            newsletter: true,
            departments: [{}]
        };
        ctrl.REGEXP = REGEXP;

        // Hook methods
        ctrl.$onInit = () => {};

        // Public methods
        ctrl.createCompany = (formObject) => {
            if (formObject.$valid && ctrl.company.password === ctrl.company.confirm_password) {
                UserService.companyRegistration(ctrl.company).then(ctrl.onRegisteredCallback);
            }
        };

        ctrl.addNewDepartment = () => {
            ctrl.company.departments.push({});
        };

        ctrl.deleteDepartment = (departIndex) => {
            ctrl.company.departments.splice(departIndex, 1);
        };
    }
})();