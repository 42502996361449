(() => {
    angular.module('VedApp').component('vedContactMe', {
        templateUrl: 'js/components/dugnad/contact-me/contact-me.tpl.html',
        controller: VedContactMeController
    });

    VedContactMeController.$inject = ['$scope', '$translate', 'REGEXP', 'DugnadService', 'ToastService'];

    function VedContactMeController($scope, $translate, REGEXP, DugnadService, ToastService) {
        let ctrl = this;

        ctrl.REGEXP = REGEXP;

        ctrl.contactMeRequest = {
            first_name: '',
            last_name: '',
            phone_number: '',
            email: '',
            city: '',
            street: '',
            zipcode: ''
        };
        ctrl.successfullySubmited = false;

        // Hook methods
        ctrl.$onInit = () => {
        };

        // Public methods
        ctrl.submitContactMeRequest = (formObject) => {
            if (formObject.$valid) {
                DugnadService.sendContactMeRequest(ctrl.contactMeRequest)
                    .then(() => {
                        ctrl.successfullySubmited = true;
                    })
                    .catch(({data}) => {
                        let errorMessage = $translate.instant('errors.form.' + data.message).toString() != data.message ?
                            $translate.instant('errors.form.' + data.message) : $translate.instant('errors.form.general');
                        ToastService.show(false, errorMessage);
                    });
            }
        };
        ctrl.onFieldChanged = (name, value, form) => {
            ctrl.contactMeRequest[name] = value;
        };
    }
})();