(() => {
    angular.module('VedApp').component('vedProductsFilterPane', {
        templateUrl: 'js/components/products-list/products-filter-pane/products-filter-pane.tpl.html',
        controller: vedProductsFilterPaneController,
        bindings: {
            onFiltersChanged: '&?',
            closeFiltersPanel: '&'
        }
    });

    vedProductsFilterPaneController.$inject = ['OrderCartService', '$q', '$scope', '$window', '$timeout', '$translate'];

    function vedProductsFilterPaneController(OrderCartService, $q, $scope, $window, $timeout, $translate) {
        let ctrl = this;

        ctrl.filters = [];
        ctrl.categoryIdList = [];
        ctrl.mobile = $window.matchMedia("only screen and (max-width: 768px)").matches;
        ctrl.packageIdList = [];
        ctrl.activeFiltersTrue = false;
        ctrl.categoryNamesList = [];
        ctrl.capacityList = [];
        ctrl.typeList = [];
        ctrl.window = angular.element($window);

        ctrl.filters = [
            {
                title: $translate.instant('new-order.firewood-type'),
                label: 'categories',
                expanded: false,
                values: []
            },
            {
                title: $translate.instant('new-order.firewood-sizes'),
                label: 'packages',
                expanded: false,
                values: []
            }
        ];

        ctrl.window.bind('resize', () => {
        (window.innerWidth <= 768) ? (ctrl.mobile = true) : (ctrl.mobile = false); 
        });

        $scope.$on('filterAvailableItems', (event, data) => {
            ctrl.categoryNamesList = data.categoryNamesList;
            ctrl.capacityList = data.capacityList;
            ctrl.typeList = data.typeList;

            $timeout(() => {
                ctrl.filters[0].values = Object.values(ctrl.filters[0].values);
                ctrl.filters[1].values = Object.values(ctrl.filters[1].values);
    
                ctrl.filters[0].values.forEach((value) => {
                    (ctrl.categoryNamesList.includes(value.name)) ? value.enabled = true : value.enabled = false;
                });
    
                ctrl.filters[1].values.forEach((value) => {
                    (ctrl.capacityList.includes(value.capacity)) ? value.enabled = true : value.enabled = false;
                })
    
            }, 1000);

        });

        ctrl.checkFirewoodAvailable = (value) => {
            let enableItem = ctrl.categoryNamesList.includes(value.name);
            return !enableItem;
        }

        ctrl.checkCapacityAvailable = (value) => {
            let enableItem = ctrl.capacityList.includes(value.capacity);
            return !enableItem;
        }



        // Hook methods
        ctrl.$onInit = () => {
            let categoryPromise = OrderCartService.getCategories();
            let packagePromise = OrderCartService.getPackages();

            $q.all([categoryPromise, packagePromise]).then((response) => {
                ctrl.filters.forEach((filter) => {
                    if (filter.label === 'categories') {
                        filter.values = Object.values(response[0].data);
                    } else if (filter.label === 'packages') {
                        filter.values = Object.values(response[1].data);
                    } else {
                        return false;
                    }
                });
            });
        };

        $scope.$on('clearFilters', () => {
            ctrl.resetFilters();
        });

        // Public methods
        ctrl.resetFilters = () => {
            ctrl.filters.forEach((filter) => {
                for (let key in filter.values) {
                    if (filter.values[key].hasOwnProperty('active')) {
                        filter.values[key].active = false;
                    }
                }
            });

            // reloadProductsList();
            ctrl.activeFiltersTrue = false;
            $scope.$emit('resetFilters');
        };

        ctrl.getActiveFiltersNumber = (filtersGroup) => {
            let activeFilters = _.filter(filtersGroup.values, 'active');
            return activeFilters.length;
        };

        ctrl.getActiveFilters = () => {
            // Create lists of ID arrays for categories and packages
            let categories = [];
            let packages = [];
            let filtersCounter = 0;

            ctrl.filters.forEach((item) => {
                    if (item.label === 'categories') {
                        let activeCategories = _.filter(item.values, 'active');

                        activeCategories.forEach((categoryItem) => {
                            categories.push(categoryItem.id);
                        });

                        filtersCounter = filtersCounter + categories.length;

                        ctrl.activeFiltersTrue = true;
                    } else if (item.label === 'packages') {
                        let activePackages = _.filter(item.values, 'active');

                        activePackages.forEach((packageItem) => {
                           packages.push(packageItem.id);
                        });

                        filtersCounter = filtersCounter + packages.length;

                        ctrl.activeFiltersTrue = true;
                    } else {
                        ctrl.activeFiltersTrue = false;
                        return false;
                    }
                    sessionStorage.setItem('filtersCounter', filtersCounter);
                    $scope.$emit('countChanged');

            });

            if (categories.length === 0 && packages.length === 0) {
                ctrl.activeFiltersTrue = false;
            }

            ctrl.categoryIdList = categories;
            ctrl.packageIdList = packages;

            reloadProductsList(ctrl.categoryIdList, ctrl.packageIdList);
        };

        let reloadProductsList = (categories = null, packages = null) => {
            let productData = [categories, packages];
            $scope.$emit('reloadProducts', productData);
        }
    }
})();
