((angular, _) => {
    VedGroupOrderController.$inject = ['$scope', '$translate', '$mdDialog', '$window', 'REGEXP', 'OrderService', 'ProfileService', 'OrderCartService', '$stateParams', 'ToastService'];

    angular.module('VedApp').component('vedGroupOrder', {
        templateUrl: 'js/components/group-order/group-order.tpl.html',
        controller: VedGroupOrderController
    });

    function VedGroupOrderController($scope, $translate, $mdDialog, $window, REGEXP, OrderService, ProfileService, OrderCartService, $stateParams, ToastService) {
        let ctrl = this;

        ctrl.REGEXP = REGEXP;
        ctrl.lang = lang;
        ctrl.neighborsList = [];
        ctrl.counter = 0;
        ctrl.disableGenerating = false;
        ctrl.enabledGroupMode = false;
        ctrl.campaignCode = '';

        // Hook methods
        ctrl.$onInit = () => {
            ctrl.enabledGroupMode = OrderCartService.checkGroupMode();
            ctrl.addNewNeighbor(true);
            if (authorized) {
                ProfileService.getUserData().then(({first_name, last_name, email, phone_number}) => {
                    ctrl.neighborsList[0].name = `${first_name} ${last_name}`;
                    ctrl.neighborsList[0].email = email;
                    ctrl.neighborsList[0].phone_number = phone_number;
                }).catch();
            }
            ctrl.addNewNeighbor(false);
        };

        // Public methods
        ctrl.addNewNeighbor = (isOwner) => {
            ctrl.neighborsList.push({
                fieldId: ++ctrl.counter,
                name: '',
                email: '',
                phone_number: '',
                promo_code: ctrl.campaignCode,
                promo_code_sent: false,
                owner: isOwner
            });

            // enabledSentToAll(false);
        };
        ctrl.generateCampaignCode = () => {
            ctrl.enabledGroupMode = OrderCartService.checkGroupMode();
                OrderService.generateCode().then(({data: {name}}) => {
                    if (ctrl.enabledGroupMode === true) {
                        OrderService.assignGroupCode(localStorage.getItem('orderId'), name).then((data) => {
                            ctrl.campaignCode = name;
                            sessionStorage.setItem('groupCode', ctrl.campaignCode);
                            resetSendingCampaignCode();
                            setCurrentCampaignCode();
                            ctrl.disableGenerating = true;
                        });
                    } else {
                        ctrl.campaignCode = name;
                        sessionStorage.setItem('groupCode', ctrl.campaignCode);
                        resetSendingCampaignCode();
                        setCurrentCampaignCode();
                    }

                    OrderCartService.enableGroupMode(false);
                });
        };
        ctrl.onFieldChanged = (name, value, form) => {
            let fieldName = name.slice(0, name.lastIndexOf('_'));
            let fieldId = name.slice(name.lastIndexOf('_') + 1);

            ctrl.neighborsList.forEach((neighbor) => {
                if (neighbor.fieldId == fieldId) {
                    neighbor[fieldName] = value;
                }
            });
        };
        ctrl.removeNeighbor = (neighborIndex) => {
            ctrl.neighborsList.splice(neighborIndex, 1);
        };
        ctrl.sendCampaignCode = (neighbors, formObject) => {
            let validData = true;
            neighbors.forEach((neighbor) => {
                let fieldsList = ['name_' + neighbor.fieldId, 'email_' + neighbor.fieldId, 'phone_number_' + neighbor.fieldId, 'campaign_code'];
                fieldsList.forEach((fieldName) => {
                    formObject[fieldName].$validate();
                    if (!formObject[fieldName].$valid) {
                        formObject[fieldName].$touched = true;
                        validData = false;
                    }
                });
            });

            if (validData) {
                OrderService.sendCouponCode(ctrl.campaignCode, neighbors).then(() => {
                    neighbors.forEach((neighbor) => {
                        neighbor.promo_code_sent = true;
                    });

                    if (!ctrl.enabledGroupMode) {
                        (neighbors.length > 1 || _.every(ctrl.neighborsList, 'promo_code_sent')) ? showConfirm() : null;
                    } else {
                        ToastService.show(true, $translate.instant('group-order.sent-to-all'));
                    }

                });
            }
        };
        ctrl.navigateToOrder = () => {
            sessionStorage.setItem('coupon_code', ctrl.campaignCode);
            $window.location.pathname = '/order';
        };

        // Private methods
        let resetSendingCampaignCode = () => {
            ctrl.neighborsList = ctrl.neighborsList.map(neighbor => {
                neighbor.promo_code_sent = false;
                return neighbor;
            });
        };
        let setCurrentCampaignCode = () => {
            ctrl.neighborsList = ctrl.neighborsList.map(neighbor => {
                neighbor.promo_code = ctrl.campaignCode;
                return neighbor;
            });
        };
        let showConfirm = () => {
            let confirm = $mdDialog.confirm()
                .title($translate.instant('group-order.code-sent-btn'))
                .textContent($translate.instant('group-order.what-you-want'))
                .clickOutsideToClose(true)
                .ok($translate.instant('group-order.order'))
                .cancel($translate.instant('group-order.close'));
            $mdDialog.show(confirm).then(() => {
                ctrl.navigateToOrder()
            }, () => null);
        };
    }
})(angular, _);