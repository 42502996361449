(() => {
    angular.module('VedApp').component('vedHelper', {
        templateUrl: 'js/components/helper/helper.tpl.html',
        controller: VedHelperController
    });

    VedHelperController.$inject = [];

    function VedHelperController() {
        let ctrl = this;

        ctrl.authorized = authorized;

        // Hook methods
        ctrl.$onInit = () => {
        };

        // window.Echo.channel('my-channel')
        //     .listen('.my-event', function(data) {
        //         console.log(data);
        //     });
    }
})();