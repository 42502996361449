(() => {
    function VedCommonTableController($translate) {
        let ctrl = this;

        // Hook methods
        ctrl.$onInit = () => {
        };
    }

    VedCommonTableController.$inject = ['$translate'];

    angular.module('VedApp').component('vedCommonTable', {
        templateUrl: 'js/components/common/common-table/common-table.tpl.html',
        controller: VedCommonTableController,
        bindings: {
            dataArray: '<',
            headersArray: '<'
        }
    });
})();