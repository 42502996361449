((angular) => {
    angular.module('VedApp').component('vedPersonalInformation', {
        templateUrl: 'js/components/order/personal-information/personal-information.tpl.html',
        controller: vedPersonalInformationController,
        bindings: {
            order: '<',
            onSave: '&',
            onImageUpload: '&'
        }
    });

    vedPersonalInformationController.$inject = ['$rootScope', 'OrderService', 'REGEXP', 'FileUploader', 'ROLES', '$translate', 'ToastService'];

    function vedPersonalInformationController($rootScope, OrderService, REGEXP, FileUploader, ROLES, $translate, ToastService) {
        let ctrl = this;

        ctrl.imageIsToBig = false;
        ctrl.REGEXP = REGEXP;
        ctrl.ROLES = ROLES;
        ctrl.uploader = new FileUploader({
            url: '/order/image',
        });

        ctrl.$onInit = () => {
            OrderService.getUser().then((data) => {
            });
        }

        ctrl.uploader.filters.push({
            name: 'imageFilter',
            fn(item) {
                let type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                return '|jpg|png|jpeg|'.indexOf(type) !== -1;
            }
        });

        ctrl.uploader.onSuccessItem = (fileItem, response) => {
            (!ctrl.order.address.hasOwnProperty('images')) ? ctrl.order.address.images = [] : null;
            ctrl.order.address.images.unshift(Object.assign({}, response, {
                selected: true
            }));

            ctrl.onImageUpload();
        };

        ctrl.uploader.onAfterAddingAll = () => {
            ctrl.imageIsToBig = false;
            let validImages = _.filter(ctrl.uploader.queue, (queueFile) => {
                let fileSize = queueFile._file.size;
                ctrl.imageIsToBig = ctrl.imageIsToBig || fileSize > 3000000;
                return fileSize <= 3000000;
            });
            ctrl.uploader.queue = validImages;

            ctrl.uploader.uploadAll();
        };

        ctrl.toggleImageSelection = (image) => image.selected = !image.selected;

        ctrl.uploader.onErrorItem = () => {
            ToastService.show(false, $translate.instant('errors.general'));
        };

        ctrl.savePersonalInfo = (form) => {
            if (form.$valid && ctrl.order.salesperson.role !== ctrl.ROLES.company) {
                ctrl.onSave({step: 4});
            } else if (form.$valid && ctrl.order.salesperson.role === ctrl.ROLES.company) {
                ctrl.order.orderState.status = 'pending';

                OrderService.orderFirewood(ctrl.order)
                    .then(({data: {order_id, status, confirmed_user, errors, authorized}}) => {
                        if (errors) {
                            OrderService.alert(errors);
                            ctrl.order.orderState = Object.assign({}, {
                                status: null
                            });
                        } else {
                            ctrl.order.orderState = Object.assign({}, {
                                order_id,
                                status,
                                confirmed_user
                            });
                            sessionStorage.removeItem('order');
                            sessionStorage.removeItem('deliveryRanges');
                            authorized && authorized !== 'false' ? $rootScope.$broadcast('user-logged-in') : null;
                        }
                    });
            }
        };
    };
})(angular);