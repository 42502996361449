(() => {
    angular.module('VedApp').component('vedCompanyDepartment', {
        templateUrl: 'js/components/user-registration/company-department/company-department.tpl.html',
        controller: VedCompanyDepartmentController,
        bindings: {
            department: '<',
            fieldId: '<',
            onDeleteCallback: '&'
        }
    });

    VedCompanyDepartmentController.$inject = ['REGEXP'];

    function VedCompanyDepartmentController(REGEXP) {
        let ctrl = this;
        ctrl.REGEXP = REGEXP;

        // Hook methods
        ctrl.$onInit = () => {};
    }
})();