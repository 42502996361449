(() => {
    let WebSliderService = ($mdDialog) => {
        let viewImagesController = (product, slideIndex) => ['$scope', 'SLIDER_CONFIG', ($scope, SLIDER_CONFIG) => {
            $scope.SLIDER_CONFIG = SLIDER_CONFIG;
            if (product.hasOwnProperty('id') && product.hasOwnProperty('large')) {
                $scope.images = [];
                $scope.images.push(product);
            } else {
                if (product.images.length >= 1 && product.images.length) {
                    $scope.images = product.images;
                } 
            }

            setTimeout(() => new IdealImageSlider.Slider($scope.SLIDER_CONFIG)[`start`]((slideIndex ? slideIndex : null)), 0);
        }];

        let viewImages = (product, index) => {
            let slideIndex = index + 1;

            $mdDialog.show({
                parent: angular.element(document.body),
                template: `<md-dialog class="slider-dialog" aria-label="Image slider">
                        <div id="product-images-slider" ng-class="{'single': images.length === 1}">
                            <img ng-repeat="image in images track by image.id" src="{{image.large}}" alt="{{image.title ? image.title: 'Firewood place'}}" />
                        </div>
                    </md-dialog>`,
                clickOutsideToClose: true,
                fullscreen: false,
                controller: viewImagesController(product, slideIndex)
            });
        };

        return {
            viewImages
        };
    };

    angular.module('VedApp').factory('WebSliderService', WebSliderService);
    WebSliderService.$inject = ['$mdDialog'];
})();