(() => {
    angular.module('VedApp').component('vedArticles', {
        templateUrl: 'js/components/main/articles/articles.tpl.html',
        controller: VedArticlesController
    });

    VedArticlesController.$inject = ['$window', 'ArticlesService'];

    function VedArticlesController($window, ArticlesService) {
        let ctrl = this;
        let articles = [];
        let pageSize = ($window.innerWidth <= 768) ? 2 : 4;
        ctrl.currentPage = 0;
        ctrl.pages = 0;

        // Hook methods
        ctrl.$onInit = () => {
            ArticlesService.getArticles().then(({data}) => {
                articles = data;
                ctrl.pages = Math.round(articles.length / pageSize);
            });
        };

        // Public methods
        ctrl.getArticlesView = () => {
            if (window.innerWidth <= 426) {
                return articles;
            }
            return articles.slice(ctrl.currentPage * pageSize, ctrl.currentPage * pageSize + pageSize);
        };

        ctrl.goToPrev = () => {
            ctrl.currentPage--;
        };
        ctrl.goToNext = () => {
            ctrl.currentPage++;
        };
    }
})();