(() => {
    angular.module('VedApp').component('vedPayment', {
        templateUrl: 'js/components/cart/payment/payment.tpl.html',
        controller: VedPaymentController,
        bindings: {
            order: '<',
            step: '<'
        }
    });

    VedPaymentController.$inject = ['$rootScope', '$mdDialog', '$translate', '$window', 'moment', 'OrderService', 'OrderCartService', '$scope', '$state', '$stateParams', '$timeout'];

    function VedPaymentController($rootScope, $mdDialog, $translate, $window, moment, OrderService, OrderCartService, $scope, $state, $stateParams, $timeout) {
        let ctrl = this;

        const maxAllowedDate = 34;
        ctrl.currentDateRange = null;
        ctrl.min = new Date().getFullYear();
        ctrl.max = ctrl.min + maxAllowedDate;
        ctrl.months = _.range(1, 13).map((month) => (month < 10) ? `0${month}` : month);
        ctrl.years = _.range(ctrl.min, ctrl.max);
        ctrl.mobile = $window.matchMedia("only screen and (max-width: 768px)").matches;
        ctrl.moment = moment;
        ctrl.copiedObject = null;
        ctrl.gtag = window.globalGtag;
        ctrl.payedPrice = 0;
        ctrl.updatedVendorPrices = null;
        ctrl.allowedDays = null;
        ctrl.chargedPrice = 0;
        ctrl.order2Save = null;
        ctrl.startPoint = null;
        ctrl.endPoint = null;
        ctrl.carryPrice = 0;
        ctrl.packages = [];
        ctrl.percentageOptions = [
            {value: 100},
            {value: 50},
            {value: 25},
            {value: 10}
        ];
        ctrl.cardNumber = cardNumber;
        ctrl.cardExpiry = cardExpiry;
        ctrl.cardCvc = cardCvc;
        ctrl.payment = {
            type: '',
            number: '',
            exp_month: null,
            exp_year: null,
            expiry: null,
            cvc: ''
        };

        // Hook methods
        ctrl.$onInit = () => {
            if(lang === 'fi'){
                $state.go('cart.payment-fi');
                return;
            }

            //enable button
            ctrl.step.validated = true;
            ctrl.cardNumber.mount('#card-number');
            ctrl.cardExpiry.mount('#card-expiry');
            ctrl.cardCvc.mount('#card-cvc');

            ctrl.registerStripeElements([ctrl.cardNumber, ctrl.cardExpiry, ctrl.cardCvc]);

            OrderCartService.getPackages().then(({data}) => {
                ctrl.packages = Object.values(data);
            });

            ctrl.copiedObject = OrderCartService.getOrderToPay();
            sessionStorage.getItem('currentDateRange') ? ctrl.currentDateRange = JSON.parse(sessionStorage.getItem('currentDateRange')) : ctrl.currentDateRange;
            sessionStorage.getItem('carryPrice') ? ctrl.carryPrice = JSON.parse(sessionStorage.getItem('carryPrice')) : ctrl.carryPrice;
            sessionStorage.getItem('newOrder') ? (ctrl.order = JSON.parse(sessionStorage.getItem('newOrder'))) : ctrl.order;
            ctrl.carryPrice = Number(sessionStorage.getItem('carryPrice'));
            sessionStorage.getItem('allowedDays') ? (ctrl.allowedDays = JSON.parse(sessionStorage.getItem('allowedDays'))) : ctrl.allowedDays;

            if (ctrl.copiedObject &&  ctrl.copiedObject !== null) {
                ctrl.carryPrice = ctrl.copiedObject ? ctrl.copiedObject.carryPrice : null;
                OrderCartService.getOrder(ctrl.copiedObject.order_id).then(({data}) => {
                    ctrl.order2Save = {
                        vendor_id: data.vendor_id,
                        vendor_address_id: null,
                        first_name: data.first_name,
                        last_name: data.last_name,
                        email: data.email,
                        phone_number: data.phone_number,
                        location: {
                            city: data.city,
                            street: data.street,
                            zipcode: data.zipcode
                        },
                        products: ctrl.sortProductsForOrder(data.lines),
                        delivery_date_range: data.delivery_date_range,
                        custom_range: null,
                        excluded_delivery_dates: ctrl.getExcludedDeliveryDates(data.excluded_delivery_dates),
                        carry: data.carry,
                        delivery_time_morning: data.delivery_time_morning,
                        delivery_time_midday: data.delivery_time_midday,
                        delivery_time_evening: data.delivery_time_evening,
                        services: [],
                        prepaid_percentage: data.prepaid_percentage,
                        coupon_code: data.coupon_code,
                        comment: data.comment,
                        transaction_token: null,
                        payment_fi: false,
                        images: ctrl.copiedObject ? ctrl.copiedObject.images : []
                    };

                    ctrl.order = Object.assign({}, ctrl.order, {
                        vendor_id: data.vendor_id,
                        vendor_address_id: null,
                        min_order_value: null,
                        first_name: data.first_name,
                        last_name: data.last_name,
                        totalSum: 0,
                        email: data.email,
                        confirm_email: data.email,
                        phone_number: data.phone_number,
                        location: {
                            zipcode: data.zipcode,
                            city: data.city,
                            street: data.street,
                            department: '',
                            latitude: '',
                            longitude: ''
                        },
                        products: ctrl.sortProductsForView(data.lines),
                        delivery_date_range: data.delivery_date_range,
                        custom_range: {
                            start_date: null,
                            end_date: null
                        },
                        carry: false,
                        services: {
                            not_at_home: false,
                            not_big_car: false
                        },
                        deliveryTime: {
                            '8_12': data.delivery_time_morning,
                            '12_17': data.delivery_time_midday,
                            '17_22': data.delivery_time_evening
                        },
                        prepaid_percentage: data.prepaid_percentage,
                        orderTotalSum: 0,
                        ranges: {
                            excludedDeliveryDates: data.excluded_delivery_dates
                        },
                        deliveryCost: (data.delivery_costs < 200) ? data.delivery_costs * 2 : data.delivery_costs,
                        coupon_code: {
                            type: '',
                            value: data.coupon_code,
                            valid: false
                        },
                        comment: data.comment,
                        transaction_token: data.transaction_token,
                        payment_fi: false,
                        images: data.images
                    });

                    let totalCount = 0;
                    ctrl.order.products.map((product) => {
                        totalCount = totalCount + product.product_total;
                    });
                    ctrl.order.totalSum = totalCount;
                    ctrl.carryPrice = ctrl.copiedObject ? ctrl.copiedObject.carryPrice : 0;
                });

                $timeout(() => {
                    if (!ctrl.order2Save.vendor_id || ctrl.order2Save.vendor_id === 'undefined' || ctrl.order2Save.vendor_id === null) {
                        OrderCartService.getDefaultVendorPrices().then((data) => {
                            ctrl.updatedVendorPrices = data;
                            changePricesToActual();
                        })
                    } else {
                        OrderCartService.getVendorPrices(ctrl.order2Save.vendor_id, ctrl.order2Save.vendor_address_id).then(({data}) => {
                            ctrl.updatedVendorPrices = data;
                            changePricesToActual();
                        })
                    }

                }, 600);
            }
        };

        ctrl.registerStripeElements = (elements) => {
            const error = document.getElementById('card-errors');
            const errorMessage = error.querySelector('.message');

            // Listen for errors from each Element, and show error messages in the UI.
            let savedErrors = {};

            elements.forEach(function(element, idx) {
                element.on('change', function(event) {
                    if (event.error) {
                        error.classList.add('visible');
                        savedErrors[idx] = event.error.message;
                        errorMessage.innerText = event.error.message;
                        // ctrl.step.validated = false;
                    } else {
                        savedErrors[idx] = null;

                        // Loop over the saved errors and find the first one, if any.
                        let nextError = Object.keys(savedErrors)
                            .sort()
                            .reduce(function(maybeFoundError, key) {
                                return maybeFoundError || savedErrors[key];
                            }, null);

                        if (nextError) {
                            // Now that they've fixed the current error, show another one.
                            errorMessage.innerText = nextError;
                        } else {
                            // The user fixed the last error; no more errors.
                            error.classList.remove('visible');
                            // ctrl.fieldsNotEmpty();
                        }
                    }
                });
            });
        };

        ctrl.elementsValidation = () => {
            // all elements valid and not empty
            if(
                !ctrl.cardCvc._empty && !ctrl.cardExpiry._empty && !ctrl.cardNumber._empty
                && !ctrl.cardCvc._invalid && !ctrl.cardExpiry._invalid && !ctrl.cardNumber._invalid
            ){
                return true;
            }

            return false;
        };

        ctrl.$onDestroy = () => {
            sessionStorage.setItem('newOrder', JSON.stringify(ctrl.order));
        };

        ctrl.sortProductsForView = (products) => {
            let sortedProducts = [];

            _.forEach(products, (productItem) => {
                let newProd = {
                    quantity: productItem.product_quantity,
                    id: productItem.id,
                    price: productItem.price,
                    carryPrice : productItem.carry_price,
                    product_total: productItem.total,
                    category: {
                        id: productItem.product_category_id,
                        name: productItem.product_name,
                    },
                    package: {
                        capacity: productItem.product_package,
                        image: ctrl.findProductImage(productItem)
                    }
                };

                sortedProducts.push(newProd);
            });


            return sortedProducts;
        };

        ctrl.findProductImage = (item) => {

            let createdImage = ctrl.packages.find((pack) => {
                if (pack.capacity == item.product_package) {
                    return pack.image;
                }
            });
            return createdImage.image;
        };

        let changePricesToActual = () => {
            _.forEach(ctrl.order2Save.lines, (product) => {
                ctrl.updatedVendorPrices.filter((vendorItem) => {
                    return (vendorItem.package.id === product.package.id &&
                        vendorItem.category.id === product.category.id) ?
                        (product.product_price = vendorItem.price,
                         product.carry_price = vendorItem.package.carry_price) : null;
                })
            })
        }

        ctrl.getExcludedDeliveryDates = (excludedDates) => {
            let sortedExcludedDates = [];

            _.forEach(excludedDates, (date) => {
                date = moment(date).format('YYYY-MM-DD');

               sortedExcludedDates.push(date);
            });

            return sortedExcludedDates;
        };

        ctrl.sortProductsForOrder = (products) => {
            let sortedProducts = [];

            _.forEach(products, (productItem) => {
                let newProd = {
                    category_id: productItem.product_category_id,
                    package_id: productItem.product_pack_id,
                    quantity: productItem.product_quantity
                };

                sortedProducts.push(newProd);
            });

            return sortedProducts;
        }

        ctrl.$doCheck = () => ctrl.selectPaymentPercentage();

        ctrl.getPercentage = (percentage, percentageFrom) => (percentage / 100) * percentageFrom;

        ctrl.roundNumber = (number) => number.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

        ctrl.selectPaymentPercentage = () => {
            let price = 0;
            if (ctrl.order.coupon_code.type === 'group_order' && ctrl.order.coupon_code.valid) {
                price = ctrl.order.totalSum + ctrl.carryPrice + ctrl.order.deliveryCost / 2;
            } else if (ctrl.order.coupon_code.type !== 'group_order') {
                price = ctrl.order.totalSum + ctrl.carryPrice + ctrl.order.deliveryCost;
            }

            ctrl.payedPrice = ctrl.getPercentage(ctrl.order.prepaid_percentage, price);
            ctrl.chargedPrice = ctrl.roundNumber((price - ctrl.payedPrice));
        };

        // Public methods
        ctrl.getCardType = () => {
            ctrl.payment.type = null;

            const re = {
                visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
                mastercard: /^5[1-5][0-9]{14}$/,
                maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
                amex: /^3[47][0-9]{13}$/,
                discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
                unionpay: /^(62|88)\d+$/
            };

            for (let key in re) {
                if (re[key].test(ctrl.payment.number)) {
                    ctrl.payment.type = key
                }
            }
        };

        ctrl.clearOrder = (order_id, status, confirmed_user, authorized) => {
            ctrl.order.orderState = Object.assign({}, {
                order_id,
                status,
                confirmed_user,
                authorized
            });

            sessionStorage.clear();
            $rootScope.$broadcast('clearCart');
        };

        ctrl.parseCard = () => {
            let card = {
                number: ctrl.payment.number,
                cvc: ctrl.payment.cvc
            };

            if(ctrl.payment.expiry){
                let index = ctrl.payment.expiry.indexOf('/');
                card = Object.assign({}, card, {
                    exp_month: ctrl.payment.expiry.slice(0, index),
                    exp_year: ctrl.payment.expiry.slice((index + 1)),
                });
            }
            
            // ctrl.step.validated = ctrl.paymentForm.$valid;

            return card;
        };

        ctrl.handlePaymentError = (error) => {
            ctrl.order.orderState.status = null;
            if (error.hasOwnProperty('custom_range.start_date') || error.hasOwnProperty('custom_range.end_date')) {
                OrderService.alert('errors.payment.extra-long-range');
            } else {
                (error.indexOf('_') !== -1) ? OrderService.alert(`errors.payment.${error}`) : OrderService.alert(error);
            }
        };

        ctrl.createStripeToken = () => {
            if (ctrl.elementsValidation()) {
                ctrl.step.validated = false;
            }

            stripe.createPaymentMethod('card', ctrl.cardNumber)
                .then((response) => {
                    if (response.error) {
                        // Inform the user if there was an error
                        const error = document.getElementById('card-errors');
                        const errorMessage = error.querySelector('.message');
                        errorMessage.textContent = response.error.message;
                        ctrl.step.validated = true;
                    } else {

                        const token = response.paymentMethod.id;

                        if (ctrl.copiedObject && ctrl.copiedObject !== null) {
                            ctrl.order2Save.transaction_token = token;

                            OrderCartService.repeatOrderFirewood(ctrl.order2Save).then((response) => {
                                if (apiURL === 'https://www.kortreistved.no/') {
                                    window.dataLayer = window.dataLayer || [];
                                    function gtag() {
                                        dataLayer.push(arguments);
                                    }
                                    gtag('js', new Date());
                                    gtag('config', 'AW-870752050');

                                    gtag('event', 'conversion', {
                                            'send_to': 'AW-870752050/rML8CMKJppMBELK-mp8D',
                                            'value': ctrl.order.totalSum,
                                            'currency': 'NOK',
                                            'transaction_id': response.data.data.order_id
                                        }
                                    );

                                }

                                $rootScope.$broadcast('removeButtons');
                                OrderCartService.setOrderToPay(null);
                                localStorage.setItem('orderId', response.data.data.order_id);
                                $state.go('cart.successful-order');

                                (!response.errors) ?
                                    ctrl.clearOrder(response.data.data.order_id, response.data.status, response.data.data.confirmed_user) : ctrl.handlePaymentError(errors);

                                response.data.data.confirmed_user && response.data.data.confirmed_user !== 'false' ? $rootScope.$broadcast('user-logged-in') : null;
                                ctrl.authorized && ctrl.authorized !== 'false' ? $rootScope.$broadcast('user-logged-in') : null;
                            })
                            .catch(error => {
                                ctrl.handleServerErrorResponse(error.data);
                            });
                        } else {
                            ctrl.order.transaction_token = token;

                            OrderCartService.orderFirewood(ctrl.order)
                                .then((response) => {
                                    if (apiURL === 'https://www.kortreistved.no/') {
                                        window.dataLayer = window.dataLayer || [];
                                        function gtag() {
                                            dataLayer.push(arguments);
                                        }
                                        gtag('js', new Date());
                                        gtag('config', 'AW-870752050');

                                        gtag('event', 'conversion', {
                                                'send_to': 'AW-870752050/rML8CMKJppMBELK-mp8D',
                                                'value': ctrl.order.totalSum,
                                                'currency': 'NOK',
                                                'transaction_id': response.data.data.order_id
                                            }
                                        );

                                    }

                                    localStorage.setItem('orderId', response.data.data.order_id);
                                    $rootScope.$broadcast('removeButtons');
                                    $state.go('cart.successful-order');

                                    (!response.errors) ?
                                        ctrl.clearOrder(response.data.data.order_id, response.data.status, response.data.data.confirmed_user) : ctrl.handlePaymentError(errors);

                                    response.data.data.confirmed_user && response.data.data.confirmed_user !== 'false' ? $rootScope.$broadcast('user-logged-in') : null;
                                    ctrl.authorized && ctrl.authorized !== 'false' ? $rootScope.$broadcast('user-logged-in') : null;
                                })
                                .catch(error => {
                                    ctrl.handleServerErrorResponse(error.data);
                                });
                        }
                    }
                })
                .error(error => {
                    ctrl.step.validated = true;
                    OrderService.alert(error.message);
                });
        };

        ctrl.handleServerErrorResponse = (error) => {
            if(error.data.requires_action){
                ctrl.step.validated = true;
                // Use Stripe.js to handle required card action
                stripe.handleCardAction(error.data.client_secret)
                    .then(response => {

                        if (response.error) {
                            const error = document.getElementById('card-errors');
                            const errorMessage = error.querySelector('.message');
                            errorMessage.textContent = response.error.message;
                            ctrl.step.validated = true;

                        } else {
                            ctrl.step.validated = false;

                            const token = response.paymentIntent.payment_method;
                            const intent = response.paymentIntent.id;

                            // The card action has been handled
                            // The PaymentIntent can be confirmed again on the server
                            if (ctrl.copiedObject && ctrl.copiedObject !== null) {
                                ctrl.order2Save.transaction_token = token;
                                ctrl.order2Save.payment_intent = intent;

                                OrderCartService.repeatOrderFirewood(ctrl.order2Save).then((response) => {
                                    if (apiURL === 'https://www.kortreistved.no/') {
                                        window.dataLayer = window.dataLayer || [];
                                        function gtag() {
                                            dataLayer.push(arguments);
                                        }
                                        gtag('js', new Date());
                                        gtag('config', 'AW-870752050');

                                        gtag('event', 'conversion', {
                                                'send_to': 'AW-870752050/rML8CMKJppMBELK-mp8D',
                                                'value': ctrl.order.totalSum,
                                                'currency': 'NOK',
                                                'transaction_id': response.data.data.order_id
                                            }
                                        );

                                    }

                                    $rootScope.$broadcast('removeButtons');
                                    OrderCartService.setOrderToPay(null);
                                    localStorage.setItem('orderId', response.data.data.order_id);
                                    $state.go('cart.successful-order');

                                    (!response.errors) ?
                                        ctrl.clearOrder(response.data.data.order_id, response.data.status, response.data.data.confirmed_user) : ctrl.handlePaymentError(errors);

                                    response.data.data.confirmed_user && response.data.data.confirmed_user !== 'false' ? $rootScope.$broadcast('user-logged-in') : null;
                                    ctrl.authorized && ctrl.authorized !== 'false' ? $rootScope.$broadcast('user-logged-in') : null;
                                })
                                .catch(error => {
                                    ctrl.step.validated = true;
                                    OrderService.alert('errors.payment.' + error.data.data);
                                });

                            } else {
                                ctrl.order.transaction_token = token;
                                ctrl.order.payment_intent = intent;

                                OrderCartService.orderFirewood(ctrl.order)
                                    .then((response) => {
                                        if (apiURL === 'https://www.kortreistved.no/') {
                                            window.dataLayer = window.dataLayer || [];
                                            function gtag() {
                                                dataLayer.push(arguments);
                                            }
                                            gtag('js', new Date());
                                            gtag('config', 'AW-870752050');

                                            gtag('event', 'conversion', {
                                                    'send_to': 'AW-870752050/rML8CMKJppMBELK-mp8D',
                                                    'value': ctrl.order.totalSum,
                                                    'currency': 'NOK',
                                                    'transaction_id': response.data.data.order_id
                                                }
                                            );

                                        }

                                        localStorage.setItem('orderId', response.data.data.order_id);
                                        $rootScope.$broadcast('removeButtons');
                                        $state.go('cart.successful-order');

                                        (!response.errors) ?
                                            ctrl.clearOrder(response.data.data.order_id, response.data.status, response.data.data.confirmed_user) : ctrl.handlePaymentError(errors);

                                        response.data.data.confirmed_user && response.data.data.confirmed_user !== 'false' ? $rootScope.$broadcast('user-logged-in') : null;
                                        ctrl.authorized && ctrl.authorized !== 'false' ? $rootScope.$broadcast('user-logged-in') : null;
                                    })
                                    .catch(error => {
                                        ctrl.step.validated = true;
                                        OrderService.alert('errors.payment.' + error.data.data);
                                    });
                            }

                        }

                    });
            } else {
                ctrl.step.validated = true;
                OrderService.alert(error.message);
            }
        };

        $scope.$on('initiateOrderPayment', () => {
            ctrl.buyFirewood();
        });

        ctrl.buyFirewood = () => {
            ctrl.createStripeToken();
            localStorage.setItem('zip_code', ctrl.order.location.zipcode);
        };

        ctrl.onFieldChanged = (form) => {
            console.log('onFieldChanged');
            if (ctrl.paymentForm.$valid ) {
                ctrl.step.validated = true;
            } else {
                ctrl.step.validated = false;
            }
        };
    }
})();