((angular) => {
    angular.module('VedApp').component('vedProductsWidget', {
        templateUrl: 'js/components/order-widget/order-widget.tpl.html',
        controller: VedOrderWidgetController
    });

    VedOrderWidgetController.$inject = ['$mdDialog', 'REGEXP', 'OrderService', 'ProfileService', 'ToastService',
        'ZipLocationService'];

    function VedOrderWidgetController ($mdDialog, REGEXP, OrderService, ProfileService, ToastService, ZipLocationService) {
        let ctrl = this;

        ctrl.products = null;
        ctrl.REGEXP = REGEXP;
        ctrl.isLoading = true;
        ctrl.zipItem = null;
        ctrl.userZipItem = null;
        ctrl.zipcodesList = [];
        ctrl.userZipCode = '';

        // Hook methods
        ctrl.$onInit = () => {
            ZipLocationService.getZipcodesList().then(({data}) => {
                ctrl.zipcodesList = data;
            }).catch();
            let storedZipCode = sessionStorage.getItem('productListZip');

            if (!storedZipCode || storedZipCode === 'undefined') {
                if (authorized) {
                    ProfileService.getUserData().then(user => {
                        if (user.address.length > 0 && user.address[0].zipcode) {
                            ctrl.userZipCode = user.address[0].zipcode;
                        }
                    }).catch().finally(() => ctrl.setZipCode(ctrl.userZipCode));
                } else {
                    ctrl.setZipCode();
                    showZipCodeModal();
                }
            } else {
                ctrl.setZipCode(storedZipCode);
            }
        };

        // Public methods
        ctrl.onZipChanged = (newZipCode) => {
            ctrl.setZipCode(newZipCode).catch(() => {
                ctrl.zipItem = angular.copy(ctrl.userZipItem);
            });
        };
        ctrl.querySearch = (query) => {
            if (query.length < 2) {
                return [];
            }
            return _.filter(ctrl.zipcodesList, (zipItem) => {
                return zipItem.zipcode.indexOf(query) === 0;
            });
        };
        ctrl.setZipCode = (newZipCode) => {
            if (newZipCode && newZipCode.search(ctrl.REGEXP.ZIP_CODE_REGEXP) >= 0 && (!ctrl.userZipItem || ctrl.userZipItem.zipcode !== newZipCode)) {
                ctrl.isLoading = true;
                sessionStorage.setItem('productListZip', newZipCode);
                // localStorage.setItem('zip_code', newZipCode);

                ToastService.toggleNotifications();
                ZipLocationService.getLocationByZip(newZipCode)
                    .then(({data}) => {
                        ctrl.zipItem = data;
                        ctrl.userZipItem = data;
                        ZipLocationService.getProductsByZip(ctrl.zipItem.zipcode).then(({data}) => {
                            ctrl.products = data;
                            setDeliveryCosts();
                            ctrl.isLoading = false;
                        });
                    }).catch(() => {
                        ctrl.searchText = newZipCode;
                        ZipLocationService.getProductsByZip(ctrl.searchText).then(({data}) => {
                            ctrl.products = data;
                            setDeliveryCosts();
                            ctrl.isLoading = false;
                        });
                    }).finally(() => {ToastService.toggleNotifications();});
            } else if (!newZipCode || newZipCode === '') {
                sessionStorage.removeItem('productListZip');
                ctrl.isLoading = true;

                ZipLocationService.getProductsByZip().then(({data}) => {
                    ctrl.products = data;
                    setDeliveryCosts();
                    ctrl.isLoading = false;
                });
            }
        };

        // Private methods
        let showZipCodeModal = () => {
            $mdDialog.show({
                templateUrl: 'js/components/order-widget/zip-code-modal.tpl.html',
                clickOutsideToClose: true,
                fullscreen: false,
                controller: userZipLocationController()
            }).then();
        };
        let setDeliveryCosts = () => {
            OrderService.getDeliveryCostPrices().then((data) => {
                let deliveryCostTable = data;
                ctrl.products.map((product) => {
                    product['delivery_cost'] = deliveryCostTable[product.pack_id];
                });
            });
        };

        let userZipLocationController = () => ['$scope', '$mdDialog', 'REGEXP', ($scope, $mdDialog, REGEXP) => {
            $scope.REGEXP = REGEXP;
            $scope.wrongZip = false;
            $scope.zipcode = '';

            $scope.close = () => $mdDialog.cancel();
            $scope.submitZipCode = (formObject) => {
                if (formObject.$valid) {
                    $scope.zipcode = $scope.zipcode || formObject.zipcode.$viewValue;
                    if ($scope.zipcode === '' || $scope.zipcode.search(ctrl.REGEXP.ZIP_CODE_REGEXP) >= 0) {
                        ctrl.setZipCode($scope.zipcode);
                        $mdDialog.hide();
                    }
                }
            };
            $scope.onFieldChanged = (name, value, form) => {
                $scope.wrongZip = false;
                $scope.zipcode = value;
            };
        }];
    }
})(angular);