(() => {
    angular.module('VedApp').component('vedMessages', {
        templateUrl: 'js/components/messages/messages.tpl.html',
        controller: VedWidgetController
    });

    VedWidgetController.$inject = ['CommunicationService', 'ProfileService', '$state', 'moment'];

    function VedWidgetController(CommunicationService, ProfileService, $state, moment) {
        let ctrl = this;

        ctrl.chatsList = null;
        ctrl.selectedChat = null;
        ctrl.user = null;
        ctrl.moment = moment;
        ctrl.messagesCount = null;

        ctrl.$onInit = () => {
            CommunicationService.getChatsList()
                .then(({data}) => {
                    ctrl.chatsList = data.data;

                    ctrl.chatsList.forEach((chat) => {
                        let momentData = moment(chat.created_at);
                        chat.created_at = momentData._d;
                        chat.lastMessage = chat.messages[chat.messages.length -1];
                    });
                });
        };

        ctrl.viewConversation = (chat) => {
            if (chat.order_id) {
                CommunicationService.setState('messages');
                $state.go('customer-question', { id: chat.order_id, type: 'ORDER' });
            } else {
                CommunicationService.setState('messages');
                $state.go('customer-question', { id: chat.id, type: 'GENERAL' });
            }
            
        }
    }
})();