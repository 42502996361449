(() => {
    angular.module('VedApp').component('vedContact', {
        templateUrl: 'js/components/contact-us/contact-us.tpl.html',
        controller: VedContactsController
    });

    VedContactsController.$inject = [];

    function VedContactsController() {
        let ctrl = this;

        ctrl.lang = lang;
        // Hook methods
        ctrl.$onInit = () => {
        };
    }
})();