(() => {
    let EditorialsService = ($http, API_URL) => {
        let getArticles = () => $http.get(`${apiURI}editorials`);
        let getArticle = (id) => $http.get(`${apiURI}editorials/${id}`);
        let getArticleComments = (id) => $http.get(`${apiURI}editorials/${id}/comments`);
        let commentArticle = (id, data) => $http.post(`${apiURI}editorials/${id}/comments`, data);
        let likeArticle = (id) => $http.post(`${apiURI}editorials/${id}/like`);

        return {
            getArticles,
            getArticle,
            getArticleComments,
            commentArticle,
            likeArticle
        };
    };

    angular.module('VedApp').factory('EditorialsService', EditorialsService);
    EditorialsService.$inject = ['$http'];
})();
