(() => {
    angular.module('VedApp').component('vedMain', {
        templateUrl: 'js/components/main/main.tpl.html',
        controller: VedMainController
    });

    function VedMainController() {
        let ctrl = this;
        ctrl.lang = lang;

        // Hook methods
        ctrl.$onInit = () => {
        };

        // Public methods
        ctrl.navigateToTutorialList = () => {};
    }
})();