((angular) => {
    angular.module('VedApp').directive('stopccp', function () {
        return {
            scope: {},
            link: function (scope, element) {
                element.on('cut copy paste', function (event) {
                    event.preventDefault();
                });
            }
        };
    });
})(window.angular);