(() => {
    angular.module('VedApp').component('vedPrivacyPolicy', {
        templateUrl: 'js/components/privacy-policy/privacy-policy.tpl.html',
        controller: VedPrivacyPolicyController
    });

    //VedPrivacyPolicyController.$inject = [];

    function VedPrivacyPolicyController() {
        let ctrl = this;

        ctrl.lang = lang;

        // Hook methods
        ctrl.$onInit = () => {
        };
    }
})();