(() => {
    let Utils = () => {
        const parseQuery = () => {
            let query = {};
            let searchParams = location.search;
            let params = (searchParams[0] === '?' ? searchParams.substr(1) : searchParams).split('&');

            params.map((paramItem) => {
                let paramKeyValue = paramItem.split('=');
                query[decodeURIComponent(paramKeyValue[0])] = decodeURIComponent(paramKeyValue[1] || '');
            });

            return query;
        };

        const getParameterByName = (name, url) => {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        };

        return {
            parseQuery,
            getParameterByName
        };
    };

    angular.module('VedApp').factory('Utils', Utils);
    Utils.$inject = [];
})();