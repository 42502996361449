(() => {
    angular.module('VedApp').component('vedCart', {
        templateUrl: 'js/components/cart/cart.tpl.html',
        controller: VedCartController
    });

    VedCartController.$inject = ['$state', '$scope', 'OrderCartService', '$rootScope', 'ROLES', 'OrderService', '$translate'];

    function VedCartController($state, $scope, OrderCartService, $rootScope, ROLES, OrderService, $translate) {
        let ctrl = this;

        ctrl.steps = [];
        ctrl.deliveryRanges = (sessionStorage.getItem('deliveryRanges')) ? JSON.parse(sessionStorage.getItem('deliveryRanges')) : [];
        ctrl.activeStep = {};
        ctrl.hideButtons = false;
        ctrl.authorized = authorized;
        ctrl.ROLES = ROLES;
        ctrl.paymentInProgress = false;
        ctrl.deliveryDateRanges = [];
        ctrl.authorized = authorized;
        ctrl.userRole = null;

        ctrl.order = {
            vendor_id: null,
            vendor_address_id: null,
            min_order_value: null,
            first_name: '',
            last_name: '',
            totalSum: null,
            email: '',
            confirm_email: '',
            phone_number: '',
            location: {
                zipcode: '',
                city: '',
                street: '',
                department: '',
                latitude: '',
                longitude: ''
            },
            salesperson: {
                firstName: '',
                lastName: '',
                email: '',
                confirmEmail: '',
                phoneNumber: '',
                purchaseNumber: '',
                comment: ''
            },
            products: [],
            delivery_date_range: null,
            custom_range: {
                start_date: null,
                end_date: null
            },
            carry: false,
            services: {
                not_at_home: false,
                not_big_car: false
            },
            deliveryTime: {
                '8_12': true,
                '12_17': true,
                '17_22': true
            },
            prepaid_percentage: null,
            deliveryConfirmation: false,
            orderTotalSum: 0,
            ranges: {
                excludedDeliveryDates: null
            },
            deliveryCost: null,
            coupon_code: {
                type: '',
                value: sessionStorage.getItem('coupon_code') ? sessionStorage.getItem('coupon_code') : null,
                valid: false
            },
            comment: '',
            transaction_token: '',
            selectedAddress: false,
            images: [],
            payment_fi: false,
            orderState: {
                order_id: null,
                status: null,
                authorized: ctrl.authorized,
                confirmed_user: false
            },
        };

        // Hook methods
        ctrl.$onInit = () => {
            ctrl.authorized = authorized;
            OrderCartService.toggleWidgetAvailable();
            $rootScope.$broadcast('disableWidget');

            if (ctrl.authorized) {
                OrderService.getUser()
                .then(({data}) => {
                    ctrl.userRole = data.role;
                }).catch();
            }

            ctrl.steps = [{
                index: 0,
                name: $translate.instant('new-order.cart.step-1'),
                status: 'active',
                href: 'cart.order-details',
                validated: false
            }, {
                index: 1,
                name: $translate.instant('new-order.cart.step-2'),
                status: 'not-passed',
                href: 'cart.personal-info',
                validated: false
            }, {
                index: 2,
                name: $translate.instant('new-order.cart.step-3'),
                status: 'not-passed',
                href: lang === 'fi' ? 'cart.payment-fi' : 'cart.payment',
                validated: false
            }];
            ctrl.activeStep = ctrl.steps[0];

            ctrl.setStepsStatuses();
            $scope.$on('$stateChangeSuccess', ctrl.setStepsStatuses);
        };

        ctrl.clearOrder = (order_id, status, confirmed_user, authorized) => {
            ctrl.order.orderState = Object.assign({}, {
                order_id,
                status,
                confirmed_user,
                authorized
            });

            sessionStorage.clear();
            $rootScope.$broadcast('clearCart');
        };

        // Public methods
        ctrl.goToStep = (indexModif) => {
            ctrl.saveNewOrderToStorage();
            for (let i = 0; i < ctrl.steps.length; i++) {
                if (ctrl.steps[i].status === 'active') {
                    if (ctrl.steps[i].index === 1 && ctrl.userRole === 'COMPANY') {
                        if (ctrl.steps[i + indexModif] && indexModif === 1) {
                            sessionStorage.getItem('newOrder') ? (ctrl.order = JSON.parse(sessionStorage.getItem('newOrder'))) : ctrl.order;
                            ctrl.order.coupon_code.value = null;
                            OrderCartService.orderFirewood(ctrl.order)
                            .then((response) => {
                                OrderCartService.setOrderToPay(null);
                                localStorage.setItem('orderId', response.data.data.order_id);
                                $rootScope.$broadcast('removeButtons');
                                if (!response.errors) {
                                    ctrl.clearOrder(response.data.data.order_id, response.data.status, response.data.data.confirmed_user);
                                }

                                $state.go('cart.successful-order');
                                sessionStorage.clear();
                                authorized && authorized !== 'false' ? $rootScope.$broadcast('user-logged-in') : null;
                            });
                        } else {
                            $state.go(ctrl.steps[i + indexModif].href);
                        }
                        return;
                    } else {
                        ctrl.steps[i + indexModif]
                            ? $state.go(ctrl.steps[i + indexModif].href)
                            : (i === 2
                                ? ctrl.steps[i].status = 'active'
                                : ctrl.steps[i].status = ctrl.steps[i - indexModif].status
                            );
                        return;
                    }
                }
            }
        };

        ctrl.saveNewOrderToStorage = () => {
            sessionStorage.setItem('deliveryRanges', JSON.stringify(ctrl.deliveryRanges));
        };

        $scope.$on('removeButtons', () => {
            ctrl.hideButtons = true;
        });

        ctrl.setStepsStatuses = () => {
            ctrl.currentStepName = $state.current.name;
            let stepperStatus = 'passed';
            ctrl.steps.map((step) => {
                if (ctrl.currentStepName === step.href) {
                    step.status = 'active';
                    ctrl.activeStep = step;
                    stepperStatus = 'not-passed';
                } else {
                    step.status = stepperStatus;
                }
            })
        };

        ctrl.goToProductsList = () => {
            ctrl.saveNewOrderToStorage();
            $state.go('products-list');
        };

        ctrl.orderPayment = () => {
            // ctrl.paymentInProgress = true;
            ctrl.goToStep(1);

            if (ctrl.order.salesperson.role !== ctrl.ROLES.company) {
                $scope.$broadcast('initiateOrderPayment');
            } else if (ctrl.order.salesperson.role === ctrl.ROLES.company) {
                ctrl.order.orderState.status = 'pending';
                ctrl.order.coupon_code.value = null;

                OrderCartService.orderFirewood(ctrl.order)
                    .then(({data: {order_id, status, confirmed_user, errors, authorized}}) => {
                        if (errors) {
                            OrderService.alert(errors);
                            ctrl.order.orderState = Object.assign({}, {
                                status: null
                            });
                        } else {
                            ctrl.order.orderState = Object.assign({}, {
                                order_id,
                                status,
                                confirmed_user
                            });

                            localStorage.setItem('orderId', ctrl.order.orderState.order_id);

                            if (apiURL === 'https://www.kortreistved.no/') {
                                window.dataLayer = window.dataLayer || [];
                                function gtag() {
                                    dataLayer.push(arguments);
                                }
                                gtag('js', new Date());
                                gtag('config', 'AW-870752050');
    
                                gtag('event', 'conversion', {
                                        'send_to': 'AW-870752050/rML8CMKJppMBELK-mp8D',
                                        'value': ctrl.order.totalSum,
                                        'currency': 'NOK',
                                        'transaction_id': order_id
                                    }
                                );
    
                            }

                            ctrl.authorized && ctrl.authorized !== 'false' ? $rootScope.$broadcast('user-logged-in') : null;
                        }
                        $state.go('cart.successful-order');
                    }).catch(({data}) => {
                        // ctrl.paymentInProgress = true;
                    });
            }
        };

        ctrl.$onDestroy = () => {
            $rootScope.$broadcast('enableWidget');
            OrderCartService.toggleWidgetAvailable();
        }
    }
})();