(() => {
    function VedGroupMemberRowController($scope, $translate, REGEXP) {
        let ctrl = this;
        ctrl.REGEXP = REGEXP;

        let validationRules = {
            first_name: {
                required: true,
                messages: {
                    required: $translate.instant('errors.form.required')
                }
            },
            last_name: {
                required: true,
                messages: {
                    required: $translate.instant('errors.form.required')
                }
            },
            email: {
                pattern: REGEXP.EMAIL_REGEXP,
                required: true,
                messages: {
                    pattern: $translate.instant('errors.form.email-pattern'),
                    required: $translate.instant('errors.form.required')
                }
            }
        };

        // Hook methods
        ctrl.$onChanges = (changes) => {
            if (angular.isDefined(changes.formSubmitted) && !changes.formSubmitted.isFirstChange()) {
                $scope['groupMember' + ctrl.index].$submitted = ctrl.formSubmitted;
                ctrl.formSubmitted && angular.isFunction($scope['groupMember' + ctrl.index].$validate) ?
                    $scope['groupMember' + ctrl.index].$validate() : null;
            }
        };

        // Public methods
        ctrl.changeMember = (name, value, form) => {
            ctrl.member[name] = value;
            angular.isFunction(form.$validate) ? form.$validate() : null;
            form.$valid ? ctrl.onChangeCallback({itemIndex: ctrl.index, item: ctrl.member}) : null;
        };
        ctrl.deleteMember = () => {
            ctrl.onDeleteCallback({itemIndex: ctrl.index, item: ctrl.member});
        };
    }

    VedGroupMemberRowController.$inject = ['$scope', '$translate', 'REGEXP'];

    angular.module('VedApp').component('vedGroupMemberRow', {
        templateUrl: 'js/components/common/group-member-row/group-member-row.tpl.html',
        controller: VedGroupMemberRowController,
        bindings: {
            member: '<',
            index: '<',
            showLabels: '<',
            formSubmitted: '<',
            onChangeCallback: '&',
            onDeleteCallback: '&'
        }
    });
})();