(() => {
    angular.module('VedApp').component('vedPosts', {
        templateUrl: 'js/components/home/posts/posts.tpl.html',
        controller: VedPostsController
    });

    VedPostsController.$inject = ['ArticlesService'];

    function VedPostsController(ArticlesService) {
        let ctrl = this;
        ctrl.posts = [];

        // Hook methods
        ctrl.$onInit = () => {
            ArticlesService.getArticles().then(({data}) => {
                ctrl.posts = data;
            });
        };
    }
})();