((angular) => {
    angular.module('VedApp').component('vedOrder', {
        templateUrl: 'js/components/order/order.tpl.html',
        controller: vedOrderController
    });

    vedOrderController.$inject = ['$mdDialog', '$document', 'Utils', 'ORDER_TYPES', 'ROLES', 'OrderService',
        'ZipLocationService', 'ToastService'];

    function vedOrderController($mdDialog, $document, Utils, ORDER_TYPES, ROLES, OrderService, ZipLocationService, ToastService) {
        let ctrl = this;

        ctrl.deliveryRanges = (sessionStorage.getItem('deliveryRanges')) ? JSON.parse(sessionStorage.getItem('deliveryRanges')) : [];
        ctrl.authorized = authorized;
        ctrl.ROLES = ROLES;
        ctrl.ORDER_TYPES = ORDER_TYPES;
        ctrl.newOrder = {
            addresses: [],
            address: null,
            services: {
                carry: false,
                deliverNoAtHome: true,
                noBigCar: false
            },
            code: {
                type: '',
                value: sessionStorage.getItem('coupon_code') ? sessionStorage.getItem('coupon_code') : null,
                valid: false
            },
            ranges: {
                excludedDeliveryDates: null
            },
            deliveryRange: null,
            customRange: {
                startDate: null,
                endDate: null
            },
            deliveryTime: {
                '8_12': true,
                '12_17': true,
                '17_22': true
            },
            salesperson: {
                firstName: '',
                lastName: '',
                email: '',
                confirmEmail: '',
                phoneNumber: '',
                purchaseNumber: '',
                comment: ''
            },
            stripeToken: null,
            products: [],
            deliveryCost: 0,
            orderTotalSum: 0,
            minimumOrderPrice: 0,
            percentage: 100,
            carryPrice: 0,
            orderState: {
                order_id: null,
                status: null,
                authorized: ctrl.authorized
            },
            step: 1,
            type: (!!location.search) ? ctrl.ORDER_TYPES.quick : ctrl.ORDER_TYPES.long
        };
        ctrl.quickOrderValue = null;

        ctrl.setProperOrderType = () => (!!location.search) ? ctrl.ORDER_TYPES.quick : ctrl.ORDER_TYPES.long;

        ctrl.setMenuActive = () => {
        };

        ctrl.$onInit = function () {
            ctrl.setMenuActive();

            ctrl.quickOrderValue = Utils.parseQuery();
            ctrl.newOrder = (sessionStorage.getItem('order')) ? JSON.parse(sessionStorage.getItem('order')) : ctrl.newOrder;
            ctrl.newOrder.code.value = sessionStorage.getItem('coupon_code') ? sessionStorage.getItem('coupon_code') : ctrl.newOrder.code.value;

            ToastService.toggleNotifications();

            if (ctrl.newOrder.type === ctrl.ORDER_TYPES.quick) {
                let storedZipCode = localStorage.getItem('zip_code');
                if (storedZipCode && storedZipCode !== 'undefined') {
                    ctrl.newOrder.address = {
                        zipcode: storedZipCode
                    };
                    ZipLocationService.getLocationByZip(storedZipCode).then(({data}) => {
                        ctrl.newOrder.address.id = null;
                        ctrl.newOrder.address.city = data.city;
                    }).finally(() => {ToastService.toggleNotifications();});
                }
            }

            (ctrl.authorized) ?
                OrderService.getUser()
                    .then(({data: {role, first_name: firstName, last_name: lastName, phone_number: phoneNumber, email, address}}) => {
                        ctrl.newOrder.addresses = address;
                        ctrl.newOrder.address = (ctrl.newOrder.address) ? ctrl.newOrder.address : Object.assign({}, ctrl.newOrder.addresses[0]);
                        ctrl.newOrder.salesperson = Object.assign({}, {
                            role,
                            firstName,
                            lastName,
                            phoneNumber,
                            email,
                            confirmEmail: email
                        });
                    }) : null;
        };

        ctrl.saveOrderToStorage = () => {
            sessionStorage.setItem('deliveryRanges', JSON.stringify(ctrl.deliveryRanges));
            sessionStorage.setItem('order', JSON.stringify(ctrl.newOrder));
        };

        ctrl.saveAddress = (step) => {
            ctrl.moveToNextStep((step - 1));
            ctrl.newOrder.type = ctrl.setProperOrderType();

            if (ctrl.newOrder.type === ctrl.ORDER_TYPES.quick) {
                OrderService.getPreSelectedProduct(ctrl.quickOrderValue, ctrl.newOrder.address)
                    .then(({data}) => {
                        if (data.error_message) {
                            ctrl.resetOrderState();
                            OrderService.alert(data.error_message);
                        } else {
                            ctrl.saveProducts(data, step)
                        }
                    })
            } else {
                OrderService
                    .getProducts(ctrl.newOrder.address)
                    .then(({data}) => {
                        if (data.id && data.products.length > 0) {
                            ctrl.resetOrderState();
                            ctrl.saveProducts(data, step);
                        } else {
                            OrderService.alert();
                        }
                    });
            }
        };

        ctrl.resetOrderState = () => {
            ctrl.newOrder = Object.assign({}, ctrl.newOrder, {
                products: [],
                minimumOrderPrice: 0,
                orderTotalSum: 0,
                deliveryCost: 0,
                type: ctrl.setProperOrderType(),
                carryPrice: 0,
                services: {
                    carry: false,
                    deliverNoAtHome: false,
                    noBigCar: false
                }
            });

            ctrl.moveToNextStep(1, false);
        };

        ctrl.saveProducts = (data, step) => {
            ctrl.resetOrderState();
            let products = data.products;

            ctrl.deliveryRanges = [
                {value: 'EXTRA_LONG', index: 0},
                {value: '12_30', index: 0},
                {value: '7_12', index: 0},
                {value: '4_6', index: +(data.extra_price_4_6)},
                {value: '2_3', index: +(data.extra_price_2_3)},
                {value: 'TODAY', index: +(data.extra_price_TODAY)}
            ];
            ctrl.newOrder.deliveryRange = ctrl.deliveryRanges[2].value;
            ctrl.newOrder.minimumOrderPrice = +(data.min_order_value);
            ctrl.newOrder.products = products.map(product => Object.assign({}, product, {
                quantity: (ctrl.newOrder.type === ctrl.ORDER_TYPES.quick) ? ctrl.quickOrderValue.quantity : '',
                carry_price: +(product.carry_price),
                price: +(product.price),
                _price: +(product.price)
            }));
            ctrl.moveToNextStep(step);
        };

        ctrl.moveToNextStep = (nextStep, update = true) => {
            if (ctrl.newOrder.step < nextStep || nextStep === 1) {
                ctrl.newOrder.step = nextStep;
            }

            let scrollToStep = angular.element(document.getElementById(`step-${nextStep}`));
            $document.scrollToElement(scrollToStep, 100, 500);

            update ? ctrl.saveOrderToStorage() : null;
        };
    };
})(angular, jQuery);
