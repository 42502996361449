((angular, _) => {
    angular.module('VedApp').component('vedAddressImageUploader', {
        templateUrl: 'js/components/profile/address-image-uploader/address-image-uploader.tpl.html',
        controller: vedAddressImageUploaderController,
        bindings: {
            department: '<',
            index: '@?',
            user: '<'
        }
    });

    vedAddressImageUploaderController.$inject = ['ProfileService', 'ROLES', '$translate', 'FileUploader', '$mdDialog', 'ToastService', '$scope'];

    function vedAddressImageUploaderController(ProfileService, ROLES, $translate, FileUploader, $mdDialog, ToastService, $scope) {
        let ctrl = this;

        ctrl.imageIsToBig = false;
        ctrl.ROLES = ROLES;

        ctrl.$onInit = () => {
            if (ctrl.user.role === ctrl.ROLES.company) {
                ctrl.user.address.find((address) => {
                    (address.id === ctrl.department.id) ? ctrl.department.images = address.images : null;
                });
            }
        }

        ctrl.deleteImage = (image) => {
            ProfileService.deleteImage(image.id)
                .then(() => {
                    ctrl.department.images = ctrl.department.images.filter(imageItem => imageItem.id !== image.id);
                    ToastService.show(true, $translate.instant('errors.success'));
                });
        };

        ctrl.uploader = new FileUploader({
            url: `/user/address/image`,
            formData: [
                {address_id: ctrl.department.id}
            ]
        });

        ctrl.uploader.filters.push({
            name: 'imageFilter',
            fn(item) {
                let type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                return '|jpg|png|jpeg|'.indexOf(type) !== -1;
            }
        });

        ctrl.showAlert = () => {
            let alert = $mdDialog.alert()
                .clickOutsideToClose(true)
                .title($translate.instant('errors.heading'))
                .textContent($translate.instant('errors.form.image-upload'))
                .ok($translate.instant('errors.close'));

            $mdDialog.show(alert).then(() => null, () => null);
        };

        ctrl.uploader.onBeforeUploadItem = (item) => {
            if (!item.formData[0].address_id && !ctrl.department.id) {
                ctrl.showAlert();
                item.cancel();
            } else {
                item.formData[0].address_id = ctrl.department.id;
            }
        };

        ctrl.uploader.onSuccessItem = (fileItem, response) => {
            ctrl.department.images = response.images;

            $scope.$emit('departmentChanged', ctrl.department);
        };

        ctrl.uploader.onAfterAddingAll = () => {
            ctrl.imageIsToBig = false;
            let validImages = _.filter(ctrl.uploader.queue, (queueFile) => {
                let fileSize = queueFile._file.size;
                ctrl.imageIsToBig = ctrl.imageIsToBig || fileSize > 3000000;
                return fileSize <= 3000000;
            });
            ctrl.uploader.queue = validImages;

            ctrl.uploader.uploadAll();
        };

        ctrl.uploader.onErrorItem = () => {
            ToastService.show(false, $translate.instant('errors.general'));
        };
    }
})(angular, _);