(() => {
    angular.module('VedApp').factory('HttpInterceptor', ['$q', '$injector', '$translate', ($q, $injector, $translate) => {
        return {
            request: (request) => request,
            requestError: (rejection) => $q.reject(rejection),

            response: (response) => {

                let headers = response.headers();
                if ('client-notifications' in headers) {
                    let serializedMessages = headers['client-notifications'];

                    let ToastService = $injector.get('ToastService');
                    ToastService.showMany(JSON.parse(serializedMessages));
                }
                return response;
            },
            responseError: (error) => {
                let ToastService = $injector.get('ToastService');

                console.error(error);
                if (angular.isObject(error.data) && (angular.isObject(error.data.errors) || angular.isArray(error.data.errors))) {
                    _.forEach(error.data.errors, (errorField) => {
                        let errorMessage = $translate.instant('errors.form.' + errorField) != ('errors.form.' + errorField) ?
                            $translate.instant('errors.form.' + errorField) : errorField[0] ?
                                errorField[0] : $translate.instant('errors.form.general') + error.statusText ? error.statusText : null;
                        ToastService.show(false, errorMessage);
                    });
                } else {
                    let errorMessage = error.data && (error.data.message || error.data.error) &&
                        $translate.instant('errors.form.' + error.data.message) != ('errors.form.' + error.data.message) ?
                        $translate.instant('errors.form.' + error.data.message) :
                        $translate.instant('errors.form.' + error.data.error) != ('errors.form.' + error.data.error) ?
                        $translate.instant('errors.form.' + error.data.error) :
                        `${$translate.instant('errors.form.toast-error')} :
                        ${error.data.errors ?  error.data.errors : error.data.message ? error.data.message : 
                            error.statusText ? error.statusText : error.message ? error.message: null}`;
                    ToastService.show(false, errorMessage);
                }

                return $q.reject(error);
            }
        }
    }]);
})();
