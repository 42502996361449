(() => {
    angular.module('VedApp')
        .constant('REGEXP', {
            EMAIL_REGEXP: '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$',
            NUMBERS_REGEXP: '^[0-9]*$',
            PHONE_NUMBER_REGEXP: '^[0-9]{5,12}$',
            POSITIVE_NUMBERS_REGEXP: '^[1-9][0-9]*$',
            NUMBERS_CHARACTERS: '^[a-zA-Z WÆæØøÅåÖöÄäŽž]*$',
            NUMBERS_AND_DOT: '^[.0-9]*$',
            NUMBERS_WITH_RANGE: '^[0-9 ,-]*$',
            DECIMAL_PRICE_NUMBERS: '^[0-9]*(.|,)[0-9]*$',
            NAME_REGEXP: '^[a-zA-Z- \WÆæØøÅåÖöÄäŽž]{3,25}$',
            NUMBERS_FROM_ZERO_TO_SIX: '([0-6])',
            NUMBERS_FROM_ZERO_TO_ONE_HUNDRED: '([0-9][0-9]?|100)',
            ZIP_CODE_REGEXP: '^[0-9]{4,5}$', // Norway zip code
            DUGNAD_NAME: '^.{3,}$',
            STREET_REGEXP: '^.{4,}$'
        })
        .constant('GOOGLE_MAP', {
            key: googleMapApiKey,
            sensor: true,
            language: lang,
            region: lang.toUpperCase(),
        })
        .constant('SLIDER_CONFIG', {
            selector: '#product-images-slider',
            effect: 'fade',
            height: 'auto',
            transitionDuration: 0
        })
        .constant('LANGUAGES', {
            no: 'Norge',
            fi: 'Sverige',
            en: 'English'
        })
        .constant('ROLES', {
            company: 'COMPANY',
            buyer: 'BUYER',
            vendor: 'VENDOR',
            admin: 'ADMIN'
        })
        .constant('ORDER_TYPES', {
            long: 'LONG',
            quick: 'QUICK'
        })
        .constant('PAID_STATUSES', {
            paid: 'PAID',
            partially_paid: 'PARTIALLY_PAID',
            unpaid: 'UNPAID',
        })
        .constant('TOAST_HIDE_DELAY', 3000)
})();