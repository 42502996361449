((angular, _) => {
    angular.module('VedApp').component('vedChangePassword', {
        templateUrl: 'js/components/profile/change-password/change-password.tpl.html',
        controller: vedChangePasswordController
    });

    vedChangePasswordController.$inject = ['ProfileService', 'ToastService', '$translate'];

    function vedChangePasswordController(ProfileService, ToastService, $translate) {
        this.password = {
            current_password: '',
            new_password: '',
            confirm_password: '',
            errors: null
        };

        this.resetErrors = () => this.password.errors = null;

        this.showPassword = ($event) => {
            let input = $($event.target).parents('md-input-container').find('input');
            (input.attr('type') === 'password') ? input.attr('type', 'text') : input.attr('type', 'password');
        };

        this.changePassword = () => {
            ProfileService.changePassword(this.password).then(() => {
                    ToastService.show(true, $translate.instant('errors.success'));
                })
                .catch(({data: {errors}}) => this.password.errors = errors);
        };

        this.onChangePassword = (form) => form.$valid ? this.changePassword() : null;
    };
})(angular, _);