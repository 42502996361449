(() => {
    angular.module('VedApp').component('vedOrderDetails', {
        templateUrl: 'js/components/cart/order-details/order-details.tpl.html',
        controller: VedOrderDetailsController,
        bindings: {
            onSave: '&',
            order: '<',
            step: '<'
        }
    });

    VedOrderDetailsController.$inject = ['ROLES', 'OrderCartService', '$state', '$scope', '$timeout', 'OrderService', 'ToastService', '$translate', '$rootScope', 'moment', 'REGEXP', '$mdDialog', '$window'];

    function VedOrderDetailsController(ROLES, OrderCartService, $state, $scope, $timeout, OrderService, ToastService, $translate, $rootScope, moment, REGEXP, $mdDialog, $window) {
        let ctrl = this;
        
        ctrl.REGEXP = REGEXP;
        ctrl.temporary = null;
        ctrl.lang = lang;
        ctrl.carryPrice = null;
        ctrl.ROLES = ROLES;
        ctrl.authorized = authorized;
        ctrl.isDugnadDisabled = false;
        ctrl.productsValid = null;
        ctrl.mobile = $window.matchMedia("only screen and (max-width: 600px)").matches;
        ctrl.moment = moment;
        ctrl.disableCarry = false;
        ctrl.vendorIdForPrices = null;
        ctrl.vendorAddressIdForPrices = null;
        ctrl.copiedObject = null;
        ctrl.updatedVendorPrices = [];
        ctrl.rangeOpen = false;
        ctrl.products = [];
        ctrl.highlightDays = [];
        ctrl.allowedDays = [];
        ctrl.startMonth = null;
        ctrl.currentDatesRange = [];
        ctrl.currentDateRange = null;
        ctrl.disabledDaysAmount = null;
        ctrl.selectedDays = [];
        ctrl.rangeLength = 0;
        ctrl.week = {
            start: null,
            end: null,
            selected: []
        };
        ctrl.rangeLength = 0;
        ctrl.isDugnadInfoCollapsed = false;
        ctrl.codeAutocomplete = {
            searching: false,
            loading: false,
            results: null,
            error: null
        };
        ctrl.deliveryDateRanges = [];

        // Hook methods
        ctrl.$onInit = () => {
            ctrl.copiedObject = OrderCartService.getOrderToPay();
            if (ctrl.authorized) {
                OrderService.getUser()
                .then(({data}) => {
                    if (data.role === 'COMPANY') {
                        ctrl.isDugnadDisabled = true;
                    }
                }).catch();
            }
            sessionStorage.getItem('newOrder') ? (ctrl.order = JSON.parse(sessionStorage.getItem('newOrder'))) : ctrl.order;
            ctrl.currentDateRange = sessionStorage.getItem('currentDateRange') ? JSON.parse(sessionStorage.getItem('currentDateRange')): null;
            ctrl.order.products = sessionStorage.getItem('cartItems') ? JSON.parse(sessionStorage.getItem('cartItems')) : [];
            ctrl.order.location = sessionStorage.getItem('customerAddress') ? JSON.parse(sessionStorage.getItem('customerAddress')): ctrl.order.location;
            (!ctrl.isDugnadDisabled) ?  ctrl.order.coupon_code.value = sessionStorage.getItem('groupCode') : null;

            (ctrl.order.coupon_code.value !== null) ? ctrl.isDugnadInfoCollapsed = true : false;
            (ctrl.copiedObject && ctrl.copiedObject !== null) ? ctrl.order = JSON.parse(sessionStorage.getItem('newOrder')) : null;

            if (ctrl.order) {
                _.forEach(ctrl.order.products, (productItem) => {
                    if (productItem.vendorForGetPricesAddressId && productItem.vendorForGetPricesId) {
                        ctrl.vendorIdForPrices = productItem.vendorForGetPricesId;
                        ctrl.vendorAddressIdForPrices = productItem.vendorForGetPricesAddressId;
                        return;
                    }
                });
            }

            ctrl.getVendorPrices(ctrl.vendorIdForPrices);
            ctrl.getVendorServices(ctrl.vendorIdForPrices);
        };

        ctrl.$onDestroy = () => {
            delete $window.onbeforeunload;
            sessionStorage.setItem('newOrder', JSON.stringify(ctrl.order));
            sessionStorage.setItem('currentDateRange', JSON.stringify(ctrl.currentDateRange));
        };

        $window.onbeforeunload = () => {
            sessionStorage.setItem('newOrder', JSON.stringify(ctrl.order));
            sessionStorage.setItem('currentDateRange', JSON.stringify(ctrl.currentDateRange));
        }

        // Public methods
        ctrl.removeProduct = (product) => {
            let index = ctrl.order.products.indexOf(product);

            ctrl.order.products.splice(index, 1);
            ctrl.calculateProductPrices();

            sessionStorage['cartItems'] = JSON.stringify(ctrl.order.products);
            $rootScope.$broadcast('newItemAppearedInCart');

            if (ctrl.order.products.length === 0) {
                $state.go('products-list');
            }
        };

        ctrl.applyCarry = () => {
            ctrl.carryPrice = 0;

            ctrl.order.products
                .filter(product => (+(product.quantity) > 0))
                .map(product => {
                    if (ctrl.order.carry) {
                        product.carryPrice = (product.quantity * product.package.carry_price);
                        ctrl.carryPrice += product.carryPrice;
                    } else {
                        product.carryPrice = 0;
                    }
                });

                sessionStorage.setItem('carryPrice', ctrl.carryPrice);
        };

        ctrl.enableCarry = () => {
            let selectedProducts = ctrl.order.products.filter(product => (+(product.quantity) > 0));
            ctrl.disableCarry = (selectedProducts.length > 0) ?
                selectedProducts.every(product => (+(product.package.capacity) === 1000 || +(product.package.capacity) === 1500)) : false;
            if (ctrl.disableCarry) {
                ctrl.order.carry = !ctrl.disableCarry;
                ctrl.carryPrice = 0;
            } else {
                ctrl.applyCarry();
            }
        };

        ctrl.toggleRangeDatePickers = () => ctrl.rangeOpen = !ctrl.rangeOpen;

        ctrl.showOrder = () => {
        };

        ctrl.getVendorPrices = (vendorId, addressId) => {
            if (!vendorId || vendorId === 'undefined' || vendorId === null) {
                OrderCartService.getDefaultVendorPrices().then(({data}) => {
                    ctrl.updatedVendorPrices = data;
                })
                .then(() => {
                    changePricesToActual();
                }).then(() => {
                    ctrl.order.products.map((product) => {
                        product.product_total = product.quantity * product.price;
                    });
                });
            } else {
                OrderCartService.getVendorPrices(vendorId, addressId)
                .then(({data}) => {
                    ctrl.updatedVendorPrices = data;
                })
                .then(() => {
                    changePricesToActual();
                })
                .then(() => {
                    ctrl.order.products.map((product) => {
                        product.product_total = product.quantity * product.price;
                    });
                });
            }

        };

        let changePricesToActual = () => {
            _.forEach(ctrl.order.products, (product) => {
                    ctrl.updatedVendorPrices.filter((vendorItem) => {
                        return (vendorItem.package.id === product.package.id &&
                                vendorItem.category.id === product.category.id) ?
                                (product.price = vendorItem.price,
                                 product.carryPrice = vendorItem.package.carry_price,
                                 product.package.carry_price = (vendorItem.carry_price && vendorItem.carry_price !== null) ? vendorItem.carry_price : vendorItem.package.carry_price
                                ) : null;
                    });
            });

        };

        ctrl.getVendorServices = (vendorId) => {
            if (vendorId === null || vendorId === 'undefined' || !vendorId) {
                OrderCartService.getDefaultVendorServices().then(({data}) => {
                    ctrl.deliveryDateRanges = data.delivery_ranges;
                    ctrl.order.min_order_value = data.min_order_value;
                    if (ctrl.currentDateRange == null) {
                        ctrl.currentDateRange = ctrl.deliveryDateRanges['7_12'];
                    } else {
                        return false;
                    }
                })
                .then(() => {
                    ctrl.calculateProductPrices();
                })
                .then(() => {
                    if (ctrl.currentDateRange === null) {
                        ctrl.order.delivery_date_range = '7_12';
                    } else {
                        ctrl.order.delivery_date_range = ctrl.currentDateRange.name;
                    }
                })
                .finally(() => {
                    ctrl.updateDateRange();
                });
            } else {
                OrderCartService.getVendorServices(vendorId).then(({data}) => {
                    ctrl.deliveryDateRanges = data.delivery_ranges;
                    ctrl.order.min_order_value = data.min_order_value;
                    if (ctrl.currentDateRange == null) {
                        ctrl.currentDateRange = ctrl.deliveryDateRanges['7_12'];
                    } else {
                        return false;
                    }
                })
                .then(() => {
                    ctrl.calculateProductPrices();
                })
                .then(() => {
                    if (ctrl.currentDateRange === null) {
                        ctrl.order.delivery_date_range = '7_12';
                    } else {
                        ctrl.order.delivery_date_range = ctrl.currentDateRange.name;
                    }
                })
                .finally(() => {
                    ctrl.updateDateRange();
                });
            }

        };

        ctrl.getRange = () => {
            return (+(_.last(ctrl.order.delivery_date_range.split('_'))) + 1);
        };

        ctrl.calculateDateRange = (range, startFrom) => {
            ctrl.rangeLength = range ? range : ctrl.getRange();
            ctrl.selectedDays = [];
            ctrl.order.ranges.excludedDeliveryDates = [];
            ctrl.disabledDaysAmount = ctrl.setDisabledDaysAmount();
            ctrl.currentDatesRange = ctrl.setRange(startFrom).map(({date}) => ctrl.moment(date));
            ctrl.highlightDays = ctrl.setRange(startFrom);
            ctrl.setAllowedDays(startFrom);
            ctrl.setMonthView(ctrl.highlightDays);

            let rangeToChange = ctrl.order.delivery_date_range;

            if (typeof(ctrl.deliveryDateRanges[rangeToChange]) !== 'undefined') {
                ctrl.currentDateRange = ctrl.deliveryDateRanges[rangeToChange];
            }
        };

        ctrl.setMonthView = (dateItem) => {
            ctrl.startMonth = (!!dateItem.length) ?
                ctrl.moment(_.first(dateItem).date) :
                ctrl.moment(dateItem.date);
        };

        ctrl.resetDateRange = () => {
            ctrl.highlightDays = [];
            ctrl.allowedDays = [];
            ctrl.currentDatesRange = [];
            ctrl.disabledDaysAmount = null;
        };

        ctrl.resetCustomRange = () => {
            ctrl.order.custom_range = {
                start_date: null,
                end_date: null
            };
        };

        ctrl.setDisabledDaysAmount = () => {
            switch (ctrl.order.delivery_date_range) {
                case '7_12':
                    return 2;
                case '4_6':
                    return 1;
                case '2_3':
                    return 1;
                default:
                    return 0;
            }
        };

        ctrl.updateDateRange = (previousRange) => {
            if (ctrl.order.delivery_date_range === 'TODAY') {
                ctrl.resetDateRange();
            } else {
                ctrl.calculateDateRange();
            }

            (previousRange && ctrl.order.delivery_date_range === 'EXTRA_LONG') ?
                ctrl.showExtraDeliveryRangeModal(previousRange) :
                null;
        };

        ctrl.showExtraDeliveryRangeModal = (previousRange) => {
            $mdDialog.show({
                templateUrl: 'js/components/cart/order-details/extra-long-delivery.tpl.html',
                locals: {previousRange: previousRange},
                clickOutsideToClose: true,
                fullscreen: false,
                controller: ['$scope', '$mdDialog', 'moment', 'previousRange', ($scope, $mdDialog, moment, previousRange) => {

                    // TODO mocked dates
                    let defaultStartDate = '2019-08-20',
                        defaultEndDate = '2019-09-20',
                        startRangeDate = '2019-08-01',
                        endRangeDate = '2019-09-30';

                    $scope.moment = moment;
                    $scope.generateDateRange = (startDate, endDate, key) => {
                        let range = [];
                        let endPoint = $scope.moment(endDate);
                        let startPoint = $scope.moment(startDate);

                        while (endPoint >= startPoint) {
                            range.push($scope.moment(startPoint.format('YYYY-MM-DD')));
                            startPoint.add(1, key);
                        }

                        return range;
                    };

                    $scope.isCustomRange = () => {
                        let customRangeNotEmpty = ctrl.order.custom_range.startDate !== null;
                        return customRangeNotEmpty && (ctrl.order.custom_range.startDate !== defaultStartDate || ctrl.order.custom_range.endDate !== defaultEndDate);
                    };

                    $scope.extraLongDelivery = {
                        custom: $scope.isCustomRange(),
                        startDate: ctrl.order.custom_range.startDate ? [$scope.moment(ctrl.order.custom_range.startDate)] : [],
                        endDate: ctrl.order.custom_range.endDate ? [$scope.moment(ctrl.order.custom_range.endDate)] : [],
                        defaultRange: {
                            start: $scope.moment(defaultStartDate),
                            end: $scope.moment(defaultEndDate)
                        },
                        range: $scope.generateDateRange(startRangeDate, endRangeDate, 'day')
                    };
                    $scope.dataPickersConfig = {
                        startDatePickerOpen: false,
                        endDatePickerOpen: false,
                        startDateBaseMonth: _.first($scope.extraLongDelivery.range),
                        endDateBaseMonth: _.last($scope.extraLongDelivery.range)
                    };

                    $scope.updateDate = (property, date) => {
                        $scope.extraLongDelivery[property] = [date];
                        $scope.dataPickersConfig[`${property}PickerOpen`] = false;
                    };
                    $scope.onStartDateSelect = (event, {date}) => $scope.updateDate('startDate', date);
                    $scope.onEndDateSelect = (event, {date}) => $scope.updateDate('endDate', date);

                    $scope.getRange = (startDate, endDate) => (endDate && startDate) ? endDate.diff(startDate, 'days') : 0;

                    $scope.saveExtraDeliveryRange = (form) => {
                        if (form.$valid) {
                            let range = 0;
                            let startDate = null;

                            if ($scope.extraLongDelivery.custom) {
                                range = $scope.getRange(
                                    _.first($scope.extraLongDelivery.startDate),
                                    _.first($scope.extraLongDelivery.endDate)
                                );
                                startDate = _.first($scope.extraLongDelivery.startDate);
                            } else {
                                range = $scope.getRange(
                                    $scope.extraLongDelivery.defaultRange.start,
                                    $scope.extraLongDelivery.defaultRange.end
                                );
                                startDate = $scope.extraLongDelivery.defaultRange.start;
                            }

                            ctrl.calculateDateRange(range + 1, startDate);
                            ctrl.setCustomRangeBorders();
                            $mdDialog.cancel();
                        }
                    };

                    $scope.close = () => {
                        ctrl.order.deliveryRange = (previousRange || ctrl.order.deliveryRange);
                        previousRange !== 'EXTRA_LONG' ? ctrl.calculateDateRange() : null;
                        $mdDialog.cancel();
                    }
                }]
            });
        };

        ctrl.setAllowedDays = () => ctrl.allowedDays = ctrl.highlightDays.map(({date}) => ctrl.moment(date));

        ctrl.resetCode = () => {
            ctrl.order.coupon_code = {
                type: '',
                value: '',
                valid: false
            };
            ctrl.codeAutocomplete = {
                loading: false,
                searching: false,
                results: null,
                error: null
            };
        };

        ctrl.searchCode = () => {
            ctrl.codeAutocomplete.searching = true;
            ctrl.codeAutocomplete.loading = true;
            OrderService.getCode(ctrl.order.coupon_code.value)
                .then(data => {
                    ctrl.codeAutocomplete.loading = false;

                    if (data.status === 'success') {
                        ctrl.codeAutocomplete = Object.assign({}, ctrl.codeAutocomplete, {
                            error: null,
                            results: data.data
                        });
                    } else {
                        ctrl.codeAutocomplete = Object.assign({}, ctrl.codeAutocomplete, {
                            error: data,
                            results: null
                        });
                    }
                });
        };

        ctrl.selectCode = (code) => {
            ctrl.order.coupon_code.value = code;
            ctrl.codeAutocomplete.loading = false;
            ctrl.codeAutocomplete.searching = false; 
        };

        ctrl.openValidationModal = () => {
            $mdDialog.show({
                parent:angular.element(document.body),
                template: `
                <form name="codeValidationForm">
                    <div class="container code-validation-modal">
                        <div class="row">
                            <div class="col s12">
                                <h5>{{'new-order.details.address-modal-title' | translate}}</h5>

                                <p class="distance-error" ng-show="distanceError">
                                    {{'new-order.errors.wrong-distance' | translate}}
                                </p>
                            </div>
                        </div>

                        <div class="address-validation">
                            <div class="row">
                                <div class="col s12">
                                <span ng-show="authorized" style="display: block; margin-bottom: 10px;" ng-click="openAddressModal()" 
                                class="address-dropdown">{{'new-order.details.select-from-address' | translate}}</span>

                                <md-input-container style="margin-bottom: 13px;" class="drop-address-container" ng-show="showAddressDropdown">
                                    <span class="address-label">{{'new-order.details.address' | translate}}</span>
                                    <md-select ng-model="loggedUserAddress">
                                        <md-option ng-value="option" ng-repeat="option in addresses" ng-click="changeLocation(option)">
                                            <span>{{option.street}}, {{option.city}}, {{option.zipcode}}</span>
                                        </md-option>
                                    </md-select>
                                </md-input-container>
                                    <md-input-container class="field-container">
                                        <md-button class="gps-location-btn md-icon-button"
                                                    style="display: none;"
                                                    ng-click="$ctrl.useGeoLocation(address)"
                                                    aria-label="{{'Use my Geo-location' | translate}}">
                                            <i class="material-icons" md-colors="{'color': 'grey-700'}">gps_fixed</i>
                                            <md-tooltip md-direction="top">
                                                {{'profile.user-profile.use-my-geolocation' | translate}}
                                            </md-tooltip>
                                        </md-button>
                                        <span class="address-label" for="street-mobile">
                                            {{'new-order.details.street-address' | translate}}
                                        </span>
                                        <input ng-model="order.location.street"
                                            class="form-control"
                                            autocomplete="off"
                                            id="street-mobile"
                                            ng-class="{ 'error': codeValidationForm.street.$dirty && codeValidationForm.street.$invalid ||
                                            codeValidationForm.$submitted && codeValidationForm.street.$invalid }"
                                            placeholder="{{'order.street' | translate}}"
                                            ng-if="$ctrl.isMobile"
                                            name="street"
                                            required/>
                                        <input 
                                                ng-model="order.location.street"
                                                class="form-control"
                                                autocomplete="off"
                                                id="street"
                                                ng-if="!isMobile"
                                                ng-class="{ 'error': codeValidationForm.street.$dirty && codeValidationForm.street.$invalid ||
                                                codeValidationForm.$submitted && codeValidationForm.street.$invalid }"
                                                placeholder="{{'order.street' | translate}}"
                                                name="street"
                                                required/>
                                        <div class="error"
                                            ng-if="codeValidationForm.$submitted || codeValidationForm.street.$dirty"
                                            ng-messages="codeValidationForm.street.$error">
                                            <p ng-message="required">
                                                {{'order.errors.required' | translate}}
                                            </p>
                                        </div>
                                    </md-input-container>
                                </div>
                            </div>

                            <div class="row no-margin">
                                <div class="col s12 m6">
                                    <md-input-container>
                                        <span class="address-label" for="street-mobile">
                                            {{'new-order.details.zip' | translate}}
                                        </span>
                                        <input type="text"
                                            ng-model="order.location.zipcode"
                                            class="form-control"
                                            ng-class="{ 'error': codeValidationForm.zipcode.$dirty && codeValidationForm.zipcode.$invalid ||
                                            codeValidationForm.$submitted && codeValidationForm.zipcode.$invalid }"
                                            id="zipcode"
                                            name="zipcode"
                                            placeholder="{{'new-order.zipcode' | translate}}"
                                            required/>
                                    </md-input-container>
                                </div>
                                <div class="col s12 m6">
                                    <md-input-container>
                                        <span class="address-label" for="street-mobile">
                                            {{'new-order.details.place' | translate}}
                                        </span>
                                        <input type="text"
                                            ng-model="order.location.city"
                                            class="form-control"
                                            ng-class="{ 'error': codeValidationForm.place.$dirty && codeValidationForm.place.$invalid ||
                                            codeValidationForm.$submitted && codeValidationForm.place.$invalid }"
                                            id="place"
                                            name="place"
                                            placeholder="{{'new-order.place' | translate}}"
                                            required/>
                                    </md-input-container>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col s12">
                                <div class="validation-modal-buttons">
                                    <button class="cancel" ng-click="closeModal()">Cancel</button>
                                    <button ng-disabled="codeValidationForm.$invalid" class="submit" ng-click="submitModal()">{{'new-order.details.add-address' | translate}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                `,
                clickOutsideToClose: true,
                fullscreen: false,
                controller: ['$scope', '$mdDialog', 'ProfileService', '$translate',
                 'OrderCartService', 'ToastService', '$rootScope', ($scope, $mdDialog, ProfileService, $translate, OrderCartService, ToastService, $rootScope) => {
                    var vm = this;
                    $scope.authorized = authorized;
                    $scope.distanceError = false;
                    $scope.place = null;
                    $scope.loggedUserAddress = null;
                    $scope.showAddressDropdown = false;
                    $scope.placeCountry = lang;
                    $scope.showErrorMessage = false;

                    $scope.openAddressModal = () => {
                        $scope.showAddressDropdown = !$scope.showAddressDropdown;
                    }

                    $scope.changeLocation = (newAddress) => {
                        $scope.order.location.street = newAddress.street;
                        $scope.order.location.zipcode = newAddress.zipcode;
                        $scope.order.location.city = newAddress.city;
                    }

                    if ($scope.authorized) { 
                        ProfileService.getUserData().then((data) => {
                            $scope.addresses = data.address;
                            $scope.loggedUserAddress = data.address[0];
                        }).catch();
                    }

                    if (sessionStorage.getItem('newOrder')) {
                        $scope.order = JSON.parse(sessionStorage.getItem('newOrder'));
                    }

                    $scope.autocompleteSubmitHandler = () => {
                        $scope.place = this.getPlace();

                        if ($scope.place.length === 0) {
                            return;
                        }

                    }

                    $scope.closeModal = () => {
                        $mdDialog.hide();
                    }

                    $scope.submitModal = () => {
                        let coupon = $scope.order.coupon_code.value;
                        let location = $scope.order.location;
                        OrderCartService.validateCouponCode(coupon, location).then(({data}) => {
                            if (data.status === 'error') {
                                if (data.message === 'out_of_area') {
                                    $scope.distanceError = true;
                                } else if (data.message === 'expired') {
                                    ToastService.show(false, $translate.instant('new-order.errors.area-out'));
                                }
                            } else if (data.status === 'ok' && data.type === 'group_order') {
                                $rootScope.$broadcast('couponValidated', $scope.order.location);
                                $mdDialog.hide();
                            }
                        });
                    }
                }]
            });
        }

        $scope.$on('couponValidated', (event, newLocation) => {
            ctrl.order.coupon_code.valid = true;
            ctrl.order.coupon_code.type = 'group_order';
            
            ctrl.order.location.city = newLocation.city;
            ctrl.order.location.zipcode = newLocation.zipcode;
            ctrl.order.location.street = newLocation.street;
            ctrl.order.location.latitude = newLocation.latitude;
            ctrl.order.location.longitude = newLocation.longitude;

            sessionStorage.setItem('customerAddress', JSON.stringify(ctrl.order.location));
            sessionStorage.setItem('validationPassed', true);
            ToastService.show(true, $translate.instant('Success'));
        });

        ctrl.applyCode = () => {
            sessionStorage.setItem('newOrder', JSON.stringify(ctrl.order));
            ctrl.order.coupon_code.valid = false;
            ToastService.toggleNotifications();
            let location = {
                city: '',
                zipcode: '',
                street: ''
            };

            OrderCartService.validateCouponCode(ctrl.order.coupon_code.value, location).then(({data}) => {

                if (data.type === 'group_order') {
                    if (data.status === 'ok') {
                    } else {
                        if (data.message === 'not_active') {
                            ctrl.showAlert($translate.instant(`new-order.modals.group-order.not-active`));
                        } else if (data.message === 'expired') {
                            ToastService.show(false, $translate.instant('new-order.errors.expired'));
                        } else if (data.message === 'out_of_area') {
                            ToastService.show(false, $translate.instant('Out of area'));
                        } else if (data.message === 'address_is_required' && data.status === 'error') {
                            ctrl.openValidationModal();
                        }
                    }
                } else if (data.type === 'dugnad') {
                    if (data.status === 'ok') {
                        ToastService.show(true, $translate.instant('Success'));
                    } else if (data.message === 'not_active') {
                        ctrl.showAlert($translate.instant('order.modals.group-order.invalid-code'));
                    }
                } else {
                    if (data.message === 'address_is_required') {
                        ToastService.show(false, $translate.instant('new-order.errors.required-address'));
                    } else if (data.message === 'not_found') {
                        ctrl.showAlert($translate.instant(`new-order.modals.group-order.${data.message}`));
                    }
                };

            }).catch(() => {
            });
            ToastService.toggleNotifications();
        };

        ctrl.showAlert = (message) => {
            $mdDialog.show(
                $mdDialog.alert()
                    .clickOutsideToClose(true)
                    .title($translate.instant('order.modals.search.heading'))
                    .textContent(message)
                    .ok($translate.instant('order.modals.search.close'))
            );
        };

        ctrl.changeOrderRange = () => {
            (typeof(ctrl.currentDateRange) === 'string') ? (ctrl.currentDateRange = JSON.parse(ctrl.currentDateRange)) : (ctrl.currentDateRange = ctrl.currentDateRange);
            ctrl.order.delivery_date_range = ctrl.currentDateRange.name;
            ctrl.calculateProductPrices();
        };

        ctrl.setDeliveryCost = () => {
            OrderCartService.getDeliveryCostPrices().then((data) => {
                let deliveryCostTable = data;
                let deliveryCosts = [0];
                ctrl.order.products.map((product) => {
                    if (product.quantity && product.quantity > 0) {
                        deliveryCosts.push(deliveryCostTable[product.package.id]);
                    }
                });
                
                ctrl.order.deliveryCost = _.max(deliveryCosts);
            })
        };

        ctrl.getSelectedDays = (date) => {
            let amountOfSelectedDates = null;

            if (date.mdp.selected) {
                amountOfSelectedDates = (ctrl.order.ranges.excludedDeliveryDates.length - 1);
                ctrl.selectedDays = ctrl.selectedDays.filter(day => !day.date.isSame(date.date));
            } else {
                amountOfSelectedDates = (ctrl.order.ranges.excludedDeliveryDates.length + 1);
                ctrl.selectedDays.push(date);
            }

            return amountOfSelectedDates;
        };

        ctrl.onDayClick = ($event, date) => {
            let amountOfSelectedDates = ctrl.getSelectedDays(date);
            ctrl.setMonthView(date);
            (ctrl.order.delivery_date_range === '12_30' || ctrl.order.delivery_date_range === 'EXTRA_LONG') ?
                ctrl.setUnlimitedRange(amountOfSelectedDates, date) :
                ctrl.setLimitedRanges(amountOfSelectedDates, date);
        };

        ctrl.setUnlimitedRange = (amountOfSelectedDates, date) => {
            ctrl.week = Object.assign({}, ctrl.week, {
                start: ctrl.moment(),
                end: ctrl.moment().add(7, 'days')
            });
            ctrl.week.selected = ctrl.selectedDays.filter(({date}) => {
                return (ctrl.week.start.isSame(date, 'week')) || (date.isBetween(ctrl.week.start, ctrl.week.end));
            });

            ctrl.setAdditionalDaysToRange(amountOfSelectedDates, date);
            ctrl.setAllowedDays();

            (ctrl.week.selected.length > 1) ? ctrl.disableCurrentWeek() : ctrl.setAllowedDays();
        };

        ctrl.setLimitedRanges = (amountOfSelectedDates, date) => {
            (ctrl.disabledDaysAmount >= amountOfSelectedDates) ? ctrl.setAdditionalDaysToRange(amountOfSelectedDates, date) : null;
            ctrl.setAllowedDays();
        };

        ctrl.setAdditionalDaysToRange = (amountOfSelectedDates, selectedDate) => {
            let startDate = _.first(ctrl.highlightDays).date;
            ctrl.rangeLength = selectedDate.mdp.selected ? (ctrl.rangeLength - 1) : (ctrl.rangeLength + 1);

            ctrl.highlightDays = (ctrl.disabledDaysAmount > 0 && (amountOfSelectedDates === ctrl.disabledDaysAmount)) ?
                ctrl.selectedDays :
                ctrl.setRange(startDate);
            ctrl.currentDatesRange = ctrl.setRange(startDate).map(({date}) => ctrl.moment(date));

            ctrl.setCustomRangeBorders();
        };

        ctrl.disableCurrentWeek = () => {
            const selectedDays = ctrl.selectedDays.map(({date}) => date);
            ctrl.allowedDays = ctrl.allowedDays
                .filter((day) => !day.isBetween(ctrl.week.start, ctrl.week.end))
                .concat(selectedDays);
        };

        ctrl.setRange = (startFrom) => {
            let newRange = [];
            let startDate = ctrl.order.delivery_date_range === 'EXTRA_LONG' ? startFrom : undefined;

            for (let i = 0; i < ctrl.rangeLength; i++) {
                newRange.push({
                    date: ctrl.moment(startDate).add((i), 'days').valueOf(), selectable: true
                });
            }

            return newRange;
        };

        ctrl.setCustomRangeBorders = () => {
            if (ctrl.order.delivery_date_range === 'EXTRA_LONG' && ctrl.highlightDays.length > 0) {
                ctrl.order.custom_range = {
                    start_date: ctrl.moment(_.first(ctrl.highlightDays).date).format('YYYY-MM-DD'),
                    end_date: ctrl.moment(_.last(ctrl.highlightDays).date).format('YYYY-MM-DD')
                };
            } else {
                ctrl.resetCustomRange();
            }
        };

        ctrl.disabledDays = () => {
            sessionStorage.setItem('allowedDays', ctrl.currentDatesRange[0]
                ? JSON.stringify([ctrl.currentDatesRange[0].valueOf(), ctrl.currentDatesRange[ctrl.currentDatesRange.length - 1].valueOf()])
                : null
            );
            if (ctrl.order.ranges.excludedDeliveryDates.length > 0) {
                return ctrl.order.ranges.excludedDeliveryDates
                    .sort((a, b) => moment.utc(a.valueOf()).diff(moment.utc(b.valueOf())))
                    .map(disabledDay => disabledDay.format('DD MMM'))
                    .join(', ');
            } else if (ctrl.allowedDays.length > 0) {
                return `${_.first(ctrl.allowedDays).format('DD MMM')} - ${_.last(ctrl.allowedDays).format('DD MMM')}`;
            }
        };

        ctrl.calculateTotalSum = () => {
            ctrl.order.totalSum = ctrl.order.products
            .filter(product => (+(product.quantity) > 0))
            .map(product => product.product_total)
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

            ctrl.step.validated = ctrl.order.totalSum > 0 && ctrl.order.deliveryConfirmation && (ctrl.order.totalSum >= ctrl.order.min_order_value) && ctrl.productsValid;
            ctrl.setDeliveryCost();
        };

        ctrl.calculateProductPrices = (product) => {
            if (product) {
                product.quantity = (product.quantity < 0 || !product.quantity) ? '' : product.quantity;
            }

            ctrl.productsValid = ctrl.order.products.every((product) => {
                return product.quantity > 0;
            });

            ctrl.order.products
                .filter(product => (+(product.quantity) > 0))
                .map(product => {
                    (!ctrl.currentDateRange || ctrl.currentDateRange === 'undefined') ? (ctrl.currentDateRange = ctrl.deliveryDateRanges['7_12']) : ctrl.currentDateRange;
                    product._price = (product.price * (ctrl.currentDateRange.increase / 100 + 1)).toFixed(2);
                    product.product_total = product._price * product.quantity;
                });
            
            ctrl.enableCarry();
            ctrl.calculateTotalSum();
            
            sessionStorage['cartItems'] = JSON.stringify(ctrl.order.products);

        };
    }
})();