(() => {
    angular.module('VedApp').component('vedArticleView', {
        templateUrl: 'js/components/main/articles/article-view/article-view.tpl.html',
        controller: VedArticleViewController,
        bindings: {
            article: '<'
        }
    });

    function VedArticleViewController() {
        let ctrl = this;
    }
})();