(() => {
    function VedValidatingInputFieldController($translate) {
        let ctrl = this;

        // Hook methods
        ctrl.$onInit = () => {
            ctrl.required = ctrl.required || 'false';
            ctrl.placeholder = ctrl.placeholder || ctrl.label;
            ctrl.id = ctrl.id || ctrl.name;
            ctrl.workingCopy = angular.copy(ctrl.value);
        };
        ctrl.$onChanges = (changes) => {
            if (angular.isDefined(changes.value) && !changes.value.isFirstChange()) {
                ctrl.workingCopy = angular.copy(ctrl.value);
            }
        };

        // Public methods
        ctrl.onChange = () => {
            ctrl.formObject[ctrl.name].$validate();
            ctrl.formObject[ctrl.name].$touched = true;
        };

        ctrl.onBlur = () => {
            ctrl.formObject[ctrl.name].$validate();
            if (ctrl.formObject[ctrl.name].$valid) {
                ctrl.onBlurCallback({
                    name: ctrl.name,
                    value: ctrl.workingCopy,
                    form: ctrl.formObject
                });
            } else {
                ctrl.workingCopy = angular.copy(ctrl.value);
            }
        };
    }

    VedValidatingInputFieldController.$inject = ['$translate'];

    angular.module('VedApp').component('vedValidatingInputField', {
        templateUrl: 'js/components/common/validating-input-field/validating-input-field.tpl.html',
        controller: VedValidatingInputFieldController,
        bindings: {
            type: '@',
            autocomplete: '@',
            required: '@',
            name: '@',
            label: '@',
            value: '<',
            formObject: '<',
            onBlurCallback: '&',
            id: '@?',
            placeholder: '@?',
            patternErrorText: '@?',
            validatingPattern: '<?',
            minLength: '@?',
            maxLength: '@?',
            hideLabel: '<?',
            readonly: '<?',
            onChangeCallback: '&?'
        }
    });
})();