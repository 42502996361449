(() => {
    let ZipLocationService = ($http, ToastService) => {
        let getZipcodesList = () => $http.get(`${apiURI}zipcodes`);
        let getLocationByZip = (zip) => {
            return $http.get(`${apiURI}zipcodes/${zip}`);
        };

        let getProductsByZip = (zip) => $http.get(apiURI + 'widget/products/' + (zip ? zip : ''));

        return {
            getZipcodesList,
            getLocationByZip,
            getProductsByZip
        };
    };

    angular.module('VedApp').factory('ZipLocationService', ZipLocationService);
    ZipLocationService.$inject = ['$http', 'ToastService'];
})();