(() => {
    angular.module('VedApp').component('vedQuestionSent', {
        templateUrl: 'js/components/question-sent/question-sent.tpl.html',
        controller: VedQuestionSentController
    });

    VedQuestionSentController.$inject = ['ToastService', '$translate', '$state'];

    function VedQuestionSentController(ToastService, $translate, $state) {
        let ctrl = this;

        ctrl.authorized = authorized;
        ctrl.lang = lang;

        // Hook methods
        ctrl.$onInit = () => {
           
        };
    }
})();