(() => {
    angular.module('VedApp').component('vedUserRegistration', {
        templateUrl: 'js/components/user-registration/user-registration.tpl.html',
        controller: VedUserRegistrationController
    });

    //VedUserRegistrationController.$inject = [];

    function VedUserRegistrationController() {
        let ctrl = this;

        ctrl.activeTab = 'private-customer';

        // Hook methods
        ctrl.$onInit = () => {
        };
    }
})();