(() => {
    angular.module('VedApp').component('vedDugnadGroupCreatedReport', {
        templateUrl: 'js/components/dugnad/register-dugnad-group/dugnad-group-created-report/dugnad-group-created-report.tpl.html',
        controller: VedDugnadGroupCreatedReportController,
        bindings: {
            createdDugnad: '<'
        }
    });

    VedDugnadGroupCreatedReportController.$inject = ['$translate'];

    function VedDugnadGroupCreatedReportController($translate) {
        let ctrl = this;

        // Hook methods
        ctrl.$onInit = () => {
            ctrl.dugnadRegistrationMessage = $translate.instant('dugnad.register-group.success-group-registration');
        };
    }
})();