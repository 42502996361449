(() => {
    angular.module('VedApp').component('vedSuccessfulOrder', {
        templateUrl: 'js/components/cart/successful-order/successful-order.tpl.html',
        controller: VedSuccessfulOrderController,
        bindings: {
            order: '<'
        }
    });

    VedSuccessfulOrderController.$inject = ['OrderCartService', '$state'];

    function VedSuccessfulOrderController(OrderCartService, $state) {
        let ctrl = this;
        ctrl.authorized = authorized;

        ctrl.$onInit = () => {
            sessionStorage.clear();
        }

        ctrl.enableGroupMode = () => {
            OrderCartService.enableGroupMode(true);
            $state.go('group-order', { enableGroupActive: true, orderId: localStorage.getItem('orderId')});
        }
    }
})();