((angular) => {
    angular.module('VedApp').directive('animateOnChange', ['$animate', '$timeout', function ($animate, $timeout) {
        return function (scope, elem, attr) {
            scope.$watch(attr.animateOnChange, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    let elementClass = newValue < oldValue ? 'change-up' : 'change';

                    $animate.addClass(elem, elementClass).then(function () {
                        $timeout(function () {
                            $animate.removeClass(elem, elementClass)
                        });
                    });
                }
            })
        }
    }]);
})(window.angular);